var XHRLoader = /** @class */ (function () {
    function XHRLoader(options) {
        if (options === void 0) { options = {}; }
        this.timeout = options.timeout || 15000;
    }
    XHRLoader.prototype.get = function (url) {
        return this._getRequestObject({ url: url, responseType: "text" });
    };
    XHRLoader.prototype.getArrayBuffer = function (url) {
        return this._getRequestObject({ url: url, responseType: "arraybuffer" });
    };
    XHRLoader.prototype._getRequestObject = function (param) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var request = new XMLHttpRequest();
            request.open("GET", param.url, true);
            request.responseType = param.responseType;
            request.timeout = _this.timeout;
            request.addEventListener("timeout", function () { reject(new Error("loading timeout")); }, false);
            request.addEventListener("err", function () { reject(new Error("loading error. status: " + request.status)); }, false);
            request.addEventListener("load", function () {
                if (request.status >= 200 && request.status < 300) {
                    var response = param.responseType === "text" ? request.responseText : request.response;
                    resolve(response);
                }
                else {
                    reject(new Error("loading error. status: " + request.status));
                }
            }, false);
            request.send();
        });
    };
    return XHRLoader;
}());
export { XHRLoader };

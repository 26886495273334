var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import { YScrollKeeperData } from "../view/common/hooks/useYScrollKeeper";
var TanaoroshiScreenStore = /** @class */ (function () {
    function TanaoroshiScreenStore() {
        this.entries = [];
        this.actionMode = "SCAN";
        this.isCheckedAll = false;
        this.lastSelectedIndex = null;
        this.locationInput = "";
        this.tanabanInput = "";
        this.scrollData = new YScrollKeeperData();
    }
    TanaoroshiScreenStore.prototype.reset = function () {
        this.resetEntries();
        this.setActionMode("SCAN");
        this.isCheckedAll = false;
        this.lastSelectedIndex = null;
        this.setLocationInput("");
        this.setTanabanInput("");
        this.scrollData.reset();
    };
    TanaoroshiScreenStore.prototype.setEntries = function (v) {
        this.entries = v;
        this.lastSelectedIndex = null;
    };
    TanaoroshiScreenStore.prototype.resetEntries = function () { this.setEntries([]); };
    TanaoroshiScreenStore.prototype.setActionMode = function (v) { this.actionMode = v; };
    TanaoroshiScreenStore.prototype.changeCheckAll = function (v) {
        this.isCheckedAll = v;
        this.lastSelectedIndex = null;
        this.entries = this.entries.map(function (e) { return (__assign(__assign({}, e), { isChecked: v })); });
    };
    TanaoroshiScreenStore.prototype.toggleCheckAll = function () {
        this.changeCheckAll(!this.isCheckedAll);
    };
    TanaoroshiScreenStore.prototype.toggleChecks = function (idx) {
        var _a;
        var entries = this.entries;
        var e = entries[idx];
        if (!e)
            return;
        if (this.actionMode === "SCAN" && e.isChecked)
            return;
        e.isChecked = !e.isChecked;
        if (e.isChecked) {
            var last = this.lastSelectedIndex;
            if (this.actionMode === "SCAN" && last != null && ((_a = entries[last]) === null || _a === void 0 ? void 0 : _a.isChecked)) {
                entries[last].isChecked = false;
            }
            this.lastSelectedIndex = idx;
        }
    };
    TanaoroshiScreenStore.prototype.setLocationInput = function (v) { this.locationInput = v; };
    TanaoroshiScreenStore.prototype.setTanabanInput = function (v) { this.tanabanInput = v; };
    __decorate([
        observable
    ], TanaoroshiScreenStore.prototype, "entries", void 0);
    __decorate([
        observable
    ], TanaoroshiScreenStore.prototype, "actionMode", void 0);
    __decorate([
        observable
    ], TanaoroshiScreenStore.prototype, "isCheckedAll", void 0);
    __decorate([
        observable
    ], TanaoroshiScreenStore.prototype, "lastSelectedIndex", void 0);
    __decorate([
        observable
    ], TanaoroshiScreenStore.prototype, "locationInput", void 0);
    __decorate([
        observable
    ], TanaoroshiScreenStore.prototype, "tanabanInput", void 0);
    __decorate([
        action.bound
    ], TanaoroshiScreenStore.prototype, "reset", null);
    __decorate([
        action.bound
    ], TanaoroshiScreenStore.prototype, "setEntries", null);
    __decorate([
        action.bound
    ], TanaoroshiScreenStore.prototype, "resetEntries", null);
    __decorate([
        action.bound
    ], TanaoroshiScreenStore.prototype, "setActionMode", null);
    __decorate([
        action.bound
    ], TanaoroshiScreenStore.prototype, "changeCheckAll", null);
    __decorate([
        action.bound
    ], TanaoroshiScreenStore.prototype, "toggleCheckAll", null);
    __decorate([
        action.bound
    ], TanaoroshiScreenStore.prototype, "toggleChecks", null);
    __decorate([
        action.bound
    ], TanaoroshiScreenStore.prototype, "setLocationInput", null);
    __decorate([
        action.bound
    ], TanaoroshiScreenStore.prototype, "setTanabanInput", null);
    return TanaoroshiScreenStore;
}());
export { TanaoroshiScreenStore };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { X } from "react-feather";
import { observer } from "mobx-react";
// import { LabeledInputText } from "../common/LabeledInputText";
import { InputTextMultiLine } from "../common/InputTextMultiLine";
import { CompletableInput } from "../common/CompletableInput";
import * as styles from "./MultiChangeDialog.module.scss";
function useOutsideClickEffect(ref, callback) {
    React.useEffect(function () {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}
function row_2td(l, r) {
    return (React.createElement("tr", null,
        React.createElement("td", null, l),
        React.createElement("td", null, r)));
}
export var MultiChangeDialog = observer(function MultiChangeDialog(props) {
    var sozaiDetail = props.sozaiDetail, onModify = props.onModify;
    var dialogRef = React.useRef(null);
    useOutsideClickEffect(dialogRef, props.onClose);
    var handleModifyDaimei = React.useCallback(function (v) { return onModify(__assign(__assign({}, sozaiDetail), { Daimei: v })); }, [onModify, sozaiDetail]);
    var handleModifyFukudai = React.useCallback(function (v) { return onModify(__assign(__assign({}, sozaiDetail), { Fukudai: v })); }, [onModify, sozaiDetail]);
    return (React.createElement("div", { className: styles["backdrop"] },
        React.createElement("div", { className: styles["dialog"], ref: dialogRef },
            React.createElement("div", { className: styles["dialog-close"], onClick: props.onClose },
                React.createElement(X, { size: 20 })),
            React.createElement("div", { className: "mb-3 " + styles["dialog-caption"] }, "\u4E00\u62EC\u7DE8\u96C6"),
            React.createElement("div", { className: "d-flex justify-content-between mb-2" },
                React.createElement("div", null),
                React.createElement("div", null,
                    React.createElement("button", { className: "btn btn-sm btn-primary", onClick: props.onSubmit }, "\u66F4\u65B0"))),
            React.createElement("table", { className: "table table-stripe align-baseline " + styles["table"] },
                React.createElement("tbody", null,
                    row_2td("得意先名", React.createElement(CompletableInput, __assign({}, props.completePropsTokui))),
                    row_2td("旧得意先名", React.createElement(CompletableInput, __assign({}, props.completePropsKyu))),
                    row_2td("題名グループ", React.createElement(CompletableInput, __assign({}, props.completePropsSakuhin))),
                    row_2td("担当者名", React.createElement(CompletableInput, __assign({}, props.completePropsTanto))),
                    row_2td("題名", React.createElement(InputTextMultiLine, { value: sozaiDetail.Daimei, onModify: handleModifyDaimei })),
                    row_2td("副題", React.createElement(InputTextMultiLine, { value: sozaiDetail.Fukudai, onModify: handleModifyFukudai })))))));
});

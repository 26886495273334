export function createNullUser() {
    return {
        UserCode: "",
        UserName: "",
        BusyoName: "",
        Location: "",
        MailAddress: "",
        UserRole: "",
        AuthApps: "",
        AuthTypes: ""
    };
}

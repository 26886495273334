var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import { createNullKeijo } from "../types/Keijo";
var KeijoEditScreenStore = /** @class */ (function () {
    function KeijoEditScreenStore() {
        this.keijo = createNullKeijo();
    }
    KeijoEditScreenStore.prototype.setKeijo = function (v) { this.keijo = v; };
    KeijoEditScreenStore.prototype.resetKeijo = function () { this.keijo = createNullKeijo(); };
    __decorate([
        observable
    ], KeijoEditScreenStore.prototype, "keijo", void 0);
    __decorate([
        action.bound
    ], KeijoEditScreenStore.prototype, "setKeijo", null);
    __decorate([
        action.bound
    ], KeijoEditScreenStore.prototype, "resetKeijo", null);
    return KeijoEditScreenStore;
}());
export { KeijoEditScreenStore };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
var labelStyle = {
    fontSize: "0.9rem"
};
var RefInput = React.forwardRef(function (props, ref) {
    return React.createElement("input", __assign({ ref: ref }, props));
});
export var LabeledInputText = observer(function LabeledInputText(props) {
    var label = props.label, type = props.type, value = props.value, dataCy = props.dataCy, placeholder = props.placeholder, onModify = props.onModify, onBlur = props.onBlur, onEnter = props.onEnter, rawRef = props.rawRef, className = props.className, disabled = props.disabled;
    var handleChange = React.useCallback(function (ev) {
        var next = ev.target.value;
        if (typeof next === "string") {
            onModify(next);
        }
    }, [onModify]);
    var handleKeyPress = React.useCallback(function (ev) {
        if (onEnter && ev.key === "Enter") {
            ev.preventDefault();
            onEnter();
        }
    }, [onEnter]);
    return (React.createElement("div", { className: className },
        React.createElement("span", { className: "text-secondary text-nowrap", style: labelStyle }, label),
        React.createElement(RefInput, { ref: rawRef, type: type || "text", className: "form-control form-control-sm", value: value || "", "data-cy": dataCy, placeholder: placeholder, disabled: disabled, onChange: handleChange, onBlur: onBlur, onKeyPress: handleKeyPress })));
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import { Edit, Trash2, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
import { LabeledSelect2 } from "../common/LabeledSelect2";
export var ItemEditScreen = observer(function (props) {
    var acd = props.acd, isEditMode = props.isEditMode, item = props.item, onModify = props.onModify, onRegister = props.onRegister, onDelete = props.onDelete, onEnd = props.onEnd;
    var handleModifyItemCode = React.useCallback(function (v) { return onModify(__assign(__assign({}, item), { ItemCode: v })); }, [onModify, item]);
    var handleModifyItemName = React.useCallback(function (v) { return onModify(__assign(__assign({}, item), { ItemName: v })); }, [onModify, item]);
    var handleModifyItemOrder = React.useCallback(function (v) { return onModify(__assign(__assign({}, item), { ItemOrder: v })); }, [onModify, item]);
    var handleModifyYukoFLG = React.useCallback(function (v) { return onModify(__assign(__assign({}, item), { YukoFLG: v })); }, [onModify, item]);
    if (!acd.view)
        return null;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-end mb-2" },
            React.createElement(IconButton, { iconComponent: Edit, label: "\u767B\u9332", onClickButton: onRegister, disabled: isEditMode ? !acd.c : !acd.a }),
            isEditMode && React.createElement(IconButton, { btnClassName: "btn-outline-danger", iconComponent: Trash2, label: "\u524A\u9664", onClickButton: onDelete, disabled: !acd.d }),
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u9805\u76EE\u30B3\u30FC\u30C9", value: item.ItemCode, onModify: handleModifyItemCode }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u9805\u76EE\u540D", value: item.ItemName, onModify: handleModifyItemName })),
                React.createElement("div", { className: "col-4" },
                    React.createElement(LabeledInputText, { label: "\u8868\u793A\u9806", value: item.ItemOrder, onModify: handleModifyItemOrder }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledSelect2, { label: "\u6709\u52B9\u30D5\u30E9\u30B0", options: ["無効", "有効"], selectedOption: item.YukoFLG, onModify: handleModifyYukoFLG })))));
});

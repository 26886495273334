var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
var HaikiScreenStore = /** @class */ (function () {
    function HaikiScreenStore() {
        this.INo = "";
        this.torikesiKubun = "";
        this.torikesiRiyu = "";
        this.entries = [];
        this.isSubmitted = false;
        this.isWarnDialogShown = false;
        this.warnDialogMessages = [];
    }
    HaikiScreenStore.prototype.setINo = function (v) { this.INo = v; };
    HaikiScreenStore.prototype.setTorikesiKubun = function (v) { this.torikesiKubun = v; };
    HaikiScreenStore.prototype.setTorikesiRiyu = function (v) { this.torikesiRiyu = v; };
    HaikiScreenStore.prototype.setEntries = function (v) { this.entries = v; };
    HaikiScreenStore.prototype.setIsSubmitted = function (v) { this.isSubmitted = v; };
    HaikiScreenStore.prototype.setShowsWarnDialogTrue = function () { this.isWarnDialogShown = true; };
    HaikiScreenStore.prototype.setShowsWarnDialogFalse = function () { this.isWarnDialogShown = false; };
    HaikiScreenStore.prototype.setWarnMessage = function (v) { this.setWarnMessages([v]); };
    HaikiScreenStore.prototype.setWarnMessages = function (v) { this.warnDialogMessages = v; };
    HaikiScreenStore.prototype.removeEntry = function (idx) {
        this.entries.splice(idx, 1);
    };
    HaikiScreenStore.prototype.addEntry = function (v) {
        this.entries.push(v);
    };
    HaikiScreenStore.prototype.resetInputs = function () {
        this.INo = "";
        this.torikesiKubun = "";
        this.torikesiRiyu = "";
    };
    __decorate([
        observable
    ], HaikiScreenStore.prototype, "INo", void 0);
    __decorate([
        observable
    ], HaikiScreenStore.prototype, "torikesiKubun", void 0);
    __decorate([
        observable
    ], HaikiScreenStore.prototype, "torikesiRiyu", void 0);
    __decorate([
        observable
    ], HaikiScreenStore.prototype, "entries", void 0);
    __decorate([
        observable
    ], HaikiScreenStore.prototype, "isSubmitted", void 0);
    __decorate([
        observable
    ], HaikiScreenStore.prototype, "isWarnDialogShown", void 0);
    __decorate([
        observable
    ], HaikiScreenStore.prototype, "warnDialogMessages", void 0);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "setINo", null);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "setTorikesiKubun", null);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "setTorikesiRiyu", null);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "setEntries", null);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "setIsSubmitted", null);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "setShowsWarnDialogTrue", null);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "setShowsWarnDialogFalse", null);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "setWarnMessage", null);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "setWarnMessages", null);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "removeEntry", null);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "addEntry", null);
    __decorate([
        action.bound
    ], HaikiScreenStore.prototype, "resetInputs", null);
    return HaikiScreenStore;
}());
export { HaikiScreenStore };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import { createNullLocationWithTanaban } from "../types/LocationWithTanaban";
var LocationEditScreenStore = /** @class */ (function () {
    function LocationEditScreenStore() {
        this.location = createNullLocationWithTanaban();
        this.tanaSozais = [];
    }
    LocationEditScreenStore.prototype.setLocation = function (v) { this.location = v; };
    LocationEditScreenStore.prototype.resetLocation = function () { this.location = createNullLocationWithTanaban(); };
    LocationEditScreenStore.prototype.setTanaSozais = function (v) { this.tanaSozais = v; };
    LocationEditScreenStore.prototype.resetTanaSozais = function () { this.setTanaSozais([]); };
    __decorate([
        observable
    ], LocationEditScreenStore.prototype, "location", void 0);
    __decorate([
        observable
    ], LocationEditScreenStore.prototype, "tanaSozais", void 0);
    __decorate([
        action.bound
    ], LocationEditScreenStore.prototype, "setLocation", null);
    __decorate([
        action.bound
    ], LocationEditScreenStore.prototype, "resetLocation", null);
    __decorate([
        action.bound
    ], LocationEditScreenStore.prototype, "setTanaSozais", null);
    __decorate([
        action.bound
    ], LocationEditScreenStore.prototype, "resetTanaSozais", null);
    return LocationEditScreenStore;
}());
export { LocationEditScreenStore };

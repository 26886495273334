import * as React from "react";
import { observer } from "mobx-react";
import { Edit, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
var kensuKey = "hyoujijoken-chkbox_dsp-kensu";
export var SozaiKensakuHyoujiJokenScreen = observer(function (props) {
    var acd = props.acd, maxDspCNT = props.maxDspCNT, displaysSearchCNT = props.displaysSearchCNT, hyoujiJokens = props.hyoujiJokens, onModifyMaxDspCNT = props.onModifyMaxDspCNT, onSetDisplaysSearchCNT = props.onSetDisplaysSearchCNT, onToggleHyoujiJokens = props.onToggleHyoujiJokens, onRegister = props.onRegister, onEnd = props.onEnd;
    if (!acd.view)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-end" },
            React.createElement(IconButton, { iconComponent: Edit, label: "\u4FDD\u5B58\u3057\u3066\u7D42\u4E86", onClickButton: onRegister }),
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row mb-3" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "最大件数", value: "" + maxDspCNT, onModify: onModifyMaxDspCNT }))),
            React.createElement("div", { className: "row mb-3" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("span", { className: "text-secondary" }, "\u691C\u7D22\u30AA\u30D7\u30B7\u30E7\u30F3"),
                    React.createElement("div", { className: "px-3 pt-2 border rounded" },
                        React.createElement("div", { className: "form-check mb-1", key: kensuKey },
                            React.createElement("input", { type: "checkbox", className: "form-check-input", id: kensuKey, checked: displaysSearchCNT, onChange: function () { return onSetDisplaysSearchCNT(!displaysSearchCNT); } }),
                            React.createElement("label", { className: "form-check-label", htmlFor: kensuKey }, "検索結果件数を表示"))))),
            React.createElement("div", { className: "row mb-3" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("span", { className: "text-secondary" }, "\u8868\u793A\u9805\u76EE"),
                    React.createElement("div", { className: "px-3 pt-2 border rounded" }, hyoujiJokens.map(function (hyoujiJoken, i) {
                        var key = "hyoujijoken-chkbox_".concat(hyoujiJoken.ItemCode);
                        return (React.createElement("div", { className: "form-check mb-1", key: key },
                            React.createElement("input", { type: "checkbox", className: "form-check-input", id: key, checked: hyoujiJoken.ItemCheck === "1", onChange: function () { return onToggleHyoujiJokens(i); } }),
                            React.createElement("label", { className: "form-check-label", htmlFor: key }, hyoujiJoken.ItemDisp)));
                    })))))));
});

import * as React from "react";
import { observer } from "mobx-react";
import { Plus, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
export var HensakiListScreen = observer(function (props) {
    var acd = props.acd, hensakis = props.hensakis, searchText = props.searchText, isInitial = props.isInitial, onModifySeachInput = props.onModifySeachInput, onSearch = props.onSearch, onSelect = props.onSelect, onNew = props.onNew, onEnd = props.onEnd;
    var handleSearch = React.useCallback(function (ev) {
        onSearch();
        // form の submit なので preventDefault() しないと URL が変化してしまう
        ev.preventDefault();
    }, [onSearch]);
    if (!acd.view)
        return null;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-edit mb-2" },
            React.createElement(IconButton, { iconComponent: Plus, label: "\u65B0\u898F", onClickButton: onNew, disabled: !acd.a }),
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row mb-3" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "d-flex align-items-end" },
                        React.createElement("form", { className: "form d-flex flex-fill" },
                            React.createElement("div", { className: "d-inline-block mr-2 flex-fill" },
                                React.createElement(LabeledInputText, { value: searchText, placeholder: "\u8FD4\u5374\u5148\u540D", onModify: onModifySeachInput })),
                            React.createElement("button", { type: "submit", className: "btn btn-sm btn-primary", onClick: handleSearch }, "\u691C\u7D22"))))),
            isInitial ?
                React.createElement("div", { className: "row" }) :
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col" },
                            React.createElement("table", { className: "table table-bordered table-striped table-hover " },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, "\u8FD4\u5374\u5148\u30B3\u30FC\u30C9"),
                                        React.createElement("th", null, "\u8FD4\u5374\u5148\u540D"))),
                                React.createElement("tbody", null, (hensakis || []).map(function (hensaki, i) { return (React.createElement("tr", { key: hensaki.HensakiCode, onClick: function () { return onSelect(i); }, style: { cursor: "pointer" } },
                                    React.createElement("td", null, hensaki.HensakiCode),
                                    React.createElement("td", null, hensaki.HensakiName))); }))))),
                    (hensakis.length) ?
                        null :
                        React.createElement("div", { className: "text-center mt-2" }, "\u8A72\u5F53\u30C7\u30FC\u30BF\u304C\u3042\u308A\u307E\u305B\u3093"))));
});

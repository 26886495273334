var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import { createNullHensaki } from "../types/Hensaki";
var HensakiEditScreenStore = /** @class */ (function () {
    function HensakiEditScreenStore() {
        this.hensaki = createNullHensaki();
    }
    HensakiEditScreenStore.prototype.setHensaki = function (v) { this.hensaki = v; };
    HensakiEditScreenStore.prototype.resetHensaki = function () { this.hensaki = createNullHensaki(); };
    __decorate([
        observable
    ], HensakiEditScreenStore.prototype, "hensaki", void 0);
    __decorate([
        action.bound
    ], HensakiEditScreenStore.prototype, "setHensaki", null);
    __decorate([
        action.bound
    ], HensakiEditScreenStore.prototype, "resetHensaki", null);
    return HensakiEditScreenStore;
}());
export { HensakiEditScreenStore };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
var HelpDialogStore = /** @class */ (function () {
    function HelpDialogStore() {
        this.shows = false;
    }
    HelpDialogStore.prototype.changeShowsTrue = function () {
        this.shows = true;
    };
    HelpDialogStore.prototype.setShowsFalse = function () { this.shows = false; };
    __decorate([
        observable
    ], HelpDialogStore.prototype, "shows", void 0);
    __decorate([
        action.bound
    ], HelpDialogStore.prototype, "changeShowsTrue", null);
    __decorate([
        action.bound
    ], HelpDialogStore.prototype, "setShowsFalse", null);
    return HelpDialogStore;
}());
export { HelpDialogStore };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import { createNullMedia } from "../types/Media";
var MediaEditScreenStore = /** @class */ (function () {
    function MediaEditScreenStore() {
        this.media = createNullMedia();
    }
    MediaEditScreenStore.prototype.setMedia = function (v) { this.media = v; };
    MediaEditScreenStore.prototype.resetMedia = function () { this.media = createNullMedia(); };
    __decorate([
        observable
    ], MediaEditScreenStore.prototype, "media", void 0);
    __decorate([
        action.bound
    ], MediaEditScreenStore.prototype, "setMedia", null);
    __decorate([
        action.bound
    ], MediaEditScreenStore.prototype, "resetMedia", null);
    return MediaEditScreenStore;
}());
export { MediaEditScreenStore };

import * as React from "react";
import * as ReactDOM from "react-dom";
import { HashRouter, Switch, Route } from "react-router-dom";
// import { Auth0Provider } from "@auth0/auth0-react";
import { Store } from "./store/Store";
import { StoreContext } from "./context";
import { LoginScreenRoot } from "./view/root/LoginScreenRoot";
import { HomeScreenRoot } from "./view/root/HomeScreenRoot";
import { ItemListScreenRoot } from "./view/root/ItemListScreenRoot";
import { ItemEditScreenRoot } from "./view/root/ItemEditScreenRoot";
import { TokuiListScreenRoot } from "./view/root/TokuiListScreenRoot";
import { TokuiEditScreenRoot } from "./view/root/TokuiEditScreenRoot";
import { KeijoListScreenRoot } from "./view/root/KeijoListScreenRoot";
import { KeijoEditScreenRoot } from "./view/root/KeijoEditScreenRoot";
import { HensakiListScreenRoot } from "./view/root/HensakiListScreenRoot";
import { HensakiEditScreenRoot } from "./view/root/HensakiEditScreenRoot";
import { MediaListScreenRoot } from "./view/root/MediaListScreenRoot";
import { MediaEditScreenRoot } from "./view/root/MediaEditScreenRoot";
import { LocationListScreenRoot } from "./view/root/LocationListScreenRoot";
import { LocationList2ScreenRoot } from "./view/root/LocationList2ScreenRoot";
import { LocationEditScreenRoot } from "./view/root/LocationEditScreenRoot";
import { ApplicationListScreenRoot } from "./view/root/ApplicationListScreenRoot";
import { ApplicationEditScreenRoot } from "./view/root/ApplicationEditScreenRoot";
import { UserListScreenRoot } from "./view/root/UserListScreenRoot";
import { UserEditScreenRoot } from "./view/root/UserEditScreenRoot";
import { SakuhinListScreenRoot } from "./view/root/SakuhinListScreenRoot";
import { SakuhinEditScreenRoot } from "./view/root/SakuhinEditScreenRoot";
import { SozaiEditScreenRoot } from "./view/root/SozaiEditScreenRoot";
import { SozaiKensakuScreenRoot } from "./view/root/SozaiKensakuScreenRoot";
import { SozaiKensakuHyoujiJokenScreenRoot } from "./view/root/SozaiKensakuHyoujiJokenScreenRoot";
import { SozaiHistoryScreenRoot } from "./view/root/SozaiHistoryScreenRoot";
import { LocationChangeScreenRoot } from "./view/root/LocationChangeScreenRoot";
import { MeigiChangeScreenRoot } from "./view/root/MeigiChangeScreenRoot";
import { NohinScreenRoot } from "./view/root/NohinScreenRoot";
import { HaikiScreenRoot } from "./view/root/HaikiScreenRoot";
import { PasswordEditScreenRoot } from "./view/root/PasswordEditScreenRoot";
import { TanaoroshiTopScreenRoot } from "./view/root/TanaoroshiTopScreenRoot";
import { TanaoroshiScanScreenRoot } from "./view/root/TanaoroshiScanScreenRoot";
// import { TanaoroshiMeisaiScreenRoot } from "./view/root/TanaoroshiMeisaiScreenRoot";
import { ParameterEditScreenRoot } from "./view/root/ParameterEditScreenRoot";
import { DataTransportScreenRoot } from "./view/root/DataTransportScreenRoot";
import { PrivateRoute } from "./view/root/PrivateRoute";
import * as audio from "./view/_audio";
import "bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
var store = new Store();
export function App() {
    return (
    // TODO config に切り出す
    // <Auth0Provider
    //   domain="dev-ponyama.us.auth0.com"
    //   clientId="9MJdMyBvlEGX4TZfBowzH8oUpApsG4SH"
    //   redirectUri={window.location.origin}
    // >
    React.createElement(StoreContext.Provider, { value: store },
        React.createElement(HashRouter, null,
            React.createElement(Switch, null,
                React.createElement(PrivateRoute, { exact: true, path: "/home" },
                    React.createElement(HomeScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/item-master" },
                    React.createElement(ItemListScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/item/:ItemCode" },
                    React.createElement(ItemEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/item-new/" },
                    React.createElement(ItemEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/keijo-master" },
                    React.createElement(KeijoListScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/keijo/:KeijoCode" },
                    React.createElement(KeijoEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/keijo-new/" },
                    React.createElement(KeijoEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/tokui-master" },
                    React.createElement(TokuiListScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/tokui/:TokuiCode" },
                    React.createElement(TokuiEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/tokui-new/" },
                    React.createElement(TokuiEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/hensaki-master" },
                    React.createElement(HensakiListScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/hensaki/:HensakiCode" },
                    React.createElement(HensakiEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/hensaki-new/" },
                    React.createElement(HensakiEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/media-master" },
                    React.createElement(MediaListScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/media/:MediaCode" },
                    React.createElement(MediaEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/media-new/" },
                    React.createElement(MediaEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/location-master" },
                    React.createElement(LocationListScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/loc-master" },
                    React.createElement(LocationList2ScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/location/:LocationCode/:TanaCode" },
                    React.createElement(LocationEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/location-new/" },
                    React.createElement(LocationEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/app-master" },
                    React.createElement(ApplicationListScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/app/:AppID" },
                    React.createElement(ApplicationEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/app-new/" },
                    React.createElement(ApplicationEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/user-master" },
                    React.createElement(UserListScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/user/:UserCode" },
                    React.createElement(UserEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/user-new/" },
                    React.createElement(UserEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/sakuhin-master" },
                    React.createElement(SakuhinListScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/sakuhin/:SakuhinCode" },
                    React.createElement(SakuhinEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/sakuhin-new/" },
                    React.createElement(SakuhinEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/sozai-search" },
                    React.createElement(SozaiKensakuScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/sozai/:INo" },
                    React.createElement(SozaiEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/sozai-new/" },
                    React.createElement(SozaiEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/sozai-search-cond" },
                    React.createElement(SozaiKensakuHyoujiJokenScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/sozai-history" },
                    React.createElement(SozaiHistoryScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/location-change" },
                    React.createElement(LocationChangeScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/meigi-change" },
                    React.createElement(MeigiChangeScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/nohin" },
                    React.createElement(NohinScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/discard" },
                    React.createElement(HaikiScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/password-change" },
                    React.createElement(PasswordEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/stocktaking" },
                    React.createElement(TanaoroshiTopScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/stocktaking-scan" },
                    React.createElement(TanaoroshiScanScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/parameter" },
                    React.createElement(ParameterEditScreenRoot, null)),
                React.createElement(PrivateRoute, { path: "/data-transport" },
                    React.createElement(DataTransportScreenRoot, null)),
                React.createElement(Route, { path: "/login", component: LoginScreenRoot }),
                React.createElement(Route, { component: LoginScreenRoot }))))
    // </Auth0Provider>
    );
}
(function () {
    if (process.env.NODE_ENV !== "production") {
        window.__sozaimgt__ = { store: store };
    }
    if (process.env.NODE_ENV === "production") {
        if (location.protocol === "http:") {
            // サーバ側でリダイレクトしていればここにはこないが、こちらでもリダイレクトする
            // (ここでリダイレクトしても HTML/JS 自体は取得されてしまうが、どうせAPIはガードされている)
            location.replace(location.href.replace(/^http:/, "https:"));
            return;
        }
    }
    audio.init();
    ReactDOM.render(React.createElement(App, null), document.querySelector("#app"));
})();

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
var HensakiListScreenStore = /** @class */ (function () {
    function HensakiListScreenStore() {
        this.hensakis = [];
        this.searchText = "";
        this.isInitial = true;
    }
    HensakiListScreenStore.prototype.setHensakis = function (v) { this.hensakis = v; };
    HensakiListScreenStore.prototype.setSearchText = function (v) { this.searchText = v; };
    HensakiListScreenStore.prototype.setIsInitial = function (v) { this.isInitial = v; };
    HensakiListScreenStore.prototype.eraseSearchText = function () { this.searchText = ""; };
    __decorate([
        observable
    ], HensakiListScreenStore.prototype, "hensakis", void 0);
    __decorate([
        observable
    ], HensakiListScreenStore.prototype, "searchText", void 0);
    __decorate([
        observable
    ], HensakiListScreenStore.prototype, "isInitial", void 0);
    __decorate([
        action.bound
    ], HensakiListScreenStore.prototype, "setHensakis", null);
    __decorate([
        action.bound
    ], HensakiListScreenStore.prototype, "setSearchText", null);
    __decorate([
        action.bound
    ], HensakiListScreenStore.prototype, "setIsInitial", null);
    __decorate([
        action.bound
    ], HensakiListScreenStore.prototype, "eraseSearchText", null);
    return HensakiListScreenStore;
}());
export { HensakiListScreenStore };

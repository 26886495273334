var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
var SozaiKensakuHyoujiJokenScreenStore = /** @class */ (function () {
    function SozaiKensakuHyoujiJokenScreenStore() {
        this.maxDspCNT = "";
        // TODO たぶん observable.array いらない。ただの配列でいい
        this.displaysSearchCNT = false;
        this.hyoujiJokens = observable.array();
    }
    SozaiKensakuHyoujiJokenScreenStore.prototype.setMaxDspCNT = function (v) {
        this.maxDspCNT = v;
    };
    SozaiKensakuHyoujiJokenScreenStore.prototype.setDisplaysSearchCNT = function (v) {
        this.displaysSearchCNT = v;
    };
    SozaiKensakuHyoujiJokenScreenStore.prototype.setHyoujiJokens = function (v) {
        this.hyoujiJokens = v;
    };
    SozaiKensakuHyoujiJokenScreenStore.prototype.toggleHyoujiJoken = function (idx) {
        this.hyoujiJokens[idx].ItemCheck = this.hyoujiJokens[idx].ItemCheck === "1" ? "0" : "1";
    };
    __decorate([
        observable
    ], SozaiKensakuHyoujiJokenScreenStore.prototype, "maxDspCNT", void 0);
    __decorate([
        observable
    ], SozaiKensakuHyoujiJokenScreenStore.prototype, "displaysSearchCNT", void 0);
    __decorate([
        observable
    ], SozaiKensakuHyoujiJokenScreenStore.prototype, "hyoujiJokens", void 0);
    __decorate([
        action.bound
    ], SozaiKensakuHyoujiJokenScreenStore.prototype, "setMaxDspCNT", null);
    __decorate([
        action.bound
    ], SozaiKensakuHyoujiJokenScreenStore.prototype, "setDisplaysSearchCNT", null);
    __decorate([
        action.bound
    ], SozaiKensakuHyoujiJokenScreenStore.prototype, "setHyoujiJokens", null);
    __decorate([
        action.bound
    ], SozaiKensakuHyoujiJokenScreenStore.prototype, "toggleHyoujiJoken", null);
    return SozaiKensakuHyoujiJokenScreenStore;
}());
export { SozaiKensakuHyoujiJokenScreenStore };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import { TanaoroshiScanScreen } from "../tanaoroshi/TanaoroshiScanScreen";
import * as api from "../../api/apiClient";
import * as audio from "../_audio";
export var TanaoroshiScanScreenRoot = observer(function TanaoroshiScanScreenRoot() {
    var _this = this;
    var _a, _b;
    var store = React.useContext(StoreContext);
    var history = useHistory();
    var screenStore = store.tanaoroshiScanScreenStore;
    var location = useLocation();
    var acd = store.userStore.appAuthTable.stocktaking;
    var updateScanList = React.useCallback(function (loc) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.tanaScanList({ LocationCode: loc })];
                case 1:
                    res = _a.sent();
                    // 謎のサーバエラー対応。
                    // TODO 削除する
                    if (typeof res.ErrorCode === "number") {
                        alert("未知のサーバエラーです。レスポンス: " + JSON.stringify(res));
                        return [2 /*return*/];
                    }
                    if (res.RtnMessage) {
                        alert(res.RtnMessage);
                    }
                    if (res.RtnCode === "NG")
                        return [2 /*return*/];
                    screenStore.setScanCount(res.ScanCNT);
                    screenStore.setEntries(res.DataList);
                    screenStore.setTanaLocation(res.TanaLocation);
                    screenStore.setTanaban(res.Tanaban);
                    screenStore.setScanStatus(res.ScanStatus);
                    return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    var query = new URLSearchParams(location.search);
    var paramLoc = query.get("loc");
    var paramTana = query.get("tana");
    var tanaloc = "".concat(paramLoc, "%").concat(paramTana);
    React.useEffect(function () {
        if (!acd.view) {
            alert("画面を表示する権限がありません");
            history.go(-1);
            return;
        }
        screenStore.setINoInput("");
        screenStore.setScanCount(0);
        updateScanList(tanaloc);
    }, [paramLoc, acd]);
    var handleAdd = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!screenStore.INoInput) {
                        // alert("IMAGICA 番号を指定してください"); // 鬱陶しそうなのでコメントアウト
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, api.tanaScan({
                            OpeCode: store.userStore.opeInfo.OpeCode,
                            OpeName: store.userStore.opeInfo.OpeName,
                            LocationCode: paramLoc,
                            TanaCode: paramTana,
                            INos: screenStore.INoInput.split(/\s+/).filter(function (s) { return !!s; })
                        })];
                case 1:
                    res = _a.sent();
                    screenStore.setINoInput("");
                    audio.playRegistered(res.RtnCode);
                    if (res.RtnMessage) {
                        alert(res.RtnMessage);
                    }
                    updateScanList(tanaloc);
                    return [2 /*return*/];
            }
        });
    }); }, [paramLoc, paramTana, tanaloc, store, screenStore]);
    var handleCheck = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.tanaCheck({
                        OpeCode: store.userStore.opeInfo.OpeCode,
                        OpeName: store.userStore.opeInfo.OpeName,
                        LocationCode: paramLoc,
                        TanaCode: paramTana,
                    })];
                case 1:
                    res = _a.sent();
                    if (res.RtnMessage) {
                        alert(res.RtnMessage);
                    }
                    updateScanList(tanaloc);
                    return [2 /*return*/];
            }
        });
    }); }, [paramLoc, paramTana, tanaloc, store, screenStore]);
    var handleFixLocation = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.tanaUpdate({
                        OpeCode: store.userStore.opeInfo.OpeCode,
                        OpeName: store.userStore.opeInfo.OpeName,
                        LocationCode: paramLoc,
                        TanaCode: paramTana,
                    })];
                case 1:
                    res = _a.sent();
                    if (res.RtnMessage) {
                        alert(res.RtnMessage);
                    }
                    updateScanList(tanaloc);
                    return [2 /*return*/];
            }
        });
    }); }, [paramLoc, paramTana, tanaloc, store, screenStore]);
    var handleSelectEntry = React.useCallback(function (idx) { return __awaiter(_this, void 0, void 0, function () {
        var e;
        return __generator(this, function (_a) {
            e = screenStore.entries[idx];
            history.push("/sozai-history?ino=".concat(encodeURIComponent(e.INo)));
            return [2 /*return*/];
        });
    }); }, [screenStore, history]);
    var handleEnd = React.useCallback(function () {
        if (screenStore.scanStatus) {
            var result = window.confirm(screenStore.scanStatus + "\n終了してもよろしいですか？");
            if (!result)
                return;
        }
        history.go(-1);
    }, [history, screenStore]);
    return (React.createElement(TanaoroshiScanScreen, { acd: acd, entries: screenStore.entries, INoInput: screenStore.INoInput, tanaLoction: (_a = screenStore.tanaLocation) !== null && _a !== void 0 ? _a : "", tanaban: (_b = screenStore.tanaban) !== null && _b !== void 0 ? _b : "", onChangeINoInput: screenStore.setINoInput, onAdd: handleAdd, onCheck: handleCheck, onFixLocation: handleFixLocation, onSelectEntry: handleSelectEntry, onEnd: handleEnd }));
});

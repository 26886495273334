export function createNullSozaiHistoryQuery() {
    return {
        BeginDate: "",
        EndDate: "",
        Event: "",
        INo: "",
        Daimei: "",
        LocationName: "",
        InputName: ""
    };
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import { LoginScreenStore } from "./LoginScreenStore";
import { SakuhinListScreenStore } from "./SakuhinListScreenStore";
import { SakuhinEditScreenStore } from "./SakuhinEditScreenStore";
// import { SakuhinCodeDialogStore } from "./SakuhinCodeDialogStore";
import { SozaiEditScreenStore } from "./SozaiEditScreenStore";
import { SozaiKensakuHyoujiJokenScreenStore } from "./SozaiKensakuHyoujiJokenScreenStore";
import { SozaiKensakuScreenStore } from "./SozaiKensakuScreenStore";
import { MultiChangeDialogStore } from "./MultiChangeDialogStore";
import { SozaiHistoryScreenStore } from "./SozaiHistoryScreenStore";
import { LocationChangeScreenStore } from "./LocationChangeScreenStore";
import { MeigiChangeScreenStore } from "./MeigiChangeScreenStore";
import { NohinScreenStore } from "./NohinScreenStore";
import { HaikiScreenStore } from "./HaikiScreenStore";
import { ItemListScreenStore } from "./ItemListScreenStore";
import { ItemEditScreenStore } from "./ItemEditScreenStore";
import { TokuiListScreenStore } from "./TokuiListScreenStore";
import { TokuiEditScreenStore } from "./TokuiEditScreenStore";
import { KeijoListScreenStore } from "./KeijoListScreenStore";
import { KeijoEditScreenStore } from "./KeijoEditScreenStore";
import { HensakiListScreenStore } from "./HensakiListScreenStore";
import { HensakiEditScreenStore } from "./HensakiEditScreenStore";
import { MediaListScreenStore } from "./MediaListScreenStore";
import { MediaEditScreenStore } from "./MediaEditScreenStore";
import { LocationListScreenStore } from "./LocationListScreenStore";
import { LocationList2ScreenStore } from "./LocationList2ScreenStore";
import { LocationEditScreenStore } from "./LocationEditScreenStore";
import { ApplicationListScreenStore } from "./ApplicationListScreenStore";
import { ApplicationEditScreenStore } from "./ApplicationEditScreenStore";
import { UserListScreenStore } from "./UserListScreenStore";
import { UserEditScreenStore } from "./UserEditScreenStore";
import { PasswordEditScreenStore } from "./PasswordEditScreenStore";
import { ParameterEditScreenStore } from "./ParameterEditScreenStore";
import { HomeScreenStore } from "./HomeScreenStore";
import { TanaoroshiScreenStore } from "./TanaoroshiScreenStore";
import { HelpDialogStore } from "./HelpDialogStore";
import { TanaoroshiScanScreenStore } from "./TanaoroshiScanScreenStore";
import { UserStore } from "./UserStore";
var Store = /** @class */ (function () {
    function Store() {
        // TODO: 途中から 〜Store がつくようになってる
        this.loginScreen = new LoginScreenStore();
        this.sakuhinListScreen = new SakuhinListScreenStore();
        this.sakuhinEditScreen = new SakuhinEditScreenStore();
        // sakuhinCodeDialog = new SakuhinCodeDialogStore();
        this.sozaiEditScreenStore = new SozaiEditScreenStore();
        this.sozaiKensakuScreen = new SozaiKensakuScreenStore();
        this.multiChangeDialogStore = new MultiChangeDialogStore();
        this.sozaiKensakuHyoujiJokenScreen = new SozaiKensakuHyoujiJokenScreenStore();
        this.sozaiHistoryScreenStore = new SozaiHistoryScreenStore();
        this.locationChangeScreenStore = new LocationChangeScreenStore();
        this.meigiChangeScreenStore = new MeigiChangeScreenStore();
        this.nohinScreenStore = new NohinScreenStore();
        this.haikiScreenStore = new HaikiScreenStore();
        this.itemListScreenStore = new ItemListScreenStore();
        this.itemEditScreenStore = new ItemEditScreenStore();
        this.tokuiListScreenStore = new TokuiListScreenStore();
        this.tokuiEditScreenStore = new TokuiEditScreenStore();
        this.keijoListScreenStore = new KeijoListScreenStore();
        this.keijoEditScreenStore = new KeijoEditScreenStore();
        this.hensakiListScreenStore = new HensakiListScreenStore();
        this.hensakiEditScreenStore = new HensakiEditScreenStore();
        this.mediaListScreenStore = new MediaListScreenStore();
        this.mediaEditScreenStore = new MediaEditScreenStore();
        this.locationListScreenStore = new LocationListScreenStore();
        this.locationList2ScreenStore = new LocationList2ScreenStore();
        this.locationEditScreenStore = new LocationEditScreenStore();
        this.applicationListScreenStore = new ApplicationListScreenStore();
        this.applicationEditScreenStore = new ApplicationEditScreenStore();
        this.userListScreenStore = new UserListScreenStore();
        this.userEditScreenStore = new UserEditScreenStore();
        this.passwordEditScreenStore = new PasswordEditScreenStore();
        this.parameterEditScreenStore = new ParameterEditScreenStore();
        this.homeScreenStore = new HomeScreenStore();
        this.tanaoroshiScreenStore = new TanaoroshiScreenStore();
        this.helpDialogStore = new HelpDialogStore();
        this.tanaoroshiScanScreenStore = new TanaoroshiScanScreenStore();
        this.userStore = new UserStore();
        // TODO 削除して useNestCount() を使う (どのみちこの値は UI に反映していない)
        this.isLoading = true;
    }
    Store.prototype.setIsLoading = function (isLoading) {
        this.isLoading = isLoading;
    };
    __decorate([
        observable
    ], Store.prototype, "isLoading", void 0);
    __decorate([
        action.bound
    ], Store.prototype, "setIsLoading", null);
    return Store;
}());
export { Store };

import * as React from "react";
import { observer } from "mobx-react";
import * as styles from "./IconButton.module.scss";
var iconClassName = "mr-2 ".concat(styles["relup"]);
export var IconButton = observer(function IconButton(props) {
    var _a, _b;
    return (React.createElement("button", { type: "button", className: "btn btn-sm " + ((_a = props.btnClassName) !== null && _a !== void 0 ? _a : "btn-primary") + " text-nowrap " + ((_b = props.className) !== null && _b !== void 0 ? _b : "mr-2"), onClick: props.onClickButton, "data-cy": props.dataCy, disabled: props.disabled },
        React.createElement("span", { className: iconClassName },
            " ",
            React.createElement(props.iconComponent, { size: 18 })),
        props.label));
});

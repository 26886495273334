export function createNullSozai() {
    return {
        INo: "",
        TokuiCode: "",
        TokuiName: "",
        KyuCode: "",
        KyuName: "",
        SakuhinCode: "",
        SakuhinName: "",
        Daimei: "",
        Fukudai: "",
        Series: "",
        KeijoCode: "",
        KeijoName: "",
        MediaCode: "",
        MediaNaiyo: "",
        ProdDate: "",
        TokiJiko: "",
        HensakiCode: "",
        HensakiName: "",
        TantoCode: "",
        TantoName: "",
        LocationCode: "",
        LocationName: "",
        TanaCode: "",
        Tanaban: ""
    };
}

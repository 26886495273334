var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { observer } from "mobx-react";
import { Redirect, Route } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../context";
import * as audio from "../_audio";
import { hasAPIToken, onExpireToken, setAPIToken } from "../../api/apiCommons";
import * as api from "../../api/apiClient";
export var PrivateRoute = observer(function PrivateRoute(_a) {
    var _this = this;
    var children = _a.children, rest = __rest(_a, ["children"]);
    var store = React.useContext(StoreContext);
    var history = useHistory();
    // const auth0 = useAuth0();
    // const { isAuthenticated /*, isLoading */ } = auth0;
    var isAuthenticated = { isAuthenticated: false }.isAuthenticated /*, isLoading */;
    var hasToken = hasAPIToken();
    React.useEffect(function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var param;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.getParameter()];
                    case 1:
                        param = _a.sent();
                        console.log("AUDIO-LOAD", param.ParaSoundOK, param.ParaSoundWG, param.ParaSoundNG);
                        return [4 /*yield*/, audio.register("NG", param.ParaSoundNG)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, audio.register("WG", param.ParaSoundWG)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, audio.register("OK", param.ParaSoundOK)];
                    case 4:
                        _a.sent();
                        console.log("AUDIO-LOAD-COMPLETE");
                        return [2 /*return*/];
                }
            });
        }); })();
        function handleExpireToken(status) {
            var msg = (status === 400) ? "セッションの有効期限が切れました。再ログインしてください。" :
                (status === 403) ? "認証エラーです。再ログインしてください。" :
                    "\u4E0D\u660E\u306A\u30A8\u30E9\u30FC(code: ".concat(status, ")\u3067\u3059\u3002\u518D\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304F\u3060\u3055\u3044\u3002");
            alert(msg);
            setAPIToken(null);
            // 別にいらないはずだが、一応 onExpireToken の発火をひととおり回すため非同期で遷移
            setTimeout(function () { history.replace("/login"); }, 0);
        }
        onExpireToken.add(handleExpireToken);
        return function () { return onExpireToken.remove(handleExpireToken); };
    }, [history]);
    var render = React.useCallback(function (_a) {
        var location = _a.location;
        return ((isAuthenticated || hasToken) ? children : React.createElement(Redirect, { to: { pathname: "/login", state: { from: location } } }));
    }, [children, store, hasToken, isAuthenticated]);
    return (React.createElement(Route, __assign({}, rest, { render: render })));
});

export function createNullACD() {
    return { view: false, a: false, c: false, d: false };
}
export function parseACDStr(v) {
    var ret = createNullACD();
    for (var i = 0; i < v.length; ++i) {
        switch (v[i]) {
            case "A":
                ret.a = true;
                break;
            case "C":
                ret.c = true;
                break;
            case "D":
                ret.d = true;
                break;
            default:
                console.error("unknown ACD str: " + v);
                break;
        }
    }
    return ret;
}

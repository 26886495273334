var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action, computed } from "mobx";
import { formatYYYYMMDD, parseYYYYMMDD } from "../util/dateUtil";
import { createNullSozaiDetail } from "../types/SozaiDetail";
var SozaiEditScreenStore = /** @class */ (function () {
    function SozaiEditScreenStore() {
        this.prodDateInput = null; // sozaiDetail.ProdDate (string) を常に反映する Date
        this.entryDateInput = null; // sozaiDetail.EntryDate を以下略
        this.updateDateInput = null; // sozaiDetail.UpdateDate を以下略
        this.sozaiDetail = createNullSozaiDetail(); // 上のため setSozaiDetail() 以外で変更してはならない……
        this.tokuiInput = "";
        this.tokuiSuggestions = [];
        this.isTokuiValid = false;
        this.kyuInput = "";
        this.kyuSuggestions = [];
        this.isKyuValid = false;
        this.sakuhinInput = "";
        this.sakuhinSuggestions = [];
        this.isSakuhinValid = false;
        this.keijoInput = "";
        this.keijoSuggestions = [];
        this.isKeijoValid = false;
        this.mediaInput = "";
        this.mediaSuggestions = [];
        this.isMediaValid = false;
        this.tantoInput = "";
        this.tantoSuggestions = [];
        this.isTantoValid = false;
        this.locationInput = "";
        this.locationSuggestions = [];
        this.isLocationValid = false;
        this.tanabanInput = "";
        this.tanabanSuggestions = [];
        this.isTanabanValid = false;
        this.warnDialogMessage = null;
        this.invalidItems = [];
    }
    SozaiEditScreenStore.prototype.resetAll = function () {
        this.setSozaiDetail(createNullSozaiDetail());
        this.tokuiInput = "";
        this.tokuiSuggestions = [];
        this.isTokuiValid = false;
        this.kyuInput = "";
        this.kyuSuggestions = [];
        this.isKyuValid = false;
        this.sakuhinInput = "";
        this.sakuhinSuggestions = [];
        this.isSakuhinValid = false;
        this.keijoInput = "";
        this.keijoSuggestions = [];
        this.isKeijoValid = false;
        this.mediaInput = "";
        this.mediaSuggestions = [];
        this.isMediaValid = false;
        this.tantoInput = "";
        this.tantoSuggestions = [];
        this.isTantoValid = false;
        this.locationInput = "";
        this.locationSuggestions = [];
        this.isLocationValid = false;
        this.tanabanInput = "";
        this.tanabanSuggestions = [];
        this.isTanabanValid = false;
    };
    SozaiEditScreenStore.prototype.changeSozaiDetailAndInputs = function (v) {
        this.setSozaiDetail(v);
        this.tokuiInput = v.TokuiName;
        this.isTokuiValid = true;
        this.kyuInput = v.KyuName;
        this.isKyuValid = true;
        this.sakuhinInput = v.SakuhinName;
        this.isSakuhinValid = true;
        this.keijoInput = v.KeijoName;
        this.isKeijoValid = true;
        this.mediaInput = v.MediaNaiyo;
        this.isMediaValid = true;
        this.tantoInput = v.TantoName;
        this.isTantoValid = true;
        this.locationInput = v.LocationName;
        this.isLocationValid = true;
        this.tanabanInput = v.Tanaban;
        this.isTanabanValid = true;
    };
    SozaiEditScreenStore.prototype.setProdDateInput = function (v) {
        this.prodDateInput = v;
        this.sozaiDetail.ProdDate = v ? formatYYYYMMDD(v) : "";
    };
    SozaiEditScreenStore.prototype.setSozaiDetail = function (v) {
        this.sozaiDetail = v;
        this.prodDateInput = parseYYYYMMDD(v.ProdDate);
        this.entryDateInput = parseYYYYMMDD(v.EntryDate);
        this.updateDateInput = parseYYYYMMDD(v.UpdateDate);
    };
    SozaiEditScreenStore.prototype.resetSozaiDetail = function () { this.setSozaiDetail(createNullSozaiDetail()); };
    SozaiEditScreenStore.prototype.setTokuiInput = function (v) { this.tokuiInput = v; };
    SozaiEditScreenStore.prototype.setTokuiSuggestions = function (v) { this.tokuiSuggestions = v; };
    SozaiEditScreenStore.prototype.setIsTokuiValid = function (v) { this.isTokuiValid = v; };
    SozaiEditScreenStore.prototype.resetTokuiSuggestions = function () { this.tokuiSuggestions = []; };
    SozaiEditScreenStore.prototype.setKyuInput = function (v) { this.kyuInput = v; };
    SozaiEditScreenStore.prototype.setKyuSuggestions = function (v) { this.kyuSuggestions = v; };
    SozaiEditScreenStore.prototype.setIsKyuValid = function (v) { this.isKyuValid = v; };
    SozaiEditScreenStore.prototype.resetKyuSuggestions = function () { this.kyuSuggestions = []; };
    SozaiEditScreenStore.prototype.setSakuhinInput = function (v) { this.sakuhinInput = v; };
    SozaiEditScreenStore.prototype.setSakuhinSuggestions = function (v) { this.sakuhinSuggestions = v; };
    SozaiEditScreenStore.prototype.setIsSakuhinValid = function (v) { this.isSakuhinValid = v; };
    SozaiEditScreenStore.prototype.resetSakuhinSuggestions = function () { this.sakuhinSuggestions = []; };
    SozaiEditScreenStore.prototype.setKeijoInput = function (v) { this.keijoInput = v; };
    SozaiEditScreenStore.prototype.setKeijoSuggestions = function (v) { this.keijoSuggestions = v; };
    SozaiEditScreenStore.prototype.setIsKeijoValid = function (v) { this.isKeijoValid = v; };
    SozaiEditScreenStore.prototype.resetKeijoSuggestions = function () { this.keijoSuggestions = []; };
    SozaiEditScreenStore.prototype.setMediaInput = function (v) { this.mediaInput = v; };
    SozaiEditScreenStore.prototype.setMediaSuggestions = function (v) { this.mediaSuggestions = v; };
    SozaiEditScreenStore.prototype.setIsMediaValid = function (v) { this.isMediaValid = v; };
    SozaiEditScreenStore.prototype.resetMediaSuggestions = function () { this.mediaSuggestions = []; };
    SozaiEditScreenStore.prototype.setTantoInput = function (v) { this.tantoInput = v; };
    SozaiEditScreenStore.prototype.setTantoSuggestions = function (v) { this.tantoSuggestions = v; };
    SozaiEditScreenStore.prototype.setIsTantoValid = function (v) { this.isTantoValid = v; };
    SozaiEditScreenStore.prototype.resetTantoSuggestions = function () { this.tantoSuggestions = []; };
    SozaiEditScreenStore.prototype.setLocationInput = function (v) {
        if (this.locationInput === v)
            return;
        this.locationInput = v;
        this.resetTanabanInputState();
    };
    SozaiEditScreenStore.prototype.setLocationSuggestions = function (v) { this.locationSuggestions = v; };
    SozaiEditScreenStore.prototype.setIsLocationValid = function (v) {
        this.isLocationValid = v;
        if (!v) {
            this.resetTanabanInputState();
        }
    };
    SozaiEditScreenStore.prototype.resetLocationSuggestions = function () { this.locationSuggestions = []; };
    SozaiEditScreenStore.prototype.setTanabanInput = function (v) { this.tanabanInput = v; };
    SozaiEditScreenStore.prototype.setTanabanSuggestions = function (v) { this.tanabanSuggestions = v; };
    SozaiEditScreenStore.prototype.setIsTanabanValid = function (v) { this.isTanabanValid = v; };
    SozaiEditScreenStore.prototype.resetTanabanSuggestions = function () { this.tanabanSuggestions = []; };
    SozaiEditScreenStore.prototype.resetTanabanInputState = function () {
        this.setTanabanInput("");
        this.setIsTanabanValid(false);
        this.resetTanabanSuggestions();
        // prodDate に触らないので setSozaiDetail() を経由せず直接代入してもセーフ
        this.sozaiDetail.TanaCode = "";
        this.sozaiDetail.Tanaban = "";
    };
    SozaiEditScreenStore.prototype.setWarnDialogMessage = function (v) { this.warnDialogMessage = v; };
    SozaiEditScreenStore.prototype.resetWarnDialogMessage = function () {
        this.warnDialogMessage = null;
        this.invalidItems = [];
    };
    SozaiEditScreenStore.prototype.setInvalidItems = function (v) { this.invalidItems = v; };
    Object.defineProperty(SozaiEditScreenStore.prototype, "needsToShowWarnDialog", {
        get: function () { return this.warnDialogMessage != null || this.invalidItems.length > 0; },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "prodDateInput", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "entryDateInput", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "updateDateInput", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "sozaiDetail", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "tokuiInput", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "tokuiSuggestions", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "isTokuiValid", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "kyuInput", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "kyuSuggestions", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "isKyuValid", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "sakuhinInput", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "sakuhinSuggestions", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "isSakuhinValid", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "keijoInput", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "keijoSuggestions", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "isKeijoValid", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "mediaInput", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "mediaSuggestions", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "isMediaValid", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "tantoInput", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "tantoSuggestions", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "isTantoValid", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "locationInput", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "locationSuggestions", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "isLocationValid", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "tanabanInput", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "tanabanSuggestions", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "isTanabanValid", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "warnDialogMessage", void 0);
    __decorate([
        observable
    ], SozaiEditScreenStore.prototype, "invalidItems", void 0);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetAll", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "changeSozaiDetailAndInputs", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setProdDateInput", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setSozaiDetail", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetSozaiDetail", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setTokuiInput", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setTokuiSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setIsTokuiValid", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetTokuiSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setKyuInput", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setKyuSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setIsKyuValid", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetKyuSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setSakuhinInput", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setSakuhinSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setIsSakuhinValid", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetSakuhinSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setKeijoInput", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setKeijoSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setIsKeijoValid", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetKeijoSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setMediaInput", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setMediaSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setIsMediaValid", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetMediaSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setTantoInput", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setTantoSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setIsTantoValid", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetTantoSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setLocationInput", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setLocationSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setIsLocationValid", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetLocationSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setTanabanInput", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setTanabanSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setIsTanabanValid", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetTanabanSuggestions", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetTanabanInputState", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setWarnDialogMessage", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "resetWarnDialogMessage", null);
    __decorate([
        action.bound
    ], SozaiEditScreenStore.prototype, "setInvalidItems", null);
    __decorate([
        computed
    ], SozaiEditScreenStore.prototype, "needsToShowWarnDialog", null);
    return SozaiEditScreenStore;
}());
export { SozaiEditScreenStore };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import DatePicker from "react-datepicker";
import { X, Search } from "react-feather";
import { eventKindNames, eventKindOfString, stringOfEventKind } from "../../types/EventKind";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
import { LabeledSelect2 } from "../common/LabeledSelect2";
import { formatWeekDay } from "../../util/dateUtil";
export var SozaiHistoryScreen = observer(function (props) {
    var acd = props.acd, query = props.query, entries = props.entries, beginDate = props.beginDate, endDate = props.endDate, isInitial = props.isInitial, onSearch = props.onSearch, onEnd = props.onEnd, onChangeQuery = props.onChangeQuery, onChangeBeginDate = props.onChangeBeginDate, onChangeEndDate = props.onChangeEndDate, onSelectEntry = props.onSelectEntry;
    // const handleModifyBeginDate = React.useCallback(v => onChangeQuery({ ...query, BeginDate: v }), [query, onChangeQuery]);
    // const handleModifyEndDate = React.useCallback(v => onChangeQuery({ ...query, EndDate: v }), [query, onChangeQuery]);
    var handleModifyEvent = React.useCallback(function (v) { return onChangeQuery(__assign(__assign({}, query), { Event: eventKindOfString(v) })); }, [query, onChangeQuery]);
    var handleModifyINo = React.useCallback(function (v) { return onChangeQuery(__assign(__assign({}, query), { INo: v })); }, [query, onChangeQuery]);
    var handleModifyDaimei = React.useCallback(function (v) { return onChangeQuery(__assign(__assign({}, query), { Daimei: v })); }, [query, onChangeQuery]);
    var handleModifyLocationName = React.useCallback(function (v) { return onChangeQuery(__assign(__assign({}, query), { LocationName: v })); }, [query, onChangeQuery]);
    var handleModifyInputName = React.useCallback(function (v) { return onChangeQuery(__assign(__assign({}, query), { InputName: v })); }, [query, onChangeQuery]);
    if (!acd.view)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-end mb-1" },
            React.createElement(IconButton, { iconComponent: Search, label: "\u691C\u7D22", onClickButton: onSearch }),
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container mb-3" },
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "text-secondary" }, "\u958B\u59CB\u65E5\u4ED8"),
                    React.createElement(DatePicker, { dateFormat: "yyyy/MM/dd", dateFormatCalendar: "yyyy \u5E74 MM \u6708", className: "form-control form-control-sm", formatWeekDay: formatWeekDay, selected: beginDate, showPopperArrow: false, onChange: onChangeBeginDate, showYearDropdown: true, wrapperClassName: "w-100 align-bottom" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "text-secondary" }, "\u7D42\u4E86\u65E5\u4ED8"),
                    React.createElement(DatePicker, { dateFormat: "yyyy/MM/dd", dateFormatCalendar: "yyyy \u5E74 MM \u6708", className: "form-control form-control-sm", formatWeekDay: formatWeekDay, selected: endDate, showPopperArrow: false, onChange: onChangeEndDate, showYearDropdown: true, wrapperClassName: "w-100 align-bottom" })),
                React.createElement("div", { className: "col-2" },
                    React.createElement(LabeledSelect2, { label: "\u7A2E\u5225", options: eventKindNames, selectedOption: stringOfEventKind(query.Event), onModify: handleModifyEvent })),
                React.createElement("div", { className: "col-4" },
                    React.createElement(LabeledInputText, { label: "\uFF29\uFF2D\uFF21\uFF27\uFF29\uFF23\uFF21\u756A\u53F7", type: "text", value: query.INo, onModify: handleModifyINo })),
                React.createElement("div", { className: "col-4" },
                    React.createElement(LabeledInputText, { label: "\u984C\u540D", type: "text", value: query.Daimei, onModify: handleModifyDaimei })),
                React.createElement("div", { className: "col-4" },
                    React.createElement(LabeledInputText, { label: "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3", type: "text", value: query.LocationName, onModify: handleModifyLocationName })),
                React.createElement("div", { className: "col-4" },
                    React.createElement(LabeledInputText, { label: "\u66F4\u65B0\u8005", type: "text", value: query.InputName, onModify: handleModifyInputName })))),
        isInitial ?
            React.createElement("div", { className: "table-responsive-lg mt-2" }) :
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "table-responsive-lg mt-2" },
                    React.createElement("table", { className: "table table-bordered table-striped table-hover " },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "\u767A\u751F\u65E5"),
                                React.createElement("th", null, "\u6642\u523B"),
                                React.createElement("th", null, "\u7A2E\u5225"),
                                React.createElement("th", null, "\uFF29\uFF2D\uFF21\uFF27\uFF29\uFF23\uFF21\u756A\u53F7"),
                                React.createElement("th", null, "\u984C\u540D"),
                                React.createElement("th", null, "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3"),
                                React.createElement("th", null, "\u68DA\u756A"),
                                React.createElement("th", null, "\u5F97\u610F\u5148"),
                                React.createElement("th", null, "\u8A18\u9332"),
                                React.createElement("th", null, "\u66F4\u65B0\u8005"))),
                        React.createElement("tbody", null, entries.map(function (e, i) {
                            return React.createElement("tr", { key: "".concat(e.EDate, "--").concat(e.ETime, "--").concat(e.INo), onDoubleClick: function () { return onSelectEntry(i); } },
                                React.createElement("td", null, e.EDate),
                                React.createElement("td", null, e.ETime),
                                React.createElement("td", null, stringOfEventKind(e.Event)),
                                React.createElement("td", null, e.INo),
                                React.createElement("td", null, e.Daimei),
                                React.createElement("td", null, e.LocationName),
                                React.createElement("td", null, e.Tanaban),
                                React.createElement("td", null, e.TokuiName),
                                React.createElement("td", null, e.Nohinsyo + " (".concat(e.NohinSaki, ")")),
                                React.createElement("td", null, e.InputName));
                        })))),
                React.createElement(React.Fragment, null, (entries.length) ?
                    null :
                    React.createElement("div", { className: "text-center" }, "\u8A72\u5F53\u30C7\u30FC\u30BF\u304C\u3042\u308A\u307E\u305B\u3093")))));
});

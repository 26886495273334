import * as React from "react";
import { LabeledInputText } from "../common/LabeledInputText";
function onSubmit(ev) {
    ev.preventDefault();
}
export function LoginScreen(props) {
    var username = props.username, password = props.password, onModifyUsername = props.onModifyUsername, onModifyPassword = props.onModifyPassword, onLogin = props.onLogin;
    return (React.createElement(React.Fragment, null,
        React.createElement("nav", { className: "navbar navbar-expand-md navbar-dark bg-dark mb-2" },
            React.createElement("div", { className: "navbar-brand" }, "LoCoMo\u5EAB"),
            "\u30ED\u30B0\u30A4\u30F3"),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row my-3" },
                React.createElement("div", { className: "col-6 border rounded mx-auto p-3" },
                    React.createElement("form", { onSubmit: onSubmit },
                        React.createElement("div", { className: "form-group" },
                            React.createElement(LabeledInputText, { type: "text", label: "ユーザID", value: username, onModify: onModifyUsername })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement(LabeledInputText, { type: "password", label: "パスワード", value: password, onModify: onModifyPassword })),
                        React.createElement("button", { type: "submit", className: "d-block mx-auto btn btn-primary", onClick: onLogin }, "\u30ED\u30B0\u30A4\u30F3")))))));
}

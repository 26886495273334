var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import { Edit, Trash2, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
export var MediaEditScreen = observer(function (props) {
    var acd = props.acd, isEditMode = props.isEditMode, media = props.media, onModify = props.onModify, onRegister = props.onRegister, onDelete = props.onDelete, onEnd = props.onEnd;
    var handleModifyMediaCode = React.useCallback(function (v) { return onModify(__assign(__assign({}, media), { MediaCode: v })); }, [onModify, media]);
    var handleModifyMediaName = React.useCallback(function (v) { return onModify(__assign(__assign({}, media), { MediaName: v })); }, [onModify, media]);
    if (!acd.view)
        return null;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-end mb-2" },
            React.createElement(IconButton, { iconComponent: Edit, label: "\u767B\u9332", onClickButton: onRegister, disabled: isEditMode ? !acd.c : !acd.a }),
            isEditMode && React.createElement(IconButton, { btnClassName: "btn-outline-danger", iconComponent: Trash2, label: "\u524A\u9664", onClickButton: onDelete, disabled: !acd.d }),
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u30E1\u30C7\u30A3\u30A2\u30B3\u30FC\u30C9", value: media.MediaCode, onModify: handleModifyMediaCode }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u30E1\u30C7\u30A3\u30A2\u5185\u5BB9", value: media.MediaName, onModify: handleModifyMediaName })))));
});

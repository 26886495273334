var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
var ApplicationListScreenStore = /** @class */ (function () {
    function ApplicationListScreenStore() {
        this.apps = [];
        this.searchText = "";
    }
    ApplicationListScreenStore.prototype.setApps = function (v) { this.apps = v; };
    ApplicationListScreenStore.prototype.setSearchText = function (v) { this.searchText = v; };
    ApplicationListScreenStore.prototype.eraseSearchText = function () { this.searchText = ""; };
    __decorate([
        observable
    ], ApplicationListScreenStore.prototype, "apps", void 0);
    __decorate([
        observable
    ], ApplicationListScreenStore.prototype, "searchText", void 0);
    __decorate([
        action.bound
    ], ApplicationListScreenStore.prototype, "setApps", null);
    __decorate([
        action.bound
    ], ApplicationListScreenStore.prototype, "setSearchText", null);
    __decorate([
        action.bound
    ], ApplicationListScreenStore.prototype, "eraseSearchText", null);
    return ApplicationListScreenStore;
}());
export { ApplicationListScreenStore };

import { createNullACD } from "./ACD";
export function createNullAppAuthTable() {
    return {
        "item-master": createNullACD(),
        "keijo-master": createNullACD(),
        "tokui-master": createNullACD(),
        "hensaki-master": createNullACD(),
        "media-master": createNullACD(),
        "location-master": createNullACD(),
        "app-master": createNullACD(),
        "user-master": createNullACD(),
        "sakuhin-master": createNullACD(),
        "sozai-search": createNullACD(),
        "sozai-history": createNullACD(),
        "sozai-new": createNullACD(),
        "location-change": createNullACD(),
        "meigi-change": createNullACD(),
        nohin: createNullACD(),
        discard: createNullACD(),
        stocktaking: createNullACD(),
        parameter: createNullACD()
    };
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import { Edit3, Delete, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
import { SozaiTable } from "../common/SozaiTable";
import { CompletableInput } from "../common/CompletableInput";
import { Navbar } from "../common/Navbar";
export var LocationChangeScreen = observer(function (props) {
    var acd = props.acd, inoInput = props.inoInput, tokiJikoInput = props.tokiJikoInput, entries = props.entries, isSubmitted = props.isSubmitted, onSubmit = props.onSubmit, onClear = props.onClear, onEnd = props.onEnd, onChangeINo = props.onChangeINo, onBlurINo = props.onBlurINo, onChangeTokiJiko = props.onChangeTokiJiko, onAddLine = props.onAddLine, onRemoveLine = props.onRemoveLine, isNameInputMode = props.isNameInputMode, onToggleNameInputMode = props.onToggleNameInputMode, 
    // isNameInputMode が true の時のみ利用
    completePropsLocation = props.completePropsLocation, completePropsTanaban = props.completePropsTanaban, 
    // isNameInputMode が false の時のみ利用
    locationCodeInput = props.locationCodeInput, locationName = props.locationName, tanaCodeInput = props.tanaCodeInput, tanaban = props.tanaban, isValidTanaCodeInput = props.isValidTanaCodeInput, onChangeLocationCode = props.onChangeLocationCode, onChangeTanaCode = props.onChangeTanaCode, onBlurLocationCode = props.onBlurLocationCode, onEnter = props.onEnter, onEnterTanaban = props.onEnterTanaban;
    if (!acd.view)
        return null;
    return (React.createElement("div", { className: "d-flex flex-column h-100" },
        React.createElement(Navbar, { name: "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u5909\u66F4" }),
        React.createElement("div", { className: "container-fuild " },
            React.createElement("div", { className: "d-flex flex-wrap-reverse justify-content-between mb-2" },
                React.createElement("div", { className: "flex-grow-1 flex-shrink-1 justify-content-between align-self-start d-flex align-items-end" },
                    React.createElement("div", { className: "flex-grow-0 flex-shrink-1 d-flex justify-content-between align-items-end ml-2 mr-3" },
                        React.createElement(LabeledInputText, { className: "mr-1", label: "ＩＭＡＧＩＣＡ番号", value: inoInput, onModify: onChangeINo, onEnter: onAddLine, onBlur: onBlurINo }),
                        React.createElement("button", { className: "btn btn-sm btn-primary text-nowrap mr-0", onClick: onAddLine }, "\u8FFD\u52A0")),
                    React.createElement("div", { className: "flex-grow-0 flex-shrink-1 d-flex align-items-start border border-secondary rounded p-2 mr-3" },
                        isNameInputMode ?
                            React.createElement("div", { className: "mr-1" },
                                React.createElement("span", { className: "text-secondary" }, "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u540D"),
                                React.createElement(CompletableInput, __assign({}, completePropsLocation))) :
                            React.createElement("div", null,
                                React.createElement(LabeledInputText, { className: "flex-shrink-1 mr-1", label: "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u30B3\u30FC\u30C9", value: locationCodeInput, onModify: onChangeLocationCode, onEnter: onEnter, onBlur: onBlurLocationCode }),
                                locationName || "-"),
                        isNameInputMode ?
                            React.createElement("div", { className: "mr-1" },
                                React.createElement("span", { className: "text-secondary" }, "\u68DA\u756A"),
                                React.createElement(CompletableInput, __assign({}, completePropsTanaban))) :
                            React.createElement("div", null,
                                React.createElement(LabeledInputText, { className: "flex-shrink-1 mr-1", label: "\u68DA\u756A\u30B3\u30FC\u30C9", value: tanaCodeInput, onModify: onChangeTanaCode, disabled: !locationName, onEnter: onEnterTanaban }),
                                isValidTanaCodeInput ?
                                    (tanaban || "（NULL）") : "-"),
                        React.createElement(LabeledInputText, { className: "flex-shrink-1 mr-1", label: "\u7279\u8A18\u4E8B\u9805", value: tokiJikoInput, onModify: onChangeTokiJiko }))),
                React.createElement("div", { className: "flex-grow-1 flex-shrink-0 align-self-end ml-2 mb-2 text-right text-nowrap" },
                    React.createElement("div", { className: "custom-control custom-checkbox d-inline-block mr-3 pr-3 border-right border-secondary" },
                        React.createElement("input", { type: "checkbox", className: "custom-control-input", id: "customCheck1", checked: isNameInputMode, onChange: onToggleNameInputMode }),
                        React.createElement("label", { className: "custom-control-label", htmlFor: "customCheck1" }, "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u540D\u524D\u3067\u6307\u5B9A")),
                    React.createElement("span", { className: "mr-3" },
                        "\u70B9\u6570: ",
                        entries ? entries.length : 0),
                    React.createElement(IconButton, { iconComponent: Edit3, label: "\u66F4\u65B0", onClickButton: onSubmit, disabled: !acd.c || !(entries && entries.length) }),
                    React.createElement(IconButton, { iconComponent: Delete, label: "\u30EA\u30BB\u30C3\u30C8", onClickButton: onClear, disabled: !(entries && entries.length) }),
                    React.createElement(IconButton, { btnClassName: "btn-outline-primary", className: "mr-2", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })))),
        React.createElement(SozaiTable, { entries: entries, onRemoveLine: onRemoveLine, highlightsLocationCol: isSubmitted, isResultDisplay: isSubmitted })));
});
/*
export interface LocationChangeScreenProps {
  acd: ACD;
  INo: string;
  locationCode: string;
  destLocationName: string;
  tanaban: string;
  destTanaban: string;
  entries: SozaiDetail[];
  isSubmitted: boolean;

  onSubmit: () => void;
  onClear: () => void;
  onEnd: () => void;
  onChangeINo: (v: string) => void;
  onChangeLocationCode: (v: string) => void;
  onChangeTanaban: (v: string) => void;
  onBlurINo: () => void;
  onBlurLocationCode: () => void;
  onEnter: () => void;
  onEnterTanaban: () => void;
  onAddLine: () => void;
  onRemoveLine: (idx: number) => void;
}

export const LocationChangeScreen = observer(function (props: LocationChangeScreenProps) {
  const {
    acd,
    INo,
    locationCode,
    destLocationName,
    tanaban,
    destTanaban,
    isSubmitted,
    entries,
    onSubmit,
    onClear,
    onEnd,
    onChangeINo,
    onChangeLocationCode,
    onChangeTanaban,
    onBlurINo,
    onBlurLocationCode,
    onEnter,
    onEnterTanaban,
    onAddLine,
    onRemoveLine,
  } = props;

  // input 要素への Enter キー入力で入力先をトグルする関連コード
  // 要検討？: このあたりの挙動も props で露出すべきか？
  // const refInputUserCode = React.useRef<HTMLInputElement>(null);
  // const refInputINo = React.useRef<HTMLInputElement>(null);
  // const refInputLocationCode = React.useRef<HTMLInputElement>(null);
  // const refInputTanaban = React.useRef<HTMLInputElement>(null);
  // const handleEnterUserCode = React.useCallback(() => {
  //   if (refInputINo.current) {
  //     refInputINo.current.select();
  //     refInputINo.current.focus();
  //   }
  // }, [refInputINo]);
  // const onEnterUserCode = handleEnterUserCode;

  // どれでも Enter されたら外側で入力チェックする
  const onEnterINo = onEnter;
  const onEnterLocationCode = onEnter;

  if (!acd.view)
    return null;

  return (
    <>
      <div className="d-flex justify-content-between align-content-center mb-2">
        <div className="ml-2">
          <IconButton iconComponent={ArrowLeft} label="戻る" onClickButton={onEnd} />
        </div>
        <div>
          {
            (entries && entries.length) ?
              <span className="m-3">点数: {entries.length}</span> :
              null
          }
          <IconButton iconComponent={Edit3} label="更新" onClickButton={onSubmit} disabled={!acd.c} />
          <IconButton iconComponent={Slash} label="リセット" onClickButton={onClear} />
          {// <IconButton iconComponent={X} label="終了" onClickButton={() => {}} />
          }
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-4">
            <div className="container p-3 border rounded">
              <div className="row">
                <div className="col">
                  <LabeledInputText label="ＩＭＡＧＩＣＡ番号" type="text" value={INo} onModify={onChangeINo} onBlur={onBlurINo} onEnter={onEnterINo} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <div className="float-right align-bottom">
                    <button type="button" className={"btn btn-sm btn-outline-secondary"} onClick={onAddLine}>追加</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="container p-3 border rounded">
              <div className="row">
                <div className="col-6">
                  <LabeledInputText label="ロケーション" type="text" value={locationCode} onModify={onChangeLocationCode} onBlur={onBlurLocationCode} onEnter={onEnterLocationCode} />
                  <p className="m-1">{destLocationName}</p>
                </div>
                <div className="col-6">
                  <LabeledInputText label="棚番号" type="text" value={tanaban} onModify={onChangeTanaban} onEnter={onEnterTanaban} />
                  <p className="m-1">{destTanaban}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
        // // 担当者コードは一旦はずした
        // <div className="col-md-3 col-sm-6">
        //   <LabeledInputText rawRef={refInputUserCode} label="担当者コード" type="text" value={userCode} onModify={onChangeUserCode} onBlur={onBlurUserCode} onEnter={onEnterUserCode} />
        //   <p className="m-1">
        //     {userName}
        //   </p>
        // </div>
        }

      </div>
      <SozaiTable entries={entries} onRemoveLine={onRemoveLine} highlightsLocationCol={isSubmitted} isResultDisplay={isSubmitted} />
    </>
  );
});
*/

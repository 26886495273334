var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import { Edit, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
export var PasswordEditScreen = observer(function (props) {
    var passwordChangeInputData = props.passwordChangeInputData, onModify = props.onModify, onRegister = props.onRegister, onEnd = props.onEnd;
    var handleModifyCurrentPassword = React.useCallback(function (v) { return onModify(__assign(__assign({}, passwordChangeInputData), { currentpassword: v })); }, [onModify, passwordChangeInputData]);
    var handleModifyNewPassword = React.useCallback(function (v) { return onModify(__assign(__assign({}, passwordChangeInputData), { newpassword: v })); }, [onModify, passwordChangeInputData]);
    var handleModifyConfirmPassword = React.useCallback(function (v) { return onModify(__assign(__assign({}, passwordChangeInputData), { confirmpassword: v })); }, [onModify, passwordChangeInputData]);
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-end mb-2" },
            React.createElement(IconButton, { iconComponent: Edit, label: "\u767B\u9332", onClickButton: onRegister }),
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u73FE\u30D1\u30B9\u30EF\u30FC\u30C9", type: "password", value: passwordChangeInputData.currentpassword, onModify: handleModifyCurrentPassword }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u65B0\u30D1\u30B9\u30EF\u30FC\u30C9", type: "password", value: passwordChangeInputData.newpassword, onModify: handleModifyNewPassword }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u65B0\u30D1\u30B9\u30EF\u30FC\u30C9\uFF08\u78BA\u8A8D\uFF09", type: "password", value: passwordChangeInputData.confirmpassword, onModify: handleModifyConfirmPassword })))));
});

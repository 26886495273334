var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import { Edit, Trash2, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
export var ApplicationEditScreen = observer(function (props) {
    var acd = props.acd, isEditMode = props.isEditMode, app = props.app, onModify = props.onModify, onRegister = props.onRegister, onDelete = props.onDelete, onEnd = props.onEnd;
    var handleModifyAppID = React.useCallback(function (v) { return onModify(__assign(__assign({}, app), { AppID: v })); }, [onModify, app]);
    var handleModifyAppName = React.useCallback(function (v) { return onModify(__assign(__assign({}, app), { AppName: v })); }, [onModify, app]);
    var handleModifyMenuOrder = React.useCallback(function (v) { return onModify(__assign(__assign({}, app), { MenuOrder: v })); }, [onModify, app]);
    var handleModifyAuthRoles = React.useCallback(function (v) { return onModify(__assign(__assign({}, app), { AuthRoles: v })); }, [onModify, app]);
    var handleModifyAuthTypes = React.useCallback(function (v) { return onModify(__assign(__assign({}, app), { AuthTypes: v })); }, [onModify, app]);
    if (!acd.view)
        return null;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-end b-2" },
            React.createElement(IconButton, { iconComponent: Edit, label: "\u767B\u9332", onClickButton: onRegister, disabled: isEditMode ? !acd.c : !acd.a }),
            isEditMode && React.createElement(IconButton, { btnClassName: "btn-outline-danger", iconComponent: Trash2, label: "\u524A\u9664", onClickButton: onDelete, disabled: !acd.d }),
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u30A2\u30D7\u30EA\uFF29\uFF24", value: app.AppID, onModify: handleModifyAppID }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u30A2\u30D7\u30EA\u8868\u793A\u540D", value: app.AppName, onModify: handleModifyAppName })),
                React.createElement("div", { className: "col-4" },
                    React.createElement(LabeledInputText, { label: "\u30E1\u30CB\u30E5\u30FC\u8868\u793A\u9806", value: app.MenuOrder, onModify: handleModifyMenuOrder }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u8A31\u53EF\u30AF\u30E9\u30B9\u306E\u30B3\u30F3\u30DE\u533A\u5207\u308A\u30EA\u30B9\u30C8", value: app.AuthRoles, onModify: handleModifyAuthRoles }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u30AF\u30E9\u30B9\u306E\u30A2\u30D7\u30EA\u6A29\u9650\u306E\u30B3\u30F3\u30DE\u533A\u5207\u308A\u30EA\u30B9\u30C8", value: app.AuthTypes, onModify: handleModifyAuthTypes })))));
});

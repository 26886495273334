var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import { createNullSozaiDetail } from "../types/SozaiDetail";
var MultiChangeDialogStore = /** @class */ (function () {
    function MultiChangeDialogStore() {
        this.shows = false;
        // 全部は使わないことに注意。一括編集で使うプロパティのみ利用する。
        this.sozaiDetail = createNullSozaiDetail();
        this.tokuiInput = "";
        this.tokuiSuggestions = [];
        this.isTokuiValid = false;
        this.kyuInput = "";
        this.kyuSuggestions = [];
        this.isKyuValid = false;
        this.sakuhinInput = "";
        this.sakuhinSuggestions = [];
        this.isSakuhinValid = false;
        this.tantoInput = "";
        this.tantoSuggestions = [];
        this.isTantoValid = false;
    }
    MultiChangeDialogStore.prototype.changeShowsTrue = function () {
        this.shows = true;
        this.sozaiDetail = createNullSozaiDetail();
        this.tokuiInput = "";
        this.tokuiSuggestions = [];
        this.isTokuiValid = false;
        this.kyuInput = "";
        this.kyuSuggestions = [];
        this.isKyuValid = false;
        this.sakuhinInput = "";
        this.sakuhinSuggestions = [];
        this.isSakuhinValid = false;
        this.tantoInput = "";
        this.tantoSuggestions = [];
        this.isTantoValid = false;
    };
    MultiChangeDialogStore.prototype.setShowsFalse = function () { this.shows = false; };
    MultiChangeDialogStore.prototype.setSozaiDetail = function (v) { this.sozaiDetail = v; };
    MultiChangeDialogStore.prototype.setTokuiInput = function (v) { this.tokuiInput = v; };
    MultiChangeDialogStore.prototype.setTokuiSuggestions = function (v) { this.tokuiSuggestions = v; };
    MultiChangeDialogStore.prototype.setIsTokuiValid = function (v) { this.isTokuiValid = v; };
    MultiChangeDialogStore.prototype.resetTokuiSuggestions = function () { this.tokuiSuggestions = []; };
    MultiChangeDialogStore.prototype.setKyuInput = function (v) { this.kyuInput = v; };
    MultiChangeDialogStore.prototype.setKyuSuggestions = function (v) { this.kyuSuggestions = v; };
    MultiChangeDialogStore.prototype.setIsKyuValid = function (v) { this.isKyuValid = v; };
    MultiChangeDialogStore.prototype.resetKyuSuggestions = function () { this.kyuSuggestions = []; };
    MultiChangeDialogStore.prototype.setSakuhinInput = function (v) { this.sakuhinInput = v; };
    MultiChangeDialogStore.prototype.setSakuhinSuggestions = function (v) { this.sakuhinSuggestions = v; };
    MultiChangeDialogStore.prototype.setIsSakuhinValid = function (v) { this.isSakuhinValid = v; };
    MultiChangeDialogStore.prototype.resetSakuhinSuggestions = function () { this.sakuhinSuggestions = []; };
    MultiChangeDialogStore.prototype.setTantoInput = function (v) { this.tantoInput = v; };
    MultiChangeDialogStore.prototype.setTantoSuggestions = function (v) { this.tantoSuggestions = v; };
    MultiChangeDialogStore.prototype.setIsTantoValid = function (v) { this.isTantoValid = v; };
    MultiChangeDialogStore.prototype.resetTantoSuggestions = function () { this.tantoSuggestions = []; };
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "shows", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "sozaiDetail", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "tokuiInput", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "tokuiSuggestions", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "isTokuiValid", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "kyuInput", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "kyuSuggestions", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "isKyuValid", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "sakuhinInput", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "sakuhinSuggestions", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "isSakuhinValid", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "tantoInput", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "tantoSuggestions", void 0);
    __decorate([
        observable
    ], MultiChangeDialogStore.prototype, "isTantoValid", void 0);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "changeShowsTrue", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setShowsFalse", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setSozaiDetail", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setTokuiInput", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setTokuiSuggestions", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setIsTokuiValid", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "resetTokuiSuggestions", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setKyuInput", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setKyuSuggestions", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setIsKyuValid", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "resetKyuSuggestions", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setSakuhinInput", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setSakuhinSuggestions", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setIsSakuhinValid", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "resetSakuhinSuggestions", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setTantoInput", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setTantoSuggestions", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "setIsTantoValid", null);
    __decorate([
        action.bound
    ], MultiChangeDialogStore.prototype, "resetTantoSuggestions", null);
    return MultiChangeDialogStore;
}());
export { MultiChangeDialogStore };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import { createNullSakuhinDetail } from "../types/SakuhinDetail";
var SakuhinEditScreenStore = /** @class */ (function () {
    function SakuhinEditScreenStore() {
        this.sakuhinDetail = createNullSakuhinDetail();
    }
    SakuhinEditScreenStore.prototype.setSakuhinDetail = function (v) {
        this.sakuhinDetail = v;
    };
    SakuhinEditScreenStore.prototype.resetSakuhinDetail = function () {
        this.sakuhinDetail = createNullSakuhinDetail();
    };
    __decorate([
        observable
    ], SakuhinEditScreenStore.prototype, "sakuhinDetail", void 0);
    __decorate([
        action.bound
    ], SakuhinEditScreenStore.prototype, "setSakuhinDetail", null);
    __decorate([
        action
    ], SakuhinEditScreenStore.prototype, "resetSakuhinDetail", null);
    return SakuhinEditScreenStore;
}());
export { SakuhinEditScreenStore };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
export function createNullPasswordChangeInputData() {
    return {
        username: "",
        currentpassword: "",
        newpassword: "",
        confirmpassword: ""
    };
}
var PasswordEditScreenStore = /** @class */ (function () {
    function PasswordEditScreenStore() {
        this.passwordChangeInputData = createNullPasswordChangeInputData();
    }
    PasswordEditScreenStore.prototype.setPasswordChangeInputData = function (v) { this.passwordChangeInputData = v; };
    PasswordEditScreenStore.prototype.erasePasswordChangeInputData = function () { this.passwordChangeInputData = createNullPasswordChangeInputData(); };
    __decorate([
        observable
    ], PasswordEditScreenStore.prototype, "passwordChangeInputData", void 0);
    __decorate([
        action.bound
    ], PasswordEditScreenStore.prototype, "setPasswordChangeInputData", null);
    __decorate([
        action.bound
    ], PasswordEditScreenStore.prototype, "erasePasswordChangeInputData", null);
    return PasswordEditScreenStore;
}());
export { PasswordEditScreenStore };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import { Delete, Settings, Search, X, ChevronDown, ChevronUp } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
import { LabeledSelect2 } from "../common/LabeledSelect2";
import { Navbar } from "../common/Navbar";
import * as styles from "./SozaiKensakuScreen.module.scss";
var RefTable = React.forwardRef(function (props, ref) {
    return React.createElement("table", __assign({ ref: ref }, props));
});
// TODO 他のコンポーネントで observer つけてないやつがいる気がするのをチェック・修正
// 顧客要望「25文字/13文字で折り返して欲しい」は直接実現できなさそうなので、適当な幅決め打ち。
var widthFor25Chars = 400;
var widthFor13Chars = 200;
// キー名は ItemCode: ItemCode が変化するならここは追従せねばならないことに注意。
var styleTable = {
    "TokuiName": { minWidth: widthFor13Chars },
    "KyuName": { minWidth: widthFor13Chars },
    "SakuhinName": { minWidth: widthFor13Chars },
    "Daimei": { minWidth: widthFor25Chars },
    "Fukudai": { minWidth: widthFor25Chars },
    "KeijoName": { minWidth: widthFor13Chars },
    "MediaNaiyo": { minWidth: widthFor13Chars },
    "ProdDate": { minWidth: widthFor13Chars },
    "TRokiJiko": { minWidth: widthFor13Chars },
    "HensakiName": { minWidth: widthFor13Chars }
};
export var SozaiKensakuScreen = observer(function (props) {
    var isLoading = props.isLoading, tableRef = props.tableRef, acd = props.acd, sozaiNewAcd = props.sozaiNewAcd, sozaiHistoryAcd = props.sozaiHistoryAcd, locationChangeAcd = props.locationChangeAcd, nohinAcd = props.nohinAcd, discardAcd = props.discardAcd, meigiChangeAcd = props.meigiChangeAcd, kensakuJokens = props.kensakuJokens, startNo = props.startNo, nextData = props.nextData, showsHitCount = props.showsHitCount, hitCount = props.hitCount, searchResults = props.searchResults, checksAll = props.checksAll, hasCheckedItem = props.hasCheckedItem, isInitial = props.isInitial, sortColumnIndex = props.sortColumnIndex, sortOrder = props.sortOrder, onModifyJoken = props.onModifyJoken, onSearch = props.onSearch, onSelect = props.onSelect, onClickSort = props.onClickSort, onCheckAll = props.onCheckAll, onCheck = props.onCheck, onClickMultiEdit = props.onClickMultiEdit, onClickMultiLocationChange = props.onClickMultiLocationChange, onClickMultiNohin = props.onClickMultiNohin, onClickMultiDiscard = props.onClickMultiDiscard, onClickMultiMeigiChange = props.onClickMultiMeigiChange, onSearchSetting = props.onSearchSetting, onClear = props.onClear, onPrev = props.onPrev, onNext = props.onNext, onEnd = props.onEnd, onDownload = props.onDownload, onNewCopy = props.onNewCopy, onShowHistory = props.onShowHistory;
    // TODO 履歴へのリンクを追加後、それを無効化するのに使う
    void sozaiHistoryAcd;
    if (!acd.view)
        return null;
    return (React.createElement("div", { className: "d-flex flex-column h-100" },
        React.createElement(Navbar, { name: "\u691C\u7D22" }),
        React.createElement("div", { className: "container-fuild " },
            React.createElement("div", { className: "d-flex justify-content-end align-items-center mb-2" },
                isLoading &&
                    React.createElement("div", { className: "spinner-border spinner-border-sm mr-2", role: "status" },
                        React.createElement("span", { className: "sr-only" }, "Loading...")),
                searchResults == null ? (React.createElement(React.Fragment, null,
                    React.createElement(IconButton, { iconComponent: Search, label: "\u691C\u7D22", onClickButton: onSearch, disabled: isLoading }),
                    React.createElement(IconButton, { iconComponent: Settings, label: "\u8868\u793A\u8A2D\u5B9A", onClickButton: onSearchSetting, disabled: isLoading }))) : null,
                searchResults != null ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "dropdown d-inline mr-3" },
                        React.createElement("button", { className: "btn btn-sm btn-primary dropdown-toggle", type: "button", id: "multiEditDropDown", "data-toggle": "dropdown", "aria-haspopup": "true", "aria-expanded": "false", disabled: isLoading || !hasCheckedItem }, "\u4E00\u62EC\u64CD\u4F5C"),
                        showsHitCount ? React.createElement("span", { className: "ml-3" },
                            "\u4EF6\u6570: ",
                            hitCount) : null,
                        React.createElement("div", { className: "dropdown-menu", "aria-labelledby": "multiEditDropDown" },
                            React.createElement("button", { className: "dropdown-item", type: "button", onClick: onClickMultiEdit, disabled: isLoading || !sozaiNewAcd.c }, "\u4E00\u62EC\u7DE8\u96C6"),
                            React.createElement("button", { className: "dropdown-item", type: "button", onClick: onDownload }, "CSV\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9"),
                            React.createElement("button", { className: "dropdown-item", type: "button", onClick: onClickMultiLocationChange, disabled: isLoading || !locationChangeAcd.c }, "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u5909\u66F4"),
                            React.createElement("button", { className: "dropdown-item", type: "button", onClick: onClickMultiNohin, disabled: isLoading || !nohinAcd.c }, "\u7D0D\u54C1"),
                            React.createElement("button", { className: "dropdown-item", type: "button", onClick: onClickMultiDiscard, disabled: isLoading || !discardAcd.c }, "\u5EC3\u68C4"),
                            React.createElement("button", { className: "dropdown-item", type: "button", onClick: onClickMultiMeigiChange, disabled: isLoading || !meigiChangeAcd.c }, "\u540D\u7FA9\u5909\u66F4"))),
                    React.createElement("div", { className: "btn-group mr-3", role: "group" },
                        React.createElement("button", { type: "button", className: "btn btn-sm btn-primary", disabled: isLoading || startNo === 0, onClick: onPrev }, "\u00AB \u524D"),
                        React.createElement("button", { type: "button", className: "btn btn-sm btn-primary", disabled: isLoading || nextData !== "1", onClick: onNext }, "\u6B21 \u00BB")),
                    React.createElement(IconButton, { iconComponent: Search, label: "\u691C\u7D22", onClickButton: onSearch, disabled: isLoading }),
                    React.createElement(IconButton, { iconComponent: Delete, label: "\u691C\u7D22\u30AF\u30EA\u30A2", onClickButton: onClear, disabled: isLoading }))) : null,
                React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd }))),
        React.createElement(RefTable, { ref: tableRef, className: "table table-bordered table-striped table-hover table-sm text-break " + ((isInitial || hitCount) ? "flex-grow-1 " : "") + styles["sozai-table"] },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: styles["thin"] },
                        React.createElement("input", { type: "checkbox", checked: checksAll, onChange: onCheckAll })),
                    React.createElement("th", { className: styles["thin"] }),
                    kensakuJokens.map(function (joken, i) {
                        var _a;
                        var label = React.createElement(React.Fragment, null,
                            joken.ItemDisp,
                            (i !== sortColumnIndex) ?
                                React.createElement("span", { className: styles["sort-button"] + " rounded ml-1", onClick: function () { return onClickSort(i); } },
                                    React.createElement(ChevronDown, { size: 16 })) :
                                React.createElement("span", { className: styles["sort-button"] + " rounded ml-1 btn-secondary", onClick: function () { return onClickSort(i); } }, (sortOrder === "desc") ?
                                    React.createElement(ChevronUp, { size: 16 }) :
                                    React.createElement(ChevronDown, { size: 16 })));
                        switch (joken.FilterKind) {
                            case "select":
                                return (React.createElement("th", { scope: "col", key: joken.ItemCode },
                                    React.createElement(LabeledSelect2, { label: label, options: joken.FilterCanList, selectedOption: (_a = joken.FilterValue) !== null && _a !== void 0 ? _a : joken.FilterCanList[0], onModify: function (v) { return onModifyJoken(i, v); } })));
                                break;
                            case "text":
                            default:
                                return (React.createElement("th", { scope: "col", key: joken.ItemCode },
                                    React.createElement(LabeledInputText, { label: label, value: joken.FilterValue, dataCy: joken.ItemCode, onModify: function (v) { return onModifyJoken(i, v); } })));
                                break;
                        }
                    }))),
            React.createElement("tbody", null, searchResults != null
                ? searchResults.map(function (line, lineIndex) {
                    var onSel = function () { return onSelect(lineIndex); };
                    return (React.createElement("tr", { key: line.LineCNT },
                        React.createElement("td", { scope: "col", className: styles["thin"] },
                            React.createElement("input", { type: "checkbox", checked: line.isChecked, onChange: function () { return onCheck(lineIndex); } })),
                        React.createElement("td", { scope: "col", className: styles["thin"] },
                            line.LineCNT,
                            React.createElement("span", { className: styles["sort-button"] + " rounded ml-1", "data-toggle": "dropdown" },
                                React.createElement(ChevronDown, { size: 16 })),
                            React.createElement("div", { className: "dropdown-menu", "aria-labelledby": "multiEditDropDown" },
                                React.createElement("button", { className: "dropdown-item", type: "button", onClick: onSel }, "\u7DE8\u96C6"),
                                React.createElement("button", { className: "dropdown-item", type: "button", onClick: function () { return onShowHistory(lineIndex); } }, "\u5C65\u6B74"),
                                React.createElement("button", { className: "dropdown-item", type: "button", onClick: function () { return onNewCopy(lineIndex); } }, "\u30B3\u30D4\u30FC\u3057\u3066\u65B0\u898F\u767B\u9332"))),
                        line.LineBody.map(function (body, i) { return (React.createElement("td", { scope: "col", style: styleTable[kensakuJokens[i].ItemCode], key: line.LineCNT + "/" + i + "/" + body, onDoubleClick: onSel }, body)); })));
                })
                : null)),
        React.createElement("div", { className: "text-center mt-3", "data-cy": "existsResult" }, (!isInitial && (searchResults == null || searchResults.length === 0)) ? "該当データがありません" : "")));
});

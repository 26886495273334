import * as React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
export var HomeScreen = observer(function HomeScreen(props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "container" }, props.menuEntries.map(function (e) {
            return React.createElement("div", { className: "row", key: e.MenuApp },
                React.createElement("div", { className: "col-3" }),
                React.createElement("div", { className: "col-6" },
                    React.createElement(Link, { className: "btn btn-primary btn-block m-1", to: "/".concat(e.MenuApp) }, e.MenuTitle)),
                React.createElement("div", { className: "col-3" }));
        }))));
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import { Navbar } from "../common/Navbar";
import { SozaiEditScreen } from "../sozai/SozaiEditScreen";
import { WarnDialog } from "../common/WarnDialog";
import * as api from "../../api/apiClient";
import { trim } from "../../api/apiCommons";
import { makeTokuiSuggestionProps } from "./completeUtil/makeTokuiSuggestionProps";
import { makeKyuSuggestionProps } from "./completeUtil/makeKyuSuggestionProps";
import { makeSakuhinSuggestionProps } from "./completeUtil/makeSakuhinSuggestionProps";
import { makeKeijoSuggestionProps } from "./completeUtil/makeKeijoSuggestionProps";
import { makeMediaSuggestionProps } from "./completeUtil/makeMediaSuggestionProps";
import { makeTantoSuggestionProps } from "./completeUtil/makeTantoSuggestionProps";
import { makeLocationSuggestionProps } from "./completeUtil/makeLocationSuggestionProps";
import { makeTanabanSuggestionProps } from "./completeUtil/makeTanabanSuggestionProps";
// TODO 置き場検討。
// 真面目に一般化するなら、プロパティ名・その UI での表示名・不正な理由 (enum) を定義してその組を返すことになる。
// 多言語対応とか必要になった時には要検討。
function sanitizeSozaiDetail(SozaiDetail) {
    return __assign(__assign({}, SozaiDetail), { INo: trim(SozaiDetail.INo), TokuiCode: trim(SozaiDetail.TokuiCode), KyuCode: trim(SozaiDetail.KyuCode), SakuhinCode: trim(SozaiDetail.SakuhinCode), KeijoCode: trim(SozaiDetail.KeijoCode), MediaCode: trim(SozaiDetail.MediaCode), HensakiCode: trim(SozaiDetail.HensakiCode), TantoCode: trim(SozaiDetail.TantoCode), LocationCode: trim(SozaiDetail.LocationCode), TanaCode: trim(SozaiDetail.TanaCode) });
}
// 多言語対応を考える場合、stringは返さない
function validateSozaiDetail(store) {
    var msgs = [];
    if (!store.sozaiDetail.INo)
        msgs.push("IMAGICA番号(未入力)");
    if ((store.tokuiInput !== store.sozaiDetail.TokuiName) || !store.isTokuiValid) {
        msgs.push("得意先名");
    }
    if ((store.kyuInput !== store.sozaiDetail.KyuName) || (store.sozaiDetail.KyuName !== "" && !store.isKyuValid))
        msgs.push("旧得意先名");
    if ((store.sakuhinInput !== store.sozaiDetail.SakuhinName) || (store.sozaiDetail.SakuhinName !== "" && !store.isSakuhinValid))
        msgs.push("題名グループ");
    if (!store.sozaiDetail.Daimei)
        msgs.push("題名(未入力)");
    if (store.keijoInput !== store.sozaiDetail.KeijoName || !store.isKeijoValid)
        msgs.push("形状");
    if (store.mediaInput !== store.sozaiDetail.MediaNaiyo || !store.isMediaValid)
        msgs.push("メディア内容");
    if (store.tantoInput !== store.sozaiDetail.TantoName || (store.sozaiDetail.TantoName !== "" && !store.isTantoValid))
        msgs.push("担当者");
    if (store.locationInput !== store.sozaiDetail.LocationName || !store.isLocationValid)
        msgs.push("ロケーション名");
    if (store.tanabanInput !== store.sozaiDetail.Tanaban || !store.isTanabanValid) {
        // LocationName があるなら、棚番は妥当な入力でなければならない (Tanaban は空文字列でも妥当でありうることに注意)
        msgs.push("棚番号");
    }
    if (msgs.length > 0) {
        return msgs;
    }
    return null;
}
export var SozaiEditScreenRoot = observer(function SozaiEditScreenRoot() {
    var _this = this;
    var store = React.useContext(StoreContext);
    var history = useHistory();
    var location = useLocation();
    // /sozai/:INo, /sozai-new があるが前者にしか INo はない
    var params = useParams();
    var INo = params.INo && decodeURIComponent(params.INo);
    var screenStore = store.sozaiEditScreenStore;
    var kensakuScreenStorePublicState = store.sozaiKensakuScreen.publicState;
    var isEditMode = !!INo;
    var acd = store.userStore.appAuthTable["sozai-new"];
    React.useEffect(function () {
        if (!acd.view) {
            alert("画面を表示する権限がありません");
            history.go(-1);
            return;
        }
        screenStore.resetAll();
        store.setIsLoading(true);
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var sozaiDetail, query, baseINo, sozaiDetail;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isEditMode) return [3 /*break*/, 2];
                        return [4 /*yield*/, api.getSozai({ INo: INo })];
                    case 1:
                        sozaiDetail = _a.sent();
                        screenStore.changeSozaiDetailAndInputs(sozaiDetail);
                        return [3 /*break*/, 4];
                    case 2:
                        query = new URLSearchParams(location.search);
                        baseINo = query.get("from");
                        if (!baseINo) return [3 /*break*/, 4];
                        return [4 /*yield*/, api.getSozai({ INo: baseINo })];
                    case 3:
                        sozaiDetail = _a.sent();
                        screenStore.changeSozaiDetailAndInputs(__assign(__assign({}, sozaiDetail), { INo: "" }));
                        _a.label = 4;
                    case 4:
                        store.setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [INo, location, isEditMode, acd]);
    var handleRegister = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var messages, modifySozai, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    messages = validateSozaiDetail(screenStore);
                    if (messages) {
                        screenStore.setInvalidItems(messages);
                        return [2 /*return*/];
                    }
                    screenStore.sozaiDetail = sanitizeSozaiDetail(screenStore.sozaiDetail);
                    modifySozai = isEditMode ? api.changeSozai : api.addSozai;
                    return [4 /*yield*/, modifySozai(__assign(__assign({}, screenStore.sozaiDetail), { OpeCode: store.userStore.opeInfo.OpeCode, OpeName: store.userStore.opeInfo.OpeName }))];
                case 1:
                    res = _a.sent();
                    if (res.RtnMessage) {
                        if (res.RtnCode === "OK") {
                            alert(res.RtnMessage);
                        }
                        else {
                            screenStore.setWarnDialogMessage(res.RtnMessage);
                        }
                    }
                    if (!isEditMode && res.RtnCode === "OK") {
                        screenStore.resetAll();
                    }
                    if (res.RtnCode === "OK")
                        kensakuScreenStorePublicState.setIsDirtyTrue();
                    return [2 /*return*/];
            }
        });
    }); }, [isEditMode, screenStore, kensakuScreenStorePublicState]);
    var handleCopyNew = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var result, params;
        return __generator(this, function (_a) {
            result = window.confirm("\u7D20\u6750 ".concat(INo, " \u306E\u5185\u5BB9\u3092\u30B3\u30D4\u30FC\u3057\u3066\u65B0\u898F\u767B\u9332\u753B\u9762\u3092\u958B\u304D\u307E\u3059\u3002\u4FDD\u5B58\u3057\u3066\u3044\u306A\u3044\u5909\u66F4\u306F\u5931\u308F\u308C\u307E\u3059\u3002\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F"));
            if (!result)
                return [2 /*return*/];
            params = new URLSearchParams();
            params.append("from", screenStore.sozaiDetail.INo);
            history.push("/sozai-new?".concat(params.toString()));
            return [2 /*return*/];
        });
    }); }, [isEditMode, screenStore.sozaiDetail]);
    var handleSerialRegister = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var messages, modifySozai, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    messages = validateSozaiDetail(screenStore);
                    if (messages) {
                        screenStore.setInvalidItems(messages);
                        return [2 /*return*/];
                    }
                    screenStore.sozaiDetail = sanitizeSozaiDetail(screenStore.sozaiDetail);
                    modifySozai = isEditMode ? api.changeSozai : api.addSozai;
                    return [4 /*yield*/, modifySozai(__assign(__assign({}, screenStore.sozaiDetail), { OpeCode: store.userStore.opeInfo.OpeCode, OpeName: store.userStore.opeInfo.OpeName }))];
                case 1:
                    res = _a.sent();
                    if (res.RtnMessage) {
                        if (res.RtnCode === "OK") {
                            alert(res.RtnMessage);
                        }
                        else {
                            screenStore.setWarnDialogMessage(res.RtnMessage);
                        }
                    }
                    screenStore.setSozaiDetail(__assign(__assign({}, screenStore.sozaiDetail), { INo: "" }));
                    if (res.RtnCode === "OK")
                        kensakuScreenStorePublicState.setIsDirtyTrue();
                    return [2 /*return*/];
            }
        });
    }); }, [isEditMode, screenStore, kensakuScreenStorePublicState]);
    var handleDelete = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    result = window.confirm(INo + "を削除します。本当によろしいですか？");
                    if (!result)
                        return [2 /*return*/];
                    return [4 /*yield*/, api.deleteSozai(__assign(__assign({}, screenStore.sozaiDetail), { OpeCode: store.userStore.opeInfo.OpeCode, OpeName: store.userStore.opeInfo.OpeName }))];
                case 1:
                    _a.sent();
                    kensakuScreenStorePublicState.setIsDirtyTrue();
                    history.go(-1);
                    return [2 /*return*/];
            }
        });
    }); }, [INo, screenStore.sozaiDetail]);
    var handleEnd = React.useCallback(function () { return history.go(-1); }, [history]);
    var handleModifySozaiDetail = React.useCallback(function (sozaiDetail) { return screenStore.setSozaiDetail(sozaiDetail); }, [store]);
    var dialog = React.createElement(WarnDialog, { title: screenStore.invalidItems.length ? "入力欄に誤りがあります。" : "", onClose: screenStore.resetWarnDialogMessage }, screenStore.invalidItems.length ?
        screenStore.invalidItems.map(function (item) {
            return React.createElement("div", null,
                React.createElement("code", null, item));
        }) :
        React.createElement("div", null,
            React.createElement("code", null, screenStore.warnDialogMessage)));
    return (React.createElement(React.Fragment, null,
        React.createElement(Navbar, { name: (INo != null) ? "素材編集" : "新規登録" }),
        React.createElement(SozaiEditScreen, { acd: acd, isEditMode: INo != null, sozaiDetail: screenStore.sozaiDetail, prodDate: screenStore.prodDateInput, entryDate: screenStore.entryDateInput, updateDate: screenStore.updateDateInput, completePropsTokui: makeTokuiSuggestionProps(screenStore), completePropsKyu: makeKyuSuggestionProps(screenStore), completePropsSakuhin: makeSakuhinSuggestionProps(screenStore), completePropsKeijo: makeKeijoSuggestionProps(screenStore), completePropsMedia: makeMediaSuggestionProps(screenStore), completePropsTanto: makeTantoSuggestionProps(screenStore), completePropsLocation: makeLocationSuggestionProps(screenStore), completePropsTanaban: makeTanabanSuggestionProps(screenStore), onRegister: handleRegister, onCopyNew: handleCopyNew, onSerialRegister: handleSerialRegister, onDelete: handleDelete, onEnd: handleEnd, onModify: handleModifySozaiDetail, onModifyProdDate: screenStore.setProdDateInput }),
        screenStore.needsToShowWarnDialog ? dialog : null));
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
var RefSelect = React.forwardRef(function (props, ref) {
    return React.createElement("select", __assign({ ref: ref }, props));
});
export function LabeledSelect2(props) {
    var label = props.label, options = props.options, selectedOption = props.selectedOption, onModify = props.onModify, onBlur = props.onBlur, onEnter = props.onEnter, rawRef = props.rawRef, className = props.className;
    var handleChange = React.useCallback(function (event) {
        onModify(event.target.value);
    }, [onModify]);
    // NOTE さすがに無理があったので padding: 0 でお茶を濁すように変更。様子見。このコードも最悪復活するかもしれない。
    // // Ugh! 選択肢が見切れてつらいので暫定で最大文字数から幅を求めるひどいコード。
    // // 24 は bootstrap が select 要素につける横 padding 。30 は適当。
    // const minWidth =
    //   24 +
    //   Math.max.apply(
    //     Math,
    //     options.map(o => o.length)
    //   ) *
    //     30;
    // TODO なんだこれ？
    if (!onBlur || !onEnter || !rawRef) {
        return (React.createElement("div", { className: className },
            React.createElement("span", { className: "text-secondary" }, label),
            React.createElement("select", { className: "form-control form-control-sm", value: selectedOption || "", onChange: handleChange, style: { padding: 0 } }, options.map(function (v) { return (React.createElement("option", { key: v }, v)); }))));
    }
    var handleKeyPress = React.useCallback(function (event) {
        if (onEnter && event.key === "Enter") {
            event.preventDefault();
            onEnter();
        }
    }, [onEnter]);
    return (React.createElement("div", { className: className },
        React.createElement("span", { className: "text-secondary" }, label),
        React.createElement(RefSelect, { ref: rawRef, className: "form-control form-control-sm", value: selectedOption || "", onChange: handleChange, onBlur: onBlur, onKeyPress: handleKeyPress, style: { padding: 0 } }, options.map(function (v) { return (React.createElement("option", { key: v }, v)); }))));
}

import * as React from "react";
import { observer } from "mobx-react";
import { X, PenTool, CheckCircle, Edit2 } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledFixedText } from "../common/LabeledFixedText";
import { LabeledInputText } from "../common/LabeledInputText";
import { Navbar } from "../common/Navbar";
import * as styles from "./TanaoroshiTopScreen.module.scss"; // TODO 中身一緒なので共有している。独立させる
export var TanaoroshiScanScreen = observer(function (props) {
    var acd = props.acd, entries = props.entries, INoInput = props.INoInput, tanaLoction = props.tanaLoction, tanaban = props.tanaban, onChangeINoInput = props.onChangeINoInput, onAdd = props.onAdd, onCheck = props.onCheck, onFixLocation = props.onFixLocation, onSelectEntry = props.onSelectEntry, onEnd = props.onEnd;
    var handleAdd = React.useCallback(function () {
        if (!acd.c)
            return;
        onAdd();
    }, [onAdd]);
    if (!acd.view)
        return null;
    return (React.createElement("div", { className: "d-flex flex-column h-100" },
        React.createElement(Navbar, { name: "\u68DA\u5378" }),
        React.createElement("div", { className: "container-fuild " },
            React.createElement("div", { className: "d-flex flex-wrap-reverse justify-content-between mb-2" },
                React.createElement("div", { className: "flex-grow-1 flex-shrink-0 align-self-start d-flex align-items-end mx-2" },
                    React.createElement(LabeledInputText, { className: "mr-2", label: "IMAGICA番号", value: INoInput, onModify: onChangeINoInput, onEnter: handleAdd }),
                    React.createElement(IconButton, { iconComponent: PenTool, label: "\u8FFD\u52A0", onClickButton: handleAdd, disabled: !acd.c }),
                    React.createElement("div", { className: "ml-1 mr-5 pb-1" },
                        React.createElement("span", { className: styles["rem09"] }, "\u70B9\u6570:"),
                        React.createElement("span", { className: "p-1 ml-1 rounded font-weight-bold text-light bg-primary " + styles["rem105"] }, entries.length)),
                    React.createElement(LabeledFixedText, { className: "mr-2 mt-1", label: "ロケーション", value: tanaLoction }),
                    React.createElement(LabeledFixedText, { className: "mr-2 mt-1", label: "棚番", value: tanaban })),
                React.createElement("div", { className: "flex-grow-1 flex-shrink-0 align-self-end ml-2 text-right text-nowrap" },
                    React.createElement(IconButton, { btnClassName: "btn-primary", iconComponent: CheckCircle, label: "\u7167\u4F1A", onClickButton: onCheck }),
                    React.createElement(IconButton, { btnClassName: "btn-primary", iconComponent: Edit2, label: "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u4FEE\u6B63", onClickButton: onFixLocation }),
                    React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })))),
        React.createElement("div", { className: "flex-grow-1", style: { overflowX: "scroll" } },
            React.createElement("table", { className: "table table-bordered table-striped table-hover table-sm " + styles["sozai-table"] },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "IMAGICA\u756A\u53F7"),
                        React.createElement("th", null, "\u5F97\u610F\u5148"),
                        React.createElement("th", null, "\u984C\u540D"),
                        React.createElement("th", null, "\u526F\u984C"),
                        React.createElement("th", null, "\u5F62\u72B6"),
                        React.createElement("th", null, "\u62C5\u5F53\u8005"),
                        React.createElement("th", null, "\u7279\u8A18\u4E8B\u9805"),
                        React.createElement("th", null, "\u4ED6\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3"))),
                React.createElement("tbody", null, entries != null
                    ? entries.map(function (entry, lineIndex) {
                        return (React.createElement("tr", { key: entry.INo, className: entry.Status === "R" ? "text-danger" :
                                entry.Status === "G" ? "text-success" : "", onDoubleClick: function () { return onSelectEntry(lineIndex); } },
                            React.createElement("td", { scope: "col" }, entry.INo),
                            React.createElement("td", { scope: "col" }, entry.TokuiName),
                            React.createElement("td", { scope: "col" }, entry.Daimei),
                            React.createElement("td", { scope: "col" }, entry.Fukudai),
                            React.createElement("td", { scope: "col" }, entry.KeijoName),
                            React.createElement("td", { scope: "col" }, entry.Tanto),
                            React.createElement("td", { scope: "col" }, entry.TokiJiko),
                            React.createElement("td", { scope: "col" }, entry.Alocation)));
                    })
                    : null)))));
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import { AlertCircle, Edit, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
var spacerStyle = {
    height: 100
};
export var ParameterEditScreen = observer(function (props) {
    var acd = props.acd, parameter = props.parameter, onModify = props.onModify, onRegister = props.onRegister, onEnd = props.onEnd;
    var handleModifyParaStatus = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaStatus: v })); }, [onModify, parameter]);
    var handleModifyParaZaikoUmu = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaZaikoUmu: v })); }, [onModify, parameter]);
    var handleModifyParaSokoKBN = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaSokoKBN: v })); }, [onModify, parameter]);
    var handleModifyParaEvent = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaEvent: v })); }, [onModify, parameter]);
    var handleModifyParaAlertMail = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaAlertMail: v })); }, [onModify, parameter]);
    var handleModifyParaAlertSMTP = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaAlertSMTP: v })); }, [onModify, parameter]);
    var handleModifyParaAlertJob = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaAlertJob: v })); }, [onModify, parameter]);
    var handleModifyParaEncriptKey = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaEncriptKey: v })); }, [onModify, parameter]);
    var handleModifyParaJobStart = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaJobStart: v })); }, [onModify, parameter]);
    var handleModifyParaJobEnd = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaJobEnd: v })); }, [onModify, parameter]);
    var handleModifyParaSoundOK = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaSoundOK: v })); }, [onModify, parameter]);
    var handleModifyParaSoundNG = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaSoundNG: v })); }, [onModify, parameter]);
    var handleModifyParaSoundWG = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaSoundWG: v })); }, [onModify, parameter]);
    var handleModifyParaPwDays = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaPwDays: v })); }, [onModify, parameter]);
    var handleModifyParaPwChange = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaPwChange: v })); }, [onModify, parameter]);
    var handleModifyParaPwHistory = React.useCallback(function (v) { return onModify(__assign(__assign({}, parameter), { ParaPwHistory: v })); }, [onModify, parameter]);
    if (!acd.view)
        return null;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-end mb-2" },
            React.createElement(IconButton, { btnClassName: "btn-danger", iconComponent: Edit, label: "\u767B\u9332", onClickButton: onRegister, disabled: !acd.c }),
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "mb-4 p-4 border rounded bg-danger text-white" },
                React.createElement("h4", null,
                    React.createElement(AlertCircle, null),
                    " \u5909\u66F4\u306B\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002"),
                "\u3053\u306E\u30D1\u30E9\u30E1\u30FC\u30BF\u5024\u306F\u30D7\u30ED\u30B0\u30E9\u30E0\u3068\u5BC6\u63A5\u306B\u95A2\u9023\u3057\u3066\u3044\u307E\u3059\u3002"),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u30B9\u30C6\u30FC\u30BF\u30B9\u306E\u30B3\u30F3\u30DE\u533A\u5207\u308A\u30EA\u30B9\u30C8", value: parameter.ParaStatus, onModify: handleModifyParaStatus }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u5728\u5EAB\u6709\u7121\u306E\u30B3\u30F3\u30DE\u533A\u5207\u308A\u30EA\u30B9\u30C8", value: parameter.ParaZaikoUmu, onModify: handleModifyParaZaikoUmu }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u5009\u5EAB\u533A\u5206\u306E\u30B3\u30F3\u30DE\u533A\u5207\u308A\u30EA\u30B9\u30C8", value: parameter.ParaSokoKBN, onModify: handleModifyParaSokoKBN }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u30A4\u30D9\u30F3\u30C8\u7A2E\u5225\u306E\u30B3\u30F3\u30DE\u533A\u5207\u308A\u30EA\u30B9\u30C8", value: parameter.ParaEvent, onModify: handleModifyParaEvent }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u30D1\u30B9\u30EF\u30FC\u30C9\u6697\u53F7\u5316\u30AD\u30FC", value: parameter.ParaEncriptKey, onModify: handleModifyParaEncriptKey }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u30A2\u30E9\u30FC\u30C8\u9001\u4FE1\u5148\u306E\u30B3\u30F3\u30DE\u533A\u5207\u308A\u30EA\u30B9\u30C8", value: parameter.ParaAlertMail, onModify: handleModifyParaAlertMail }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u30A2\u30E9\u30FC\u30C8\u30E1\u30FC\u30EB\u306ESMTP\u30B5\u30FC\u30D0\u3001\u30A2\u30AB\u30A6\u30F3\u30C8\u3001\u30D1\u30B9\u30EF\u30FC\u30C9", value: parameter.ParaAlertSMTP, onModify: handleModifyParaAlertSMTP }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u30A2\u30E9\u30FC\u30C8JOB", value: parameter.ParaAlertJob, onModify: handleModifyParaAlertJob }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u65E5\u6B21\u51E6\u7406\u958B\u59CB\u6642\u523B\uFF08hh:mm:ss\uFF09", value: parameter.ParaJobStart, onModify: handleModifyParaJobStart }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u65E5\u6B21\u51E6\u7406\u5B8C\u4E86\u65E5\u6642", value: parameter.ParaJobEnd, onModify: handleModifyParaJobEnd }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: '"OK"\u97F3\u6E90URL', value: parameter.ParaSoundOK, onModify: handleModifyParaSoundOK }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: '"NG"\u97F3\u6E90URL', value: parameter.ParaSoundNG, onModify: handleModifyParaSoundNG }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: '"WG"(\u8B66\u544A)\u97F3\u6E90URL', value: parameter.ParaSoundWG, onModify: handleModifyParaSoundWG }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: '\u30D1\u30B9\u30EF\u30FC\u30C9\u6709\u52B9\u65E5\u6570', value: parameter.ParaPwDays, onModify: handleModifyParaPwDays }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: '\u30D1\u30B9\u30EF\u30FC\u30C9\u5909\u66F4\u5F37\u5236\uFF1A1', value: parameter.ParaPwChange, onModify: handleModifyParaPwChange }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: '\u540C\u4E00\u30D1\u30B9\u30EF\u30FC\u30C9\u4E0D\u53EF\u5C65\u6B74\u6570', value: parameter.ParaPwHistory, onModify: handleModifyParaPwHistory })))),
        React.createElement("div", { style: spacerStyle }));
});

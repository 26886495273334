import * as React from "react";
import { User } from "react-feather";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { StoreContext } from "../../context";
import { useLogout } from "./hooks/useLogout";
export function Navbar(props) {
    var store = React.useContext(StoreContext);
    var history = useHistory();
    var logout = useLogout();
    var handlePasswordChange = React.useCallback(function () {
        history.push("/password-change");
    }, [store, history]);
    return (React.createElement("nav", { className: "navbar navbar-expand-md navbar-dark bg-dark mb-2" },
        React.createElement(Helmet, null,
            React.createElement("title", null, props.name ? "".concat(props.name, " - LoCoMo\u5EAB") : "LoCoMo庫")),
        React.createElement("div", { className: "navbar-brand" }, !props.logoutOnly ?
            React.createElement(Link, { to: "/home" }, "LoCoMo\u5EAB") :
            "LoCoMo庫"),
        props.name ? props.name : null,
        React.createElement("button", { className: "navbar-toggler", type: "button", "data-toggle": "collapse", "data-target": "#navbarSupportedContent", "aria-controls": "navbarSupportedContent", "aria-expanded": "false", "aria-label": "Toggle navigation" },
            React.createElement("span", { className: "navbar-toggler-icon" })),
        React.createElement("div", { className: "collapse navbar-collapse", id: "navbarSupportedContent" },
            React.createElement("ul", { className: "navbar-nav mr-auto" }),
            React.createElement("ul", { className: "navbar-nav" },
                React.createElement("li", { className: "nav-item dropdown" },
                    React.createElement(Link, { className: "nav-link dropdown-toggle", to: "#", id: "navbarDropdown", role: "button", "data-toggle": "dropdown", "aria-haspopup": "true", "aria-expanded": "false", "data-cy": "navbarDropdown" },
                        React.createElement(User, { size: 20 })),
                    React.createElement("div", { className: "dropdown-menu dropdown-menu-right text-muted", "aria-labelledby": "navbarDropdown" },
                        React.createElement("div", { className: "px-4 py-2 text-nowrap" },
                            store.userStore.opeInfo.OpeName,
                            " (",
                            store.userStore.opeInfo.OpeCode,
                            ")"),
                        React.createElement("div", { className: "dropdown-divider" }),
                        (function () {
                            if (props.name === "パスワード変更") {
                                return null;
                            }
                            return React.createElement("button", { className: "dropdown-item", type: "button", onClick: handlePasswordChange }, "\u30D1\u30B9\u30EF\u30FC\u30C9\u5909\u66F4");
                        })(),
                        React.createElement("button", { className: "dropdown-item", type: "button", "data-cy": "logout", onClick: logout }, "\u30ED\u30B0\u30A2\u30A6\u30C8")))))));
}

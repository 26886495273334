var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import { Edit, Trash2, X, XOctagon } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
export var UserEditScreen = observer(function (props) {
    var acd = props.acd, isEditMode = props.isEditMode, user = props.user, onModify = props.onModify, onRegister = props.onRegister, onDelete = props.onDelete, onEnd = props.onEnd, onReset = props.onReset;
    var handleModifyUserCode = React.useCallback(function (v) { return onModify(__assign(__assign({}, user), { UserCode: v })); }, [onModify, user]);
    var handleModifyUserName = React.useCallback(function (v) { return onModify(__assign(__assign({}, user), { UserName: v })); }, [onModify, user]);
    var handleModifyBusyoName = React.useCallback(function (v) { return onModify(__assign(__assign({}, user), { BusyoName: v })); }, [onModify, user]);
    var handleModifyLocation = React.useCallback(function (v) { return onModify(__assign(__assign({}, user), { Location: v })); }, [onModify, user]);
    var handleModifyMailAddress = React.useCallback(function (v) { return onModify(__assign(__assign({}, user), { MailAddress: v })); }, [onModify, user]);
    var handleModifyUserRole = React.useCallback(function (v) { return onModify(__assign(__assign({}, user), { UserRole: v })); }, [onModify, user]);
    var handleModifyAuthApps = React.useCallback(function (v) { return onModify(__assign(__assign({}, user), { AuthApps: v })); }, [onModify, user]);
    var handleModifyAuthTypes = React.useCallback(function (v) { return onModify(__assign(__assign({}, user), { AuthTypes: v })); }, [onModify, user]);
    if (!acd.view)
        return null;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-end mb-2" },
            React.createElement(IconButton, { iconComponent: Edit, label: "\u767B\u9332", onClickButton: onRegister, disabled: isEditMode ? !acd.c : !acd.a }),
            isEditMode ?
                React.createElement(React.Fragment, null,
                    React.createElement(IconButton, { btnClassName: "btn-outline-danger", iconComponent: XOctagon, label: "\u30D1\u30B9\u30EF\u30FC\u30C9\u521D\u671F\u5316", onClickButton: onReset }),
                    React.createElement(IconButton, { btnClassName: "btn-outline-danger", iconComponent: Trash2, label: "\u524A\u9664", onClickButton: onDelete, disabled: !acd.d })) :
                null,
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-4" },
                    React.createElement(LabeledInputText, { label: "\u62C5\u5F53\u8005\u30B3\u30FC\u30C9", value: user.UserCode, onModify: handleModifyUserCode })),
                React.createElement("div", { className: "col-8" },
                    React.createElement(LabeledInputText, { label: "\u62C5\u5F53\u8005\u540D", value: user.UserName, onModify: handleModifyUserName }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u90E8\u7F72\u540D", value: user.BusyoName, onModify: handleModifyBusyoName })),
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u30C7\u30D5\u30A9\u30EB\u30C8\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3", value: user.Location, onModify: handleModifyLocation }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-8" },
                    React.createElement(LabeledInputText, { label: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9", value: user.MailAddress, onModify: handleModifyMailAddress })),
                React.createElement("div", { className: "col-4" },
                    React.createElement(LabeledInputText, { label: "\u30AF\u30E9\u30B9", value: user.UserRole, onModify: handleModifyUserRole }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u8A31\u53EF\u30A2\u30D7\u30EA\u306E\u30B3\u30F3\u30DE\u533A\u5207\u308A\u30EA\u30B9\u30C8", value: user.AuthApps, onModify: handleModifyAuthApps }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u30A2\u30D7\u30EA\u6A29\u9650\u306E\u30B3\u30F3\u30DE\u533A\u5207\u308A\u30EA\u30B9\u30C8", value: user.AuthTypes, onModify: handleModifyAuthTypes })))));
});

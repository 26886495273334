import * as React from "react";
import { observer } from "mobx-react";
import { Check } from "react-feather";
import AutoSuggest from "react-autosuggest";
import * as styles from "./CompletableInput.module.scss";
function doNothing() { }
function alwaysTrue() { return true; }
export var CompletableInput = observer(function CompletableInput(props) {
    var showsCheck = props.showsCheck, onSuggestionsFetchRequested = props.onSuggestionsFetchRequested, onModifyInputValue = props.onModifyInputValue, onSelected = props.onSelected, inputId = props.inputId;
    var renderInputComponent = React.useCallback(function (inputProps) {
        return React.createElement("div", { className: styles["rel"] },
            React.createElement("input", inputProps),
            React.createElement("div", { className: styles["rightbox"] + (showsCheck ? "" : " d-none") },
                React.createElement(Check, null)));
    }, [showsCheck]);
    var handleInputChange = React.useCallback(function (_ev, _a) {
        var newValue = _a.newValue;
        onModifyInputValue(newValue);
    }, [onModifyInputValue]);
    var handleKeyPress = React.useCallback(function (ev) {
        if (ev.key === "Enter") {
            // Enter でのみ補完を更新するため、 onSuggestionsFetchRequested は AutoSuggest に渡さずここで直接呼ぶことに注意
            onSuggestionsFetchRequested();
            ev.preventDefault();
        }
    }, [onSuggestionsFetchRequested]);
    var handleSelected = React.useCallback(function (_ev, _a) {
        var suggestion = _a.suggestion, suggestionIndex = _a.suggestionIndex;
        onSelected(suggestion, suggestionIndex);
    }, [onSelected]);
    return React.createElement(AutoSuggest, { suggestions: props.suggestions, shouldRenderSuggestions: alwaysTrue, renderSuggestion: props.renderSuggestion, renderInputComponent: renderInputComponent, getSuggestionValue: props.getSuggestionValue, onSuggestionsFetchRequested: doNothing, onSuggestionsClearRequested: props.onSuggestionsClearRequested, onSuggestionSelected: handleSelected, inputProps: {
            value: props.inputValue,
            id: inputId,
            onChange: handleInputChange,
            onKeyPress: handleKeyPress,
            onBlur: props.onBlur,
            className: "form-control form-control-sm",
            disabled: props.disabled
        } });
});

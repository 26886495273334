var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
// import { TanaMeisaiEntry } from "../types/TanaMeisaiEntry";
// import { APIResponseTanaMeisai } from "../api/tana";
var TanaoroshiScanScreenStore = /** @class */ (function () {
    function TanaoroshiScanScreenStore() {
        this.entries = [];
        this.INoInput = "";
        this.scanCount = 0;
        this.tanaLocation = null;
        this.tanaban = null;
        this.scanStatus = null;
    }
    TanaoroshiScanScreenStore.prototype.setEntries = function (v) { this.entries = v; };
    TanaoroshiScanScreenStore.prototype.resetEntries = function () { this.entries = []; };
    TanaoroshiScanScreenStore.prototype.setINoInput = function (v) { this.INoInput = v; };
    TanaoroshiScanScreenStore.prototype.setScanCount = function (v) { this.scanCount = v; };
    TanaoroshiScanScreenStore.prototype.setTanaLocation = function (v) { this.tanaLocation = v; };
    TanaoroshiScanScreenStore.prototype.setTanaban = function (v) { this.tanaban = v; };
    TanaoroshiScanScreenStore.prototype.setScanStatus = function (v) { this.scanStatus = v; };
    __decorate([
        observable
    ], TanaoroshiScanScreenStore.prototype, "entries", void 0);
    __decorate([
        observable
    ], TanaoroshiScanScreenStore.prototype, "INoInput", void 0);
    __decorate([
        observable
    ], TanaoroshiScanScreenStore.prototype, "scanCount", void 0);
    __decorate([
        observable
    ], TanaoroshiScanScreenStore.prototype, "tanaLocation", void 0);
    __decorate([
        observable
    ], TanaoroshiScanScreenStore.prototype, "tanaban", void 0);
    __decorate([
        observable
    ], TanaoroshiScanScreenStore.prototype, "scanStatus", void 0);
    __decorate([
        action.bound
    ], TanaoroshiScanScreenStore.prototype, "setEntries", null);
    __decorate([
        action.bound
    ], TanaoroshiScanScreenStore.prototype, "resetEntries", null);
    __decorate([
        action.bound
    ], TanaoroshiScanScreenStore.prototype, "setINoInput", null);
    __decorate([
        action.bound
    ], TanaoroshiScanScreenStore.prototype, "setScanCount", null);
    __decorate([
        action.bound
    ], TanaoroshiScanScreenStore.prototype, "setTanaLocation", null);
    __decorate([
        action.bound
    ], TanaoroshiScanScreenStore.prototype, "setTanaban", null);
    __decorate([
        action.bound
    ], TanaoroshiScanScreenStore.prototype, "setScanStatus", null);
    return TanaoroshiScanScreenStore;
}());
export { TanaoroshiScanScreenStore };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import { X, Edit3, Delete } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
import { SozaiTable } from "../common/SozaiTable";
import { CompletableInput } from "../common/CompletableInput";
export var MeigiChangeScreen = observer(function (props) {
    var acd = props.acd, INo = props.INo, nohinsyo = props.nohinsyo, 
    // tokuiCode,
    isSubmitted = props.isSubmitted, entries = props.entries, onSubmit = props.onSubmit, onClear = props.onClear, onEnd = props.onEnd, onChangeINo = props.onChangeINo, onChangeNohinsyo = props.onChangeNohinsyo, 
    // onChangeTokuiCode,
    onBlurINo = props.onBlurINo, onBlurNohinsyo = props.onBlurNohinsyo, onEnter = props.onEnter, onAddLine = props.onAddLine, onRemoveLine = props.onRemoveLine;
    // どれでも Enter されたら外側で入力チェックする
    var onEnterINo = onEnter;
    var onEnterNohinsyo = onEnter;
    if (!acd.view)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "container-fuild " },
            React.createElement("div", { className: "d-flex flex-wrap-reverse justify-content-between mb-2" },
                React.createElement("div", { className: "flex-grow-1 flex-shrink-1 justify-content-between align-self-start d-flex align-items-end" },
                    React.createElement("div", { className: "flex-grow-0 flex-shrink-1 d-flex justify-content-between align-items-end ml-2 mr-3" },
                        React.createElement(LabeledInputText, { className: "mr-1", label: "\uFF29\uFF2D\uFF21\uFF27\uFF29\uFF23\uFF21\u756A\u53F7", type: "text", value: INo, onModify: onChangeINo, onBlur: onBlurINo, onEnter: onEnterINo }),
                        React.createElement("button", { className: "btn btn-sm btn-primary text-nowrap mr-0", onClick: onAddLine }, "\u8FFD\u52A0")),
                    React.createElement("div", { className: "flex-grow-0 flex-shrink-1 d-flex align-items-start border border-secondary rounded p-2 mr-3" },
                        React.createElement(LabeledInputText, { className: "flex-shrink-1 mr-1", label: "\u540D\u7FA9\u5909\u66F4\u756A\u53F7", type: "text", value: nohinsyo, onModify: onChangeNohinsyo, onBlur: onBlurNohinsyo, onEnter: onEnterNohinsyo }),
                        React.createElement("div", { className: "flex-shrink-1 mr-1" },
                            React.createElement("span", { className: "text-secondary text-nowrap" }, "\u65B0\u5F97\u610F\u5148"),
                            React.createElement(CompletableInput, __assign({}, props.completePropsTokui))))),
                React.createElement("div", { className: "flex-grow-1 flex-shrink-0 align-self-end ml-2 mb-2 text-right text-nowrap" },
                    React.createElement("span", { className: "mr-3" },
                        "\u70B9\u6570: ",
                        entries ? entries.length : 0),
                    React.createElement(IconButton, { iconComponent: Edit3, label: "\u66F4\u65B0", onClickButton: onSubmit, disabled: !acd.c || !(entries && entries.length) }),
                    React.createElement(IconButton, { iconComponent: Delete, label: "\u30EA\u30BB\u30C3\u30C8", onClickButton: onClear, disabled: !(entries && entries.length) }),
                    React.createElement(IconButton, { btnClassName: "btn-outline-primary", className: "mr-2", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })))),
        React.createElement(SozaiTable, { entries: entries, onRemoveLine: onRemoveLine, isResultDisplay: isSubmitted })));
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { post, get, trim } from "./apiCommons";
/* TODO 削除。旧棚卸し
export async function tanaLocation(req: APIRequestTanaLocation): Promise<APIResponseTanaLocation> {
  const ret = (await get(`/tana-location/${encodeURIComponent(req.locationName || "*")}/${encodeURIComponent(req.tanaban || "^")}`)).data as APIResponseTanaLocation;

  // TODO サーバ側の修正後削除
  ret.DataList.forEach(e => { e.LocationCode = e.LocationCode.trimLeft(); });
  return ret;

  // const name = req.locationName;
  // const res = name === "*" ? dummyTanas : dummyTanas.filter(t => t.TanaLocation.indexOf(name) !== -1);
  // return Promise.resolve({
  //   RtnCode: "OK",
  //   RtnMessage: "",
  //   DataCNT: res.length,
  //   DataList: res
  // });
}

export async function tanaOroshi(req: APIRequestTanaOroshi): Promise<APIResponseTanaOroshi> {
  return (await post(`/tana-orosi/`, { ...req, DataCNT: req.LocationList.length })).data;
}

export async function tanaInit(req: APIRequestTanaInit): Promise<APIResponseTanaInit> {
  return (await post(`/tana-init/`, { ...req, DataCNT: req.LocationList.length })).data;
}

export async function tanaCSV(req: APIRequestTanaCSV): Promise<APIResponseTanaCSV> {
  return (await post(`/tana-csv/`, { ...req, DataCNT: req.LocationList.length })).data;
}

export async function tanaMeisai(req: APIRequestTanaMeisai): Promise<APIResponseTanaMeisai> {
  return (await get(`/tana-meisai/${encodeURIComponent(trim(req.LocationCode))}`)).data;

  // return Promise.resolve({
  //   RtnCode: "OK",
  //   RtnMessage: "",
  //   TanaDate: "202010XX",
  //   TanaLocation: "LOC-DOKOKA",
  //   DataCNT: dummyMeisaiTable[req.LocationCode].length,
  //   DataList: dummyMeisaiTable[req.LocationCode]
  // });
}

export async function tanaGenpin(req: APIRequestTanaGenpin): Promise<APIResponseTanaGenpin> {
  return (await post(`/tana-genpin/`, { ...req, DataCNT: req.INos.length })).data;
}
*/
export function tanaTop(req) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, get("/tana-top/".concat(encodeURIComponent(trim(req.locationName) || "*"), "/").concat(encodeURIComponent(trim(req.tanaban) || "^")))];
                case 1: return [2 /*return*/, (_a.sent()).data];
            }
        });
    });
}
export function tanaClear(req) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, post("/tana-clear/", __assign(__assign({}, req), { DataCNT: req.LocationList.length }))];
                case 1: return [2 /*return*/, (_a.sent()).data];
            }
        });
    });
}
export function tanaFumeiCSV(req) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, post("/tana-fumeicsv/", __assign(__assign({}, req), { DataCNT: req.LocationList.length }))];
                case 1: return [2 /*return*/, (_a.sent()).data];
            }
        });
    });
}
export function tanaScanList(req) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // TODO 削除。テスト用ダミー。
                    if (req.LocationCode === "LOC9999%9999") {
                        return [2 /*return*/, {
                                "RtnCode": "OK",
                                "RtnMessage": "",
                                "TanaLocation": "極めて長い名前のロケーション01テスト文字列01234567890",
                                "Tanaban": "非常に長い棚番名称テスト文字列ABCD EFG HIJK LMNOPQ",
                                "ScanStatus": "テスト処理",
                                "ScanCNT": 3,
                                "DataCNT": 3,
                                "DataList": [
                                    {
                                        "INo": "TESTINO0001",
                                        "TokuiName": "TokuiName0001",
                                        "Daimei": "Daimei0001",
                                        "Fukudai": "Fukudai0001",
                                        "KeijoName": "KeijoName0001",
                                        "Tanto": "Tanto0001",
                                        "TokiJiko": "",
                                        "Alocation": "",
                                        "Status": "W"
                                    },
                                    {
                                        "INo": "TESTINO0002",
                                        "TokuiName": "TokuiName0002",
                                        "Daimei": "Daimei0002",
                                        "Fukudai": "Fukudai0002",
                                        "KeijoName": "KeijoName0002",
                                        "Tanto": "Tanto0002",
                                        "TokiJiko": "",
                                        "Alocation": "",
                                        "Status": "R"
                                    },
                                    {
                                        "INo": "INO-feefoo00",
                                        "TokuiName": "どこか極めて長い名前の得意先名称テスト文字列01234567890",
                                        "Daimei": "常軌を逸して莫大な長さの何らかの題名文字列テストABCDE fghijkl mnopqurls tuvwx yz 011345567 fooo",
                                        "Fukudai": "副題文字列テスト",
                                        "KeijoName": "何らかの形状",
                                        "Tanto": "担当者名テスト文字列太郎",
                                        "TokiJiko": "特記事項なし",
                                        "Alocation": "",
                                        "Status": "G"
                                    }
                                ]
                            }];
                    }
                    return [4 /*yield*/, get("/tana-scanlist/".concat(encodeURIComponent(trim(req.LocationCode))))];
                case 1: 
                // 現行 encodeURIComponent() するとエラーになるので注意
                return [2 /*return*/, (_a.sent()).data];
            }
        });
    });
}
export function tanaScan(req) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, post("/tana-scan/", __assign(__assign({}, req), { DataCNT: req.INos.length }))];
                case 1: return [2 /*return*/, (_a.sent()).data];
            }
        });
    });
}
export function tanaCheck(req) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, post("/tana-check/", __assign({}, req))];
                case 1: return [2 /*return*/, (_a.sent()).data];
            }
        });
    });
}
export function tanaUpdate(req) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, post("/tana-update/", __assign({}, req))];
                case 1: return [2 /*return*/, (_a.sent()).data];
            }
        });
    });
}
/*
function randnum(d = 4) {
  return (Math.random() * Math.pow(10, d)) | 0;
}

function makeDummyTanaLoc(i: number) {
  return {
    TanaLocation: "N" + randnum(4),
    LocationCode: `LOC${i}-XXXX`,
    C1: randnum(2), // 現有在庫数
    TanaDate: "20201100",
    R1: randnum(2), // 帳簿在庫数
    M1: randnum(2), // 現品在庫数
    M0R1: randnum(2), // 現品なし帳簿あり
    M1R0: randnum(2), // 現品あり帳簿なし
    isChecked: false
  };
}

function makeDummyMeisai(loc: string, i: number) {
  return {
    MFLG: Math.random() >= 0.5 ? "◎" : "",
    XFLG: Math.random() >= 0.5 ? "◆" : "",
    Location: loc,
    INo: `INO-${randnum(8)}`,
    TokuiName: `Tokui-${randnum(4)}`,
    Daimei: `Dai-${randnum(4)}`,
    Fukudai: `Fuku-${randnum(4)}`,
    KeijoName: `Kj-${randnum(4)}`,
    TokiJiko: `Toki-${randnum(4)}`
  };
}

function makeDummyMeisais(loc: string, i: number) {
  return makeArray(30, x => x).map(i => makeDummyMeisai(loc, i));
}

function makeArray<T>(n: number, f: (i: number) => T): T[] {
  const ret = [];
  for (let i = 0; i < n; ++i)
    ret.push(f(i));
  return ret;
}

void get;

const dummyTanas = makeArray(30, makeDummyTanaLoc);
const dummyMeisaiTable = dummyTanas.map((t, i) => makeDummyMeisais(t.LocationCode, i)).reduce((acc, m) => ((acc[m[0]!.Location] = m), acc), {} as any);
*/

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import { Navbar } from "../common/Navbar";
import { SozaiHistoryScreen } from "../sozaiHistory/SozaiHistoryScreen";
import { WarnDialog } from "../common/WarnDialog";
import * as api from "../../api/apiClient";
export var SozaiHistoryScreenRoot = observer(function SozaiHistoryScreenRoot() {
    var _this = this;
    var store = React.useContext(StoreContext);
    var history = useHistory();
    var screenStore = store.sozaiHistoryScreenStore;
    var location = useLocation();
    var acd = store.userStore.appAuthTable["sozai-history"];
    //「何か検索した痕跡がある＝ entries が空でない」時のみ一覧を再更新する。
    React.useEffect(function () {
        if (!acd.view) {
            alert("画面を表示する権限がありません");
            history.go(-1);
            return;
        }
        var query = new URLSearchParams(location.search);
        var ino = query.get("ino");
        if (ino) {
            screenStore.setEntries([]);
            screenStore.resetInputs();
            screenStore.query.INo = ino;
            (function () { return __awaiter(_this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.listRekis(__assign({ OpeCode: store.userStore.opeInfo.OpeCode }, screenStore.query))];
                        case 1:
                            res = _a.sent();
                            if (res.RtnMessage) {
                                if (res.RtnCode === "OK") {
                                    alert(res.RtnMessage);
                                }
                                else {
                                    screenStore.setWarnDialogMessage(res.RtnMessage);
                                }
                            }
                            screenStore.setIsInitial(false);
                            screenStore.setEntries(res.DataList);
                            return [2 /*return*/];
                    }
                });
            }); })();
        }
        if (screenStore.entries.length) {
            screenStore.setEntries([]);
            (function () { return __awaiter(_this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.listRekis(__assign({ OpeCode: store.userStore.opeInfo.OpeCode }, screenStore.query))];
                        case 1:
                            res = _a.sent();
                            if (res.RtnMessage) {
                                if (res.RtnCode === "OK") {
                                    alert(res.RtnMessage);
                                }
                                else {
                                    screenStore.setWarnDialogMessage(res.RtnMessage);
                                }
                            }
                            screenStore.setIsInitial(false);
                            screenStore.setEntries(res.DataList);
                            return [2 /*return*/];
                    }
                });
            }); })();
        }
    }, [acd]);
    var handleEnd = React.useCallback(function () {
        screenStore.setEntries([]);
        screenStore.resetInputs();
        screenStore.setIsInitial(true);
        history.go(-1);
    }, [history]);
    var handleSearch = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.listRekis(__assign({ OpeCode: store.userStore.opeInfo.OpeCode }, screenStore.query))];
                case 1:
                    res = _a.sent();
                    if (res.RtnMessage) {
                        if (res.RtnCode === "OK") {
                            alert(res.RtnMessage);
                        }
                        else {
                            screenStore.setWarnDialogMessage(res.RtnMessage);
                        }
                    }
                    screenStore.setIsInitial(false);
                    screenStore.setEntries(res.DataList);
                    return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    var handleSelect = React.useCallback(function (idx) {
        if (!screenStore.entries || !screenStore.entries[idx]) {
            // この条件ではここに来ないはずだが念のため
            return;
        }
        history.push("/sozai/".concat(encodeURIComponent(screenStore.entries[idx].INo)));
    }, [screenStore, history]);
    var dialog = React.createElement(WarnDialog, { onClose: screenStore.resetWarnDialogMessage }, React.createElement("div", null,
        React.createElement("code", null, screenStore.warnDialogMessage)));
    return (React.createElement(React.Fragment, null,
        React.createElement(Navbar, { name: "\u5C65\u6B74\u4E00\u89A7" }),
        React.createElement(SozaiHistoryScreen, { acd: acd, query: screenStore.query, entries: screenStore.entries, beginDate: screenStore.beginDateInput, endDate: screenStore.endDateInput, isInitial: screenStore.isInitial, onSearch: handleSearch, onEnd: handleEnd, onChangeQuery: screenStore.setQuery, onChangeBeginDate: screenStore.changeBeginDateInput, onChangeEndDate: screenStore.changeEndDateInput, onSelectEntry: handleSelect }),
        screenStore.warnDialogMessage ? dialog : null));
});

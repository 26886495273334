var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import { createNullSozaiDetail } from "../types/SozaiDetail";
var MeigiChangeScreenStore = /** @class */ (function () {
    function MeigiChangeScreenStore() {
        // 直接には使っていないが、TokuiCompleteStoreLike の補完結果が .TokuiCode, .TokuIname に入る
        this.sozaiDetail = createNullSozaiDetail();
        this.INo = "";
        this.nohinsyo = "";
        this.entries = [];
        this.isSubmitted = false;
        this.isWarnDialogShown = false;
        this.warnDialogMessages = [];
        // implements TokuiCompleteStoreLike
        this.tokuiInput = "";
        this.tokuiSuggestions = [];
        this.isTokuiValid = false;
    }
    MeigiChangeScreenStore.prototype.setINo = function (v) { this.INo = v; };
    MeigiChangeScreenStore.prototype.setNohinsyo = function (v) { this.nohinsyo = v; };
    MeigiChangeScreenStore.prototype.setEntries = function (v) { this.entries = v; };
    MeigiChangeScreenStore.prototype.setIsSubmitted = function (v) { this.isSubmitted = v; };
    MeigiChangeScreenStore.prototype.setShowsWarnDialogTrue = function () { this.isWarnDialogShown = true; };
    MeigiChangeScreenStore.prototype.setShowsWarnDialogFalse = function () { this.isWarnDialogShown = false; };
    MeigiChangeScreenStore.prototype.setWarnMessage = function (v) { this.setWarnMessages([v]); };
    MeigiChangeScreenStore.prototype.setWarnMessages = function (v) { this.warnDialogMessages = v; };
    // implements TokuiCompleteStoreLike
    MeigiChangeScreenStore.prototype.setTokuiInput = function (v) { this.tokuiInput = v; };
    MeigiChangeScreenStore.prototype.setTokuiSuggestions = function (v) { this.tokuiSuggestions = v; };
    MeigiChangeScreenStore.prototype.setIsTokuiValid = function (v) { this.isTokuiValid = v; };
    MeigiChangeScreenStore.prototype.resetTokuiSuggestions = function () { this.tokuiSuggestions = []; };
    MeigiChangeScreenStore.prototype.removeEntry = function (idx) {
        this.entries.splice(idx, 1);
    };
    MeigiChangeScreenStore.prototype.addEntry = function (v) {
        this.entries.push(v);
    };
    MeigiChangeScreenStore.prototype.resetInputs = function () {
        this.INo = "";
        this.nohinsyo = "";
        this.setTokuiInput("");
        this.setIsTokuiValid(false);
        this.resetTokuiSuggestions();
    };
    __decorate([
        observable
    ], MeigiChangeScreenStore.prototype, "sozaiDetail", void 0);
    __decorate([
        observable
    ], MeigiChangeScreenStore.prototype, "INo", void 0);
    __decorate([
        observable
    ], MeigiChangeScreenStore.prototype, "nohinsyo", void 0);
    __decorate([
        observable
    ], MeigiChangeScreenStore.prototype, "entries", void 0);
    __decorate([
        observable
    ], MeigiChangeScreenStore.prototype, "isSubmitted", void 0);
    __decorate([
        observable
    ], MeigiChangeScreenStore.prototype, "isWarnDialogShown", void 0);
    __decorate([
        observable
    ], MeigiChangeScreenStore.prototype, "warnDialogMessages", void 0);
    __decorate([
        observable
    ], MeigiChangeScreenStore.prototype, "tokuiInput", void 0);
    __decorate([
        observable
    ], MeigiChangeScreenStore.prototype, "tokuiSuggestions", void 0);
    __decorate([
        observable
    ], MeigiChangeScreenStore.prototype, "isTokuiValid", void 0);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "setINo", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "setNohinsyo", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "setEntries", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "setIsSubmitted", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "setShowsWarnDialogTrue", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "setShowsWarnDialogFalse", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "setWarnMessage", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "setWarnMessages", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "setTokuiInput", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "setTokuiSuggestions", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "setIsTokuiValid", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "resetTokuiSuggestions", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "removeEntry", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "addEntry", null);
    __decorate([
        action.bound
    ], MeigiChangeScreenStore.prototype, "resetInputs", null);
    return MeigiChangeScreenStore;
}());
export { MeigiChangeScreenStore };

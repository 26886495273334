export function createNullParameter() {
    return {
        ParaStatus: "",
        ParaZaikoUmu: "",
        ParaSokoKBN: "",
        ParaEvent: "",
        ParaAlertMail: "",
        ParaAlertSMTP: "",
        ParaAlertJob: "",
        ParaEncriptKey: "",
        ParaJobStart: "",
        ParaJobEnd: "",
        ParaSoundOK: "",
        ParaSoundNG: "",
        ParaSoundWG: "",
        ParaPwDays: "",
        ParaPwChange: "",
        ParaPwHistory: ""
    };
}

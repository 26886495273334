import * as React from "react";
var YScrollKeeperData = /** @class */ (function () {
    function YScrollKeeperData() {
        this.current = null;
    }
    YScrollKeeperData.prototype.reset = function () {
        this.current = null;
    };
    return YScrollKeeperData;
}());
export { YScrollKeeperData };
export function useYScrollKeeper(dat) {
    var domRef = React.useRef(null);
    var scrollRef = React.useCallback(function (target) {
        var _a, _b;
        if (target === domRef.current)
            return;
        if (target) {
            if (dat.current != null)
                target.scrollTop = dat.current;
        }
        else {
            dat.current = (_b = (_a = domRef.current) === null || _a === void 0 ? void 0 : _a.scrollTop) !== null && _b !== void 0 ? _b : null;
        }
        domRef.current = target;
    }, [domRef]);
    return scrollRef;
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { Edit, Trash2, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
var SakuhinEditScreen = /** @class */ (function (_super) {
    __extends(SakuhinEditScreen, _super);
    function SakuhinEditScreen() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SakuhinEditScreen.prototype.render = function () {
        var _a = this.props, acd = _a.acd, isEditMode = _a.isEditMode, sakuhin = _a.sakuhin, onRegister = _a.onRegister, onDelete = _a.onDelete, onEnd = _a.onEnd, onModifySakuhinCode = _a.onModifySakuhinCode, onModifySakuhinName = _a.onModifySakuhinName, onModifyKisanbi = _a.onModifyKisanbi;
        if (!acd.view)
            return null;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "d-flex justify-content-end mb-2" },
                React.createElement(IconButton, { iconComponent: Edit, label: "\u767B\u9332", onClickButton: onRegister, disabled: isEditMode ? !acd.c : !acd.d }),
                isEditMode && React.createElement(IconButton, { btnClassName: "btn-outline-danger", iconComponent: Trash2, label: "\u524A\u9664", onClickButton: onDelete, disabled: !acd.d }),
                React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-md-12" },
                        React.createElement(LabeledInputText, { label: "\u984C\u540D\u30B0\u30EB\u30FC\u30D7\u30B3\u30FC\u30C9", type: "text", value: sakuhin.SakuhinCode, onModify: onModifySakuhinCode }))),
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-md-12" },
                        React.createElement(LabeledInputText, { label: "\u984C\u540D\u30B0\u30EB\u30FC\u30D7\u540D", type: "text", value: sakuhin.SakuhinName, onModify: onModifySakuhinName }))),
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col" },
                        React.createElement(LabeledInputText, { label: "\u30A2\u30E9\u30FC\u30C8\u8D77\u7B97\u65E5", type: "text", value: sakuhin.Kisanbi, onModify: onModifyKisanbi }))))));
    };
    return SakuhinEditScreen;
}(React.Component));
export { SakuhinEditScreen };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import format from "date-fns/format";
import { createNullSozaiHistoryQuery } from "../types/SozaiHistoryQuery";
var SozaiHistoryScreenStore = /** @class */ (function () {
    function SozaiHistoryScreenStore() {
        this.query = createNullSozaiHistoryQuery();
        this.entries = [];
        this.beginDateInput = null;
        this.endDateInput = null;
        this.isInitial = true;
        this.warnDialogMessage = "";
    }
    SozaiHistoryScreenStore.prototype.setQuery = function (v) { this.query = v; };
    SozaiHistoryScreenStore.prototype.setEntries = function (v) { this.entries = v; };
    // @action.bound setBeginDateInput(v: Date | null): void { this.beginDateInput = v; }
    // @action.bound setEndDateInput(v: Date | null): void { this.endDateInput = v; }
    SozaiHistoryScreenStore.prototype.setIsInitial = function (v) { this.isInitial = v; };
    SozaiHistoryScreenStore.prototype.changeBeginDateInput = function (v) {
        this.beginDateInput = v;
        this.query.BeginDate = v ? format(v, "yyyyMMdd") : "";
    };
    SozaiHistoryScreenStore.prototype.changeEndDateInput = function (v) {
        this.endDateInput = v;
        this.query.EndDate = v ? format(v, "yyyyMMdd") : "";
    };
    SozaiHistoryScreenStore.prototype.resetInputs = function () {
        this.beginDateInput = null;
        this.endDateInput = null;
        this.query = createNullSozaiHistoryQuery();
    };
    SozaiHistoryScreenStore.prototype.setWarnDialogMessage = function (v) { this.warnDialogMessage = v; };
    SozaiHistoryScreenStore.prototype.resetWarnDialogMessage = function () { this.warnDialogMessage = ""; };
    __decorate([
        observable
    ], SozaiHistoryScreenStore.prototype, "query", void 0);
    __decorate([
        observable
    ], SozaiHistoryScreenStore.prototype, "entries", void 0);
    __decorate([
        observable
    ], SozaiHistoryScreenStore.prototype, "beginDateInput", void 0);
    __decorate([
        observable
    ], SozaiHistoryScreenStore.prototype, "endDateInput", void 0);
    __decorate([
        observable
    ], SozaiHistoryScreenStore.prototype, "isInitial", void 0);
    __decorate([
        observable
    ], SozaiHistoryScreenStore.prototype, "warnDialogMessage", void 0);
    __decorate([
        action.bound
    ], SozaiHistoryScreenStore.prototype, "setQuery", null);
    __decorate([
        action.bound
    ], SozaiHistoryScreenStore.prototype, "setEntries", null);
    __decorate([
        action.bound
    ], SozaiHistoryScreenStore.prototype, "setIsInitial", null);
    __decorate([
        action.bound
    ], SozaiHistoryScreenStore.prototype, "changeBeginDateInput", null);
    __decorate([
        action.bound
    ], SozaiHistoryScreenStore.prototype, "changeEndDateInput", null);
    __decorate([
        action.bound
    ], SozaiHistoryScreenStore.prototype, "resetInputs", null);
    __decorate([
        action.bound
    ], SozaiHistoryScreenStore.prototype, "setWarnDialogMessage", null);
    __decorate([
        action.bound
    ], SozaiHistoryScreenStore.prototype, "resetWarnDialogMessage", null);
    return SozaiHistoryScreenStore;
}());
export { SozaiHistoryScreenStore };

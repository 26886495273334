import * as React from "react";
import { X } from "react-feather";
import { observer } from "mobx-react";
// import { LabeledInputText } from "../common/LabeledInputText";
import * as styles from "./HelpDialog.module.scss";
function useOutsideClickEffect(ref, callback) {
    React.useEffect(function () {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}
export var HelpDialog = observer(function HelpDialog(props) {
    var dialogRef = React.useRef(null);
    useOutsideClickEffect(dialogRef, props.onClose);
    return (React.createElement("div", { className: styles["backdrop"] },
        React.createElement("div", { className: styles["dialog"], ref: dialogRef },
            React.createElement("div", { className: styles["dialog-close"], onClick: props.onClose },
                React.createElement(X, { size: 20 })),
            React.createElement("dl", { className: "mx-5 my-4" },
                React.createElement("dt", { className: "mb-1 font-weight-bold" }, "\u68DA\u5378\u4F5C\u696D (\u7D20\u6750\u306E\u30B9\u30AD\u30E3\u30F3) \u3092\u884C\u3046:"),
                React.createElement("dd", { className: "ml-3 mb-4" },
                    React.createElement("code", null, "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3"),
                    " \u6B04\u306B\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u30B3\u30FC\u30C9 (e.g. ",
                    React.createElement("code", null, "LOC0001%0001"),
                    ") \u3092\u5165\u529B\u3059\u308B\u304B\u3001",
                    React.createElement("code", null, "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3"),
                    " \u6B04\u3068 ",
                    React.createElement("code", null, "\u68DA\u756A"),
                    " \u6B04\u306B\u540D\u524D\u3092\u5165\u529B\u3057\u3066 ",
                    React.createElement("code", null, "\u691C\u7D22"),
                    " \u30DC\u30BF\u30F3\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
                    React.createElement("code", null, "\u30B9\u30AD\u30E3\u30F3\u958B\u59CB"),
                    " \u30DC\u30BF\u30F3\u3067\u5404\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u306E\u7D20\u6750\u306E\u30B9\u30AD\u30E3\u30F3\u3092\u884C\u3046\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002"),
                props.acd.d ?
                    React.createElement(React.Fragment, null,
                        React.createElement("dt", { className: "mb-1 font-weight-bold" }, "\u7BA1\u7406\u4F5C\u696D\u3092\u884C\u3046:"),
                        React.createElement("dd", { className: "ml-2" },
                            React.createElement("code", null, "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3"),
                            " \u6B04\u306B\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u540D (\u307E\u305F\u306F ",
                            React.createElement("code", null, "*"),
                            ") \u3092\u3001",
                            React.createElement("code", null, "\u68DA\u756A"),
                            " \u6B04\u306B ",
                            React.createElement("code", null, "*"),
                            " \u3092\u5165\u529B\u3057\u3066 ",
                            React.createElement("code", null, "\u691C\u7D22"),
                            " \u30DC\u30BF\u30F3\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044\u3002 \u9078\u629E\u3057\u305F\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u306E\u30EA\u30B9\u30C8\u30AF\u30EA\u30A2\u3084\u3001\u4E0D\u660E\u30C7\u30FC\u30BF CSV \u306E\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u3092\u884C\u3048\u307E\u3059\u3002")) :
                    React.createElement(React.Fragment, null)))));
});

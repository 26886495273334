import * as React from "react";
import { observer } from "mobx-react";
var outerStyle = {
    fontSize: "0.9rem"
};
export var LabeledFixedText = observer(function LabeledFixedText(props) {
    return (React.createElement("div", { className: props.className, style: outerStyle },
        React.createElement("span", { className: "text-secondary" }, props.label),
        React.createElement("input", { type: "text", disabled: true, className: "form-control form-control-sm ", value: props.value })));
});

import * as React from "react";
import { observer } from "mobx-react";
import { X, Download, Edit3, Search, XOctagon, HelpCircle } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
import { Navbar } from "../common/Navbar";
import * as styles from "./TanaoroshiTopScreen.module.scss";
import { useYScrollKeeper } from "../common/hooks/useYScrollKeeper";
export var TanaoroshiTopScreen = observer(function (props) {
    var scrollData = props.scrollData, acd = props.acd, entries = props.entries, actionMode = props.actionMode, locationInput = props.locationInput, tanabanInput = props.tanabanInput, checksAll = props.checksAll, onChangeLocationInput = props.onChangeLocationInput, onChangeTanabanInput = props.onChangeTanabanInput, onSelect = props.onSelect, onSearch = props.onSearch, onScan = props.onScan, onClearList = props.onClearList, onDownload = props.onDownload, onEnd = props.onEnd, onCheckAll = props.onCheckAll, onCheck = props.onCheck, onClickHelp = props.onClickHelp;
    var scrollRef = useYScrollKeeper(scrollData);
    var stopPropagation = React.useCallback(function (ev) { return ev.stopPropagation(); }, []);
    if (!acd.view)
        return null;
    var hasChecked = entries && entries.some(function (e) { return e.isChecked; });
    var isManageMode = actionMode === "MANAGE";
    return (React.createElement("div", { className: "d-flex flex-column h-100" },
        React.createElement(Navbar, { name: "\u68DA\u5378" }),
        React.createElement("div", { className: "container-fuild flex-glow-0 flex-shrink-0" },
            React.createElement("div", { className: "d-flex flex-wrap-reverse justify-content-between mb-2" },
                React.createElement("div", { className: "flex-grow-1 flex-shrink-1 align-self-start d-flex align-items-end" },
                    React.createElement(LabeledInputText, { label: React.createElement(React.Fragment, null,
                            React.createElement("span", null, "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3"),
                            " ",
                            React.createElement("span", { className: styles["help-icon"], onClick: onClickHelp },
                                React.createElement(HelpCircle, { size: 17 }))), className: "ml-2 mr-1", value: locationInput, onModify: onChangeLocationInput, onEnter: onSearch }),
                    React.createElement(LabeledInputText, { label: "棚番", className: "mr-1", value: tanabanInput, onModify: onChangeTanabanInput, onEnter: onSearch }),
                    React.createElement(IconButton, { iconComponent: Search, className: "", label: "\u691C\u7D22", onClickButton: onSearch })),
                React.createElement("div", { className: "flex-grow-1 flex-shrink-0 align-self-end ml-2 text-right text-nowrap" },
                    React.createElement(IconButton, { iconComponent: Edit3, label: "\u30B9\u30AD\u30E3\u30F3\u958B\u59CB", onClickButton: onScan, disabled: !acd.c || !hasChecked || isManageMode }),
                    React.createElement(IconButton, { btnClassName: "btn-outline-danger mr-3", iconComponent: XOctagon, label: "\u30EA\u30B9\u30C8\u30AF\u30EA\u30A2", onClickButton: onClearList, disabled: !acd.d || !hasChecked || !isManageMode }),
                    React.createElement(IconButton, { btnClassName: "btn-primary mr-3", iconComponent: Download, label: "\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9", onClickButton: onDownload, disabled: !hasChecked || !isManageMode }),
                    React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })))),
        React.createElement("div", { ref: scrollRef, className: "flex-grow-1 overflow-auto" },
            React.createElement("table", { className: "table table-bordered table-striped table-hover table-sm " + styles["sozai-table"] },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3"),
                        React.createElement("th", null, "\u68DA\u756A"),
                        React.createElement("th", { className: styles["thin"] }, isManageMode ?
                            React.createElement("input", { type: "checkbox", checked: checksAll, onChange: onCheckAll })
                            : null),
                        React.createElement("th", null, "\u30B9\u30AD\u30E3\u30F3\u6570"),
                        React.createElement("th", null, "\u4E0D\u660E\u6570"),
                        React.createElement("th", null, "\u51E6\u7406\u65E5"),
                        React.createElement("th", null, "\u4ED6\u3067\u306E\u30B9\u30AD\u30E3\u30F3\u6570"))),
                React.createElement("tbody", null, entries != null
                    ? entries.map(function (entry, lineIndex) {
                        var handleCheck = function (ev) {
                            ev.stopPropagation();
                            onCheck(lineIndex);
                        };
                        return (React.createElement("tr", { key: entry.LocationCode + "___ TODO REMOVE-" + lineIndex, onDoubleClick: function () { return onSelect(lineIndex); } },
                            React.createElement("td", { scope: "col" }, entry.TanaLocation),
                            React.createElement("td", { scope: "col" }, entry.Tanaban),
                            React.createElement("td", { scope: "col", className: styles["thin"], onClick: handleCheck }, isManageMode ?
                                React.createElement("input", { type: "checkbox", name: "input", checked: entry.isChecked, onClick: stopPropagation, onChange: function () { return onCheck(lineIndex); } }) :
                                React.createElement("input", { type: "radio", name: "input", checked: entry.isChecked, onClick: stopPropagation, onChange: function () { return onCheck(lineIndex); } })),
                            React.createElement("td", { scope: "col" }, entry.ScanCNT),
                            React.createElement("td", { scope: "col" }, entry.FumeiCNT),
                            React.createElement("td", { scope: "col" }, entry.ScanDate),
                            React.createElement("td", { scope: "col" }, entry.HokaCNT)));
                    })
                    : null)))));
});

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { observer } from "mobx-react";
import DatePicker from "react-datepicker";
import { Edit, Copy, Trash2, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { CompletableInput } from "../common/CompletableInput";
import { LabeledInputText } from "../common/LabeledInputText";
import { InputTextMultiLine } from "../common/InputTextMultiLine";
import { formatWeekDay, formatYYYYMMDD } from "../../util/dateUtil";
import * as styles from "./SozaiEditScreen.module.scss";
var ReadOnlyInput = observer(function (props) {
    return React.createElement("div", { className: "form-control form-control-sm " + styles.noborder }, props.children);
});
var SozaiEditScreen = /** @class */ (function (_super) {
    __extends(SozaiEditScreen, _super);
    function SozaiEditScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // handleClickSearchSakuhinCode = (ev: React.MouseEvent<HTMLAnchorElement>) => {
        //   ev.preventDefault();
        //   this.props.onClickSearchSakuhinCode();
        // };
        _this.handleModifyINo = function (INo) {
            _this.props.onModify(__assign(__assign({}, _this.props.sozaiDetail), { INo: INo }));
        };
        // handleModifyTokuiCode = (TokuiCode: string) => {
        //   this.props.onModify({ ...this.props.sozaiDetail, TokuiCode });
        // };
        // handleModifyTokuiName = (TokuiName: string) => {
        //   this.props.onModify({ ...this.props.sozaiDetail, TokuiName });
        // };
        // handleModifyKyuCode = (KyuCode: string) => {
        //   this.props.onModify({ ...this.props.sozaiDetail, KyuCode });
        // };
        // handleModifyKyuName = (KyuName: string) => {
        //   this.props.onModify({ ...this.props.sozaiDetail, KyuName });
        // };
        // handleModifySakuhinName = (SakuhinName: string) => {
        //   this.props.onModify({ ...this.props.sozaiDetail, SakuhinName });
        // };
        _this.handleModifyDaimei = function (Daimei) {
            _this.props.onModify(__assign(__assign({}, _this.props.sozaiDetail), { Daimei: Daimei }));
        };
        _this.handleModifyFukudai = function (Fukudai) {
            _this.props.onModify(__assign(__assign({}, _this.props.sozaiDetail), { Fukudai: Fukudai }));
        };
        _this.handleModifySeries = function (Series) {
            _this.props.onModify(__assign(__assign({}, _this.props.sozaiDetail), { Series: Series }));
        };
        // handleModifyKeijoName = (KeijoName: string) => {
        //   this.props.onModify({ ...this.props.sozaiDetail, KeijoName });
        // };
        // handleModifyMediaNaiyo = (MediaNaiyo: string) => {
        //   this.props.onModify({ ...this.props.sozaiDetail, MediaNaiyo });
        // };
        // handleModifyProdDate = (ProdDate: string) => {
        //   this.props.onModify({ ...this.props.sozaiDetail, ProdDate });
        // };
        _this.handleModifyTokiJiko = function (TokiJiko) {
            _this.props.onModify(__assign(__assign({}, _this.props.sozaiDetail), { TokiJiko: TokiJiko }));
        };
        _this.handleModifyHensakiName = function (HensakiName) {
            _this.props.onModify(__assign(__assign({}, _this.props.sozaiDetail), { HensakiName: HensakiName }));
        };
        return _this;
        // handleModifyTantoName = (TantoName: string) => {
        //   this.props.onModify({ ...this.props.sozaiDetail, TantoName });
        // };
        // handleModifyLocationName = (LocationName: string) => {
        //   this.props.onModify({ ...this.props.sozaiDetail, LocationName });
        // };
        // handleModifyTanaban = (Tanaban: string) => {
        //   this.props.onModify({ ...this.props.sozaiDetail, Tanaban });
        // };
    }
    SozaiEditScreen.prototype.render = function () {
        var _a = this.props, acd = _a.acd, sozaiDetail = _a.sozaiDetail, isEditMode = _a.isEditMode, prodDate = _a.prodDate, entryDate = _a.entryDate, updateDate = _a.updateDate;
        function row_2td(l, r) {
            return (React.createElement("tr", null,
                React.createElement("td", { colSpan: 1 }, l),
                React.createElement("td", { colSpan: 3 }, r)));
        }
        function row_2td_4(l1, l2, r1, r2) {
            return (React.createElement("tr", null,
                React.createElement("td", null, l1),
                React.createElement("td", null, l2),
                React.createElement("td", null, r1),
                React.createElement("td", null, r2)));
        }
        if (!acd.view)
            return null;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "d-flex justify-content-end mb-2" },
                React.createElement(IconButton, { iconComponent: Edit, label: "\u767B\u9332", dataCy: "register-button", onClickButton: this.props.onRegister, disabled: isEditMode ? !acd.c : !acd.a }),
                isEditMode ?
                    React.createElement(React.Fragment, null,
                        React.createElement(IconButton, { iconComponent: Copy, label: "\u30B3\u30D4\u30FC\u3057\u3066\u65B0\u898F\u767B\u9332", onClickButton: this.props.onCopyNew, disabled: !acd.a }),
                        React.createElement(IconButton, { btnClassName: "btn-outline-danger", iconComponent: Trash2, label: "\u524A\u9664", dataCy: "delete-button", onClickButton: this.props.onDelete, disabled: !acd.d })) :
                    React.createElement(IconButton, { iconComponent: Edit, label: "\u9023\u7D9A\u767B\u9332", onClickButton: this.props.onSerialRegister, disabled: isEditMode ? !acd.c : !acd.a }),
                React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", dataCy: "end-button", onClickButton: this.props.onEnd })),
            React.createElement("div", { className: "container" },
                React.createElement("table", { className: "table table-stripe align-baseline " + styles["table"] },
                    React.createElement("tbody", null,
                        isEditMode ?
                            row_2td("IMAGICA番号", React.createElement(ReadOnlyInput, null, sozaiDetail.INo)) :
                            row_2td(React.createElement(React.Fragment, null,
                                React.createElement("small", { className: "bg-danger rounded py-1 px-2 mr-2" }, "\u5FC5\u9808"),
                                "IMAGICA\u756A\u53F7"), React.createElement(LabeledInputText, { value: sozaiDetail.INo, dataCy: "ino-input", onModify: this.handleModifyINo })),
                        row_2td(React.createElement(React.Fragment, null,
                            React.createElement("small", { className: "bg-danger rounded py-1 px-2 mr-2" }, "\u5FC5\u9808"),
                            "\u5F97\u610F\u5148\u540D"), React.createElement(CompletableInput, __assign({}, this.props.completePropsTokui, { inputId: "tokui-input" }))),
                        row_2td("旧得意先名", React.createElement(CompletableInput, __assign({}, this.props.completePropsKyu, { inputId: "kyu-input" }))),
                        row_2td("題名グループ", React.createElement(CompletableInput, __assign({}, this.props.completePropsSakuhin, { inputId: "sakuhin-input" }))),
                        row_2td(React.createElement(React.Fragment, null,
                            React.createElement("small", { className: "bg-danger rounded py-1 px-2 mr-2" }, "\u5FC5\u9808"),
                            "\u984C\u540D"), React.createElement(InputTextMultiLine, { value: sozaiDetail.Daimei, dataCy: "daimei-input", onModify: this.handleModifyDaimei, placeholder: "\u30D5\u30EA\u30FC\u30EF\u30FC\u30C9\u5165\u529B" })),
                        row_2td("副題", React.createElement(InputTextMultiLine, { value: sozaiDetail.Fukudai, dataCy: "fukudai-input", onModify: this.handleModifyFukudai, placeholder: "\u30D5\u30EA\u30FC\u30EF\u30FC\u30C9\u5165\u529B" })),
                        row_2td("メモ", React.createElement(LabeledInputText, { value: sozaiDetail.Series, dataCy: "series-input", onModify: this.handleModifySeries, placeholder: "\u30D5\u30EA\u30FC\u30EF\u30FC\u30C9\u5165\u529B" })),
                        row_2td(React.createElement(React.Fragment, null,
                            React.createElement("small", { className: "bg-danger rounded py-1 px-2 mr-2" }, "\u5FC5\u9808"),
                            "\u5F62\u72B6"), React.createElement(CompletableInput, __assign({}, this.props.completePropsKeijo, { inputId: "keijo-input" }))),
                        row_2td(React.createElement(React.Fragment, null,
                            React.createElement("small", { className: "bg-danger rounded py-1 px-2 mr-2" }, "\u5FC5\u9808"),
                            "\u30E1\u30C7\u30A3\u30A2\u5185\u5BB9"), React.createElement(CompletableInput, __assign({}, this.props.completePropsMedia, { inputId: "media-input" }))),
                        row_2td("制作年月日", React.createElement(DatePicker, { dateFormat: "yyyy/MM/dd", dateFormatCalendar: "yyyy \u5E74 MM \u6708", className: "form-control form-control-sm", formatWeekDay: formatWeekDay, selected: prodDate, showPopperArrow: false, onChange: this.props.onModifyProdDate, showYearDropdown: true, wrapperClassName: "w-100", id: "datepickerid" })),
                        row_2td("担当者", React.createElement(CompletableInput, __assign({}, this.props.completePropsTanto, { inputId: "tanto-input" }))),
                        row_2td("特記事項", React.createElement(LabeledInputText, { value: sozaiDetail.TokiJiko, dataCy: "tokijiko-input", onModify: this.handleModifyTokiJiko, placeholder: "\u30D5\u30EA\u30FC\u30EF\u30FC\u30C9\u5165\u529B" })),
                        row_2td("入荷元名", React.createElement(LabeledInputText, { value: sozaiDetail.HensakiName, dataCy: "hensakiname-input", onModify: this.handleModifyHensakiName, placeholder: "\u30D5\u30EA\u30FC\u30EF\u30FC\u30C9\u5165\u529B" })),
                        isEditMode ?
                            row_2td("ロケーション名", React.createElement(ReadOnlyInput, null, this.props.completePropsLocation.inputValue)) :
                            row_2td(React.createElement(React.Fragment, null,
                                React.createElement("small", { className: "bg-danger rounded py-1 px-2 mr-2" }, "\u5FC5\u9808"),
                                "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u540D"), React.createElement(CompletableInput, __assign({}, this.props.completePropsLocation, { inputId: "location-input" }))),
                        isEditMode ?
                            row_2td("棚番号", React.createElement(ReadOnlyInput, null, this.props.completePropsTanaban.inputValue)) :
                            row_2td(React.createElement(React.Fragment, null,
                                React.createElement("small", { className: "bg-danger rounded py-1 px-2 mr-2" }, "\u5FC5\u9808"),
                                "\u68DA\u756A\u53F7"), React.createElement(CompletableInput, __assign({}, this.props.completePropsTanaban, { inputId: "tanaban-input" }))),
                        isEditMode
                            ? [
                                row_2td("ステータス", React.createElement(ReadOnlyInput, null, sozaiDetail.Status)),
                                row_2td("在庫有無", React.createElement(ReadOnlyInput, null, sozaiDetail.ZaikoUmu)),
                                row_2td("納品記録", React.createElement(ReadOnlyInput, null, sozaiDetail.Kiroku)),
                                row_2td_4("登録日", React.createElement(ReadOnlyInput, null, entryDate && formatYYYYMMDD(entryDate, "/")), "登録者", React.createElement(ReadOnlyInput, null, sozaiDetail.EntryName)),
                                row_2td_4("最終更新", React.createElement(ReadOnlyInput, null, updateDate && formatYYYYMMDD(updateDate, "/")), "更新者", React.createElement(ReadOnlyInput, null, sozaiDetail.UpdateName))
                            ]
                            : null // 新規登録時はないので表示させない
                    )),
                React.createElement("div", { className: "row justify-content-end mb-3" },
                    React.createElement(IconButton, { iconComponent: Edit, label: "\u767B\u9332", onClickButton: this.props.onRegister, disabled: isEditMode ? !acd.c : !acd.a }),
                    isEditMode ?
                        React.createElement(React.Fragment, null,
                            React.createElement(IconButton, { iconComponent: Copy, label: "\u30B3\u30D4\u30FC\u3057\u3066\u65B0\u898F\u767B\u9332", onClickButton: this.props.onCopyNew, disabled: !acd.a }),
                            React.createElement(IconButton, { btnClassName: "btn-outline-danger", iconComponent: Trash2, label: "\u524A\u9664", onClickButton: this.props.onDelete, disabled: !acd.d })) :
                        React.createElement(IconButton, { iconComponent: Edit, label: "\u9023\u7D9A\u767B\u9332", onClickButton: this.props.onSerialRegister, disabled: isEditMode ? !acd.c : !acd.a })),
                React.createElement("div", { className: styles["emptylongbox"] }))));
    };
    SozaiEditScreen = __decorate([
        observer
    ], SozaiEditScreen);
    return SozaiEditScreen;
}(React.Component));
export { SozaiEditScreen };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import { Edit, Trash2, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
import { LabeledSelect2 } from "../common/LabeledSelect2";
export var LocationEditScreen = observer(function (props) {
    var acd = props.acd, isEditMode = props.isEditMode, location = props.location, tanaSozais = props.tanaSozais, onModify = props.onModify, onRegister = props.onRegister, onDelete = props.onDelete, onEnd = props.onEnd;
    var handleModifyLocationCode = React.useCallback(function (v) { return onModify(__assign(__assign({}, location), { LocationCode: v })); }, [onModify, location]);
    var handleModifyLocationName = React.useCallback(function (v) { return onModify(__assign(__assign({}, location), { LocationName: v })); }, [onModify, location]);
    var handleModifySokoKBN = React.useCallback(function (v) { return onModify(__assign(__assign({}, location), { SokoKBN: v })); }, [onModify, location]);
    var handleModifyTanaCode = React.useCallback(function (v) { return onModify(__assign(__assign({}, location), { TanaCode: v })); }, [onModify, location]);
    var handleModifyTanaban = React.useCallback(function (v) { return onModify(__assign(__assign({}, location), { Tanaban: v })); }, [onModify, location]);
    var handleModifySozaiRWT = React.useCallback(function (v) { return onModify(__assign(__assign({}, location), { SozaiRWT: v })); }, [onModify, location]);
    if (!acd.view)
        return null;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-end mb-2" },
            React.createElement(IconButton, { iconComponent: Edit, label: "\u767B\u9332", onClickButton: onRegister, disabled: isEditMode ? !acd.c : !acd.a }),
            isEditMode && React.createElement(IconButton, { btnClassName: "btn-outline-danger", iconComponent: Trash2, label: "\u524A\u9664", onClickButton: onDelete, disabled: !acd.d }),
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3\u30B3\u30FC\u30C9", value: location.LocationCode, onModify: handleModifyLocationCode }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3", value: location.LocationName, onModify: handleModifyLocationName })),
                React.createElement("div", { className: "col-4" },
                    React.createElement(LabeledSelect2, { label: "\u5009\u5EAB\u533A\u5206", options: ["社内", "社外"], selectedOption: location.SokoKBN, onModify: handleModifySokoKBN }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u68DA\u756A\u30B3\u30FC\u30C9", value: location.TanaCode, onModify: handleModifyTanaCode }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-12" },
                    React.createElement(LabeledInputText, { label: "\u68DA\u756A", value: location.Tanaban, onModify: handleModifyTanaban }))),
            (isEditMode) ?
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "row mb-2" },
                        React.createElement("div", { className: "col-4" },
                            React.createElement(LabeledSelect2, { label: "\u7D20\u6750\u5728\u5EAB\u306B\u53CD\u6620", options: ["-", "素材の棚番を書き換える"], selectedOption: location.SozaiRWT, onModify: handleModifySozaiRWT }))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col" },
                            React.createElement("table", { className: "table table-bordered table-striped table-hover " },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, "\u7D20\u6750\u306E\u68DA\u756A"),
                                        React.createElement("th", null, "\u7D20\u6750\u6570"))),
                                React.createElement("tbody", null, (tanaSozais || []).map(function (tanaSozai, i) { return (React.createElement("tr", { key: "".concat(tanaSozai.Tanaban) },
                                    React.createElement("td", null, tanaSozai.Tanaban),
                                    React.createElement("td", null, tanaSozai.SozaiCNT))); }))))),
                    (tanaSozais.length) ?
                        null :
                        React.createElement("div", { className: "text-center mt-2" }, "\u8A72\u5F53\u30C7\u30FC\u30BF\u304C\u3042\u308A\u307E\u305B\u3093"))
                : null));
});

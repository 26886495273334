import * as React from "react";
import { observer } from "mobx-react";
import { X, Download } from "react-feather";
import { IconButton } from "../common/IconButton";
export var DataTransportScreen = observer(function (props) {
    var isLoading = props.isLoading, onDownload = props.onDownload, onEnd = props.onEnd;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-end mb-2" },
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row mb-3" },
                React.createElement("div", { className: "col-3" }),
                React.createElement("div", { className: "col-6" },
                    React.createElement(IconButton, { btnClassName: "btn btn-primary btn-block p-2", disabled: isLoading, iconComponent: Download, label: "\u5168\u7D20\u6750CSV\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9", onClickButton: onDownload }),
                    isLoading &&
                        React.createElement("div", { className: "spinner-border spinner-border-sm m-1", role: "status" },
                            React.createElement("span", { className: "sr-only" }, "Loading..."))),
                React.createElement("div", { className: "col-3" }))));
});

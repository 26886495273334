export function formatDate(date) {
    var yyyy = date.getFullYear();
    var MM = ('0' + (date.getMonth() + 1)).slice(-2);
    var dd = ('0' + date.getDate()).slice(-2);
    var hh = ('0' + date.getHours()).slice(-2);
    var mm = ('0' + date.getMinutes()).slice(-2);
    return "".concat(yyyy).concat(MM).concat(dd, "-").concat(hh).concat(mm);
}
export function formatYYYYMMDD(date, sep) {
    if (sep === void 0) { sep = ""; }
    var yyyy = date.getFullYear();
    var MM = ('0' + (date.getMonth() + 1)).slice(-2);
    var dd = ('0' + date.getDate()).slice(-2);
    return "".concat(yyyy).concat(sep).concat(MM).concat(sep).concat(dd);
}
export function parseYYYYMMDD(s) {
    try {
        // 例によって仕様書が無であるため形式は不明だが YYYYMMDD であるようだ。念のため YYYY-MM-DD と YYYY/MM/DD も許容しておく
        var m = s.match(/^(\d\d\d\d)[-/]?(\d\d)[-/]?(\d\d)$/);
        if (!m)
            return null;
        return new Date(parseInt(m[1], 10), parseInt(m[2], 10) - 1, parseInt(m[3], 10));
    }
    catch (e) {
        // do nothing
    }
    return null;
}
var weekDayTable = {
    "Sunday": "日",
    "Monday": "月",
    "Tuesday": "火",
    "Wednesday": "水",
    "Thursday": "木",
    "Friday": "金",
    "Saturday": "土"
};
export function formatWeekDay(weekDay) {
    return weekDayTable[weekDay];
}

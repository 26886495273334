var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
import { Edit, Trash2, X } from "react-feather";
import { IconButton } from "../common/IconButton";
import { LabeledInputText } from "../common/LabeledInputText";
export var KeijoEditScreen = observer(function (props) {
    var acd = props.acd, isEditMode = props.isEditMode, keijo = props.keijo, onModify = props.onModify, onRegister = props.onRegister, onDelete = props.onDelete, onEnd = props.onEnd;
    var handleModifyKeijoCode = React.useCallback(function (v) { return onModify(__assign(__assign({}, keijo), { KeijoCode: v })); }, [onModify, keijo]);
    var handleModifyKeijoName = React.useCallback(function (v) { return onModify(__assign(__assign({}, keijo), { KeijoName: v })); }, [onModify, keijo]);
    if (!acd.view)
        return null;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex justify-content-end mb-2" },
            React.createElement(IconButton, { iconComponent: Edit, label: "\u767B\u9332", onClickButton: onRegister, disabled: isEditMode ? !acd.c : !acd.a }),
            isEditMode && React.createElement(IconButton, { btnClassName: "btn-outline-danger", iconComponent: Trash2, label: "\u524A\u9664", onClickButton: onDelete, disabled: !acd.d }),
            React.createElement(IconButton, { btnClassName: "btn-outline-primary", iconComponent: X, label: "\u7D42\u4E86", onClickButton: onEnd })),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u5F62\u72B6\u30B3\u30FC\u30C9", value: keijo.KeijoCode, onModify: handleModifyKeijoCode }))),
            React.createElement("div", { className: "row mb-2" },
                React.createElement("div", { className: "col-6" },
                    React.createElement(LabeledInputText, { label: "\u5F62\u72B6\u540D", value: keijo.KeijoName, onModify: handleModifyKeijoName })))));
});

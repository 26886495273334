import * as React from "react";
var style = {
    fontSize: "75%"
};
export function SuggestionEntry(props) {
    return React.createElement("span", null,
        props.name,
        React.createElement("span", { style: style, className: "pl-2 text-white-50" },
            "(",
            props.code,
            ")"));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
export function makeMakeSuggestionProps(opts) {
    return function makeSuggestionProps(arg) {
        var _this = this;
        var isValid = arg.isValid, input = arg.input, suggestions = arg.suggestions, disabled = arg.disabled;
        var updateSuggestions = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, opts.search(input)];
                    case 1:
                        res = _a.sent();
                        if (res.length === 0) {
                            opts.storeSetSuggestions([opts.makeInvalidValue()]);
                        }
                        else if (res.length !== 1) {
                            opts.storeSetSuggestions(res);
                        }
                        else {
                            opts.storeSetValue(res[0]);
                            opts.storeSetIsValid(true);
                            opts.storeSetInput(opts.inputOfValue(res[0]));
                            opts.storeSetSuggestions([]);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        alert(e_1.message);
                        return [2 /*return*/];
                    case 3: return [2 /*return*/];
                }
            });
        }); }, [opts, input]);
        var handleBlurInput = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
            var value, res, _e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!opts.isAcceptEmpty && input === "") {
                            opts.storeSetValue(opts.makeEmptyValue());
                            opts.storeSetIsValid(false);
                            return [2 /*return*/];
                        }
                        value = suggestions.find(function (v) { return opts.inputOfValue(v) === input; });
                        if (!!value) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, opts.search(input)];
                    case 2:
                        res = _a.sent();
                        value = res.find(function (v) { return opts.inputOfValue(v) === input; });
                        return [3 /*break*/, 4];
                    case 3:
                        _e_1 = _a.sent();
                        value = undefined;
                        return [3 /*break*/, 4];
                    case 4:
                        if (!value) {
                            // それでも見つからなければ invalid
                            opts.storeSetValue(opts.makeEmptyValue());
                            opts.storeSetIsValid(false);
                            return [2 /*return*/];
                        }
                        opts.storeSetValue(value);
                        opts.storeSetIsValid(true);
                        return [2 /*return*/];
                }
            });
        }); }, [opts, input, suggestions]);
        var handleSelectSuggestion = React.useCallback(function (value) {
            if (opts.isInvalidValue(value)) {
                opts.storeSetValue(opts.makeEmptyValue());
                opts.storeSetIsValid(false);
                opts.storeSetInput("");
                return;
            }
            opts.storeSetValue(value);
            opts.storeSetIsValid(true);
        }, [opts]);
        return {
            inputValue: input,
            showsCheck: isValid,
            suggestions: suggestions,
            disabled: disabled,
            renderSuggestion: opts.renderSuggestion,
            getSuggestionValue: opts.getSuggestionString,
            onSuggestionsClearRequested: opts.storeResetSuggestions,
            onSuggestionsFetchRequested: updateSuggestions,
            onModifyInputValue: opts.storeSetInput,
            onBlur: handleBlurInput,
            onSelected: handleSelectSuggestion
        };
    };
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { observer } from "mobx-react";
var RefTextArea = React.forwardRef(function (props, ref) {
    return React.createElement("textarea", __assign({ ref: ref }, props));
});
export var InputTextMultiLine = observer(function InputTextMultiLine(props) {
    var value = props.value, placeholder = props.placeholder, onModify = props.onModify, onBlur = props.onBlur, rawRef = props.rawRef, className = props.className, dataCy = props.dataCy, disabled = props.disabled;
    var handleChange = React.useCallback(function (ev) {
        var next = ev.target.value;
        if (typeof next === "string") {
            onModify(next);
        }
    }, [onModify]);
    return (React.createElement(RefTextArea, { ref: rawRef, className: "form-control form-control-sm " + (className || ""), value: value || "", "data-cy": dataCy, placeholder: placeholder, disabled: disabled, onChange: handleChange, onBlur: onBlur }));
});

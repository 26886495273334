var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import download from "downloadjs";
import { StoreContext } from "../../context";
import { makeCSV } from "../../util/makeCSV";
import { formatDate } from "../../util/dateUtil";
import { TanaoroshiTopScreen } from "../tanaoroshi/TanaoroshiTopScreen";
import { HelpDialog } from "../tanaoroshi/HelpDialog";
import * as api from "../../api/apiClient";
import { isLocationCodeWithTanaban } from "../../util/codeUtil";
function extractLocTanaCode(e) {
    return { LocationCode: e.LocationCode, TanaCode: e.TanaCode };
}
function locTanaStrOf(e) {
    return "".concat(e.LocationCode, "%").concat(e.TanaCode);
}
function tableOf(xs, keyFun) {
    return xs.reduce(function (acc, x) {
        acc[keyFun(x)] = x;
        return acc;
    }, {});
}
export var TanaoroshiTopScreenRoot = observer(function TanaoroshiTopScreenRoot() {
    var _this = this;
    var store = React.useContext(StoreContext);
    var history = useHistory();
    var screenStore = store.tanaoroshiScreenStore;
    var dialogStore = store.helpDialogStore;
    var acd = store.userStore.appAuthTable.stocktaking;
    // TODO tanaban の値を指定する
    var doSearchRaw = React.useCallback(function (locationName, tanaban, preserve) {
        if (preserve === void 0) { preserve = false; }
        return __awaiter(_this, void 0, void 0, function () {
            var res, table_1, entries, selected, selectedLocTanaStr_1, idx;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.tanaTop({ locationName: locationName, tanaban: tanaban })];
                    case 1:
                        res = _a.sent();
                        if (res.RtnCode !== "OK") {
                            alert(res.RtnMessage);
                            return [2 /*return*/];
                        }
                        if (!preserve || screenStore.actionMode !== res.ActionMode) { // なさそうだが mode が変わったら preserve 断念
                            screenStore.setEntries(res.DataList.map(function (e) { return (__assign(__assign({}, e), { isChecked: false })); }));
                            if (screenStore.actionMode === "SCAN" && screenStore.entries.length === 1)
                                screenStore.toggleChecks(0);
                        }
                        else {
                            if (res.ActionMode === "MANAGE") {
                                table_1 = tableOf(screenStore.entries, locTanaStrOf);
                                screenStore.setEntries(res.DataList.map(function (e) { var _a; return (__assign(__assign({}, e), { isChecked: (_a = table_1[locTanaStrOf(e)]) === null || _a === void 0 ? void 0 : _a.isChecked })); }));
                            }
                            else {
                                entries = screenStore.entries, selected = screenStore.lastSelectedIndex;
                                selectedLocTanaStr_1 = (selected != null) ? locTanaStrOf(entries[selected]) : null;
                                screenStore.setEntries(res.DataList.map(function (e) { return (__assign(__assign({}, e), { isChecked: false })); }));
                                idx = entries.findIndex(function (e) { return locTanaStrOf(e) === selectedLocTanaStr_1; });
                                if (idx !== -1)
                                    screenStore.toggleChecks(idx);
                            }
                        }
                        screenStore.setActionMode(res.ActionMode);
                        return [2 /*return*/];
                }
            });
        });
    }, [screenStore]);
    var doSearch = React.useCallback(function (preserve) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isLocationCodeWithTanaban(screenStore.locationInput)) return [3 /*break*/, 2];
                    return [4 /*yield*/, doSearchRaw(screenStore.locationInput, "", preserve)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2:
                    if (!isLocationCodeWithTanaban(screenStore.tanabanInput)) return [3 /*break*/, 4];
                    return [4 /*yield*/, doSearchRaw(screenStore.tanabanInput, "", preserve)];
                case 3:
                    _a.sent();
                    return [2 /*return*/];
                case 4: return [4 /*yield*/, doSearchRaw(screenStore.locationInput || "^", screenStore.tanabanInput || "^", preserve)];
                case 5:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    React.useEffect(function () {
        if (!acd.view) {
            alert("画面を表示する権限がありません");
            history.go(-1);
            return;
        }
        if (history.action !== "POP") {
            screenStore.reset();
        }
        (function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, doSearch(true)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [acd, screenStore]);
    // TODO stocktaking-detail コメントアウト
    var handleSelect = React.useCallback(function (idx) {
        screenStore.toggleChecks(idx);
    }, [screenStore]);
    var handleSearch = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, doSearch(false)];
    }); }); }, [screenStore, doSearch]);
    var handleScan = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var last, e;
        return __generator(this, function (_a) {
            last = screenStore.lastSelectedIndex;
            e = (last != null) ? screenStore.entries[last] : null;
            if (last == null || !(e === null || e === void 0 ? void 0 : e.isChecked)) // この状態ならボタンを押させないが念のため
                return [2 /*return*/];
            history.push("/stocktaking-scan?loc=".concat(e.LocationCode, "&tana=").concat(e.TanaCode)); // TODO
            return [2 /*return*/];
        });
    }); }, [screenStore]);
    var handleClearList = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var result, locs, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    result = window.confirm("選択中のスキャンをクリアします。本当によろしいですか？");
                    if (!result)
                        return [2 /*return*/];
                    locs = screenStore.entries.filter(function (e) { return e.isChecked; }).map(extractLocTanaCode);
                    return [4 /*yield*/, api.tanaClear({
                            OpeCode: store.userStore.opeInfo.OpeCode,
                            OpeName: store.userStore.opeInfo.OpeName,
                            LocationList: locs
                        })];
                case 1:
                    res = _a.sent();
                    if (res.RtnMessage) {
                        alert(res.RtnMessage);
                    }
                    return [4 /*yield*/, handleSearch()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [store, screenStore, handleSearch]);
    var handleDownload = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var locs, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    locs = screenStore.entries.filter(function (e) { return e.isChecked; }).map(extractLocTanaCode);
                    return [4 /*yield*/, api.tanaFumeiCSV({
                            OpeCode: store.userStore.opeInfo.OpeCode,
                            OpeName: store.userStore.opeInfo.OpeName,
                            LocationList: locs
                        })];
                case 1:
                    res = _a.sent();
                    if (res.RtnMessage) {
                        alert(res.RtnMessage);
                        return [2 /*return*/];
                    }
                    download(new Blob([makeCSV(res.JsonCSV)]), "sozailist-".concat(formatDate(new Date()), ".csv"), "text/csv");
                    return [2 /*return*/];
            }
        });
    }); }, [store, screenStore]);
    var handleEnd = React.useCallback(function () { return history.go(-1); }, [history]);
    var dialog = React.createElement(HelpDialog, { onClose: dialogStore.setShowsFalse, acd: acd });
    return (React.createElement(React.Fragment, null,
        React.createElement(TanaoroshiTopScreen, { scrollData: screenStore.scrollData, acd: acd, entries: screenStore.entries, actionMode: screenStore.actionMode, locationInput: screenStore.locationInput, tanabanInput: screenStore.tanabanInput, checksAll: screenStore.isCheckedAll, onChangeLocationInput: screenStore.setLocationInput, onChangeTanabanInput: screenStore.setTanabanInput, onSelect: handleSelect, onCheck: screenStore.toggleChecks, onCheckAll: screenStore.toggleCheckAll, onClickHelp: dialogStore.changeShowsTrue, onClearList: handleClearList, onDownload: handleDownload, onSearch: handleSearch, onScan: handleScan, onEnd: handleEnd }),
        dialogStore.shows ? dialog : null));
});

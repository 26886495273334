// Based on: https://github.com/akashic-games/pdi-browser/tree/main/src/plugin/WebAudioPlugin
var AudioContext = window.AudioContext || window.webkitAudioContext;
var theContext = null;
;
export function getAudioContext() {
    if (!window.__sozaimgt__) {
        Object.defineProperty(window, "__sozaimgt__", {
            value: {},
            enumerable: false,
            configurable: false,
            writable: false
        });
    }
    if (!(theContext instanceof AudioContext)) {
        theContext = window.__sozaimgt__.audioContext = new AudioContext();
        _workAroundSafari();
    }
    return theContext;
}
export function createGainNode(context) {
    if (context.createGain) {
        return context.createGain();
    }
    return context.createGainNode();
}
export function createBufferNode(context) {
    var sourceNode = context.createBufferSource();
    if (!sourceNode.start) {
        sourceNode.start = sourceNode.noteOn;
        sourceNode.stop = sourceNode.noteOff;
    }
    return sourceNode;
}
export function _workAroundSafari() {
    document.addEventListener("touchstart", function touchInitializeHandler() {
        document.removeEventListener("touchstart", touchInitializeHandler);
        getAudioContext().createBufferSource().start(0);
    }, true);
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
var SakuhinListScreenStore = /** @class */ (function () {
    function SakuhinListScreenStore() {
        this.sakuhins = [];
        this.searchText = "";
    }
    SakuhinListScreenStore.prototype.setSakuhins = function (v) { this.sakuhins = v; };
    SakuhinListScreenStore.prototype.setSearchText = function (v) { this.searchText = v; };
    SakuhinListScreenStore.prototype.eraseSearchText = function () { this.searchText = ""; };
    __decorate([
        observable
    ], SakuhinListScreenStore.prototype, "sakuhins", void 0);
    __decorate([
        observable
    ], SakuhinListScreenStore.prototype, "searchText", void 0);
    __decorate([
        action.bound
    ], SakuhinListScreenStore.prototype, "setSakuhins", null);
    __decorate([
        action.bound
    ], SakuhinListScreenStore.prototype, "setSearchText", null);
    __decorate([
        action.bound
    ], SakuhinListScreenStore.prototype, "eraseSearchText", null);
    return SakuhinListScreenStore;
}());
export { SakuhinListScreenStore };

// Based on: https://github.com/akashic-games/pdi-browser/tree/main/src/plugin/WebAudioPlugin
import { getAudioContext, createGainNode } from "./audioContext";
// chrome66以降などのブラウザに導入されるAutoplay Policyに対応する
// https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
export function setupChromeMEIWorkaround() {
    var context = getAudioContext();
    if (context && typeof context.resume !== "function")
        return;
    var gain = createGainNode(context);
    // テスト用の音源を用意する
    var osc = context.createOscillator();
    osc.type = "sawtooth";
    osc.frequency.value = 440; // 何でも良い
    osc.connect(gain);
    osc.start(0);
    var contextState = context.state;
    osc.disconnect();
    if (contextState !== "running") {
        document.addEventListener("keydown", resumeHandler, true);
        document.addEventListener("mousedown", resumeHandler, true);
        document.addEventListener("touchend", resumeHandler, true);
    }
}
function resumeHandler() {
    var context = getAudioContext();
    context.resume();
    document.removeEventListener("keydown", resumeHandler);
    document.removeEventListener("mousedown", resumeHandler);
    document.removeEventListener("touchend", resumeHandler);
}

// export const tokuiCodeRegExp = /^CUC\w+$/;    // CUstomer Code
// export const sakuhinCodeRegExp = /^BUN\w+$/;  // BUNdling
// export const keijoCodeRegExp = /^SHA\w+$/;    // SHApe
// export const mediaCodeRegExp = /^MED\w+$/;    // MEDia
// export const tantoCodeRegExp = /^RES\w+$/;    // RESponsible
export var locationCodeRegExp = /^LOC\w+$/; // LOCaton
// export const statusCodeRegExp = /^STA\w+$/;   // STAtus
export var tanabanCodeRegExp = /^SHE\w+$/; // SHElf
export var locationCodeWithTanabanRegExp = /^LOC\d+%\d+$/;
export function isLocationCode(v) {
    return locationCodeRegExp.test(v);
}
export function isLocationCodeWithTanaban(v) {
    return locationCodeWithTanabanRegExp.test(v);
}
export function isTanaban(v) {
    return tanabanCodeRegExp.test(v);
}
var locationSplitRegExp = /^(LOC[^%]+)(?:%(.*))?$/;
export function parseLocationCode(v) {
    var _a;
    var match = v.match(locationSplitRegExp);
    if (!match)
        return null;
    return { LocationCode: match[1], TanaCode: (_a = match[2]) !== null && _a !== void 0 ? _a : null };
}

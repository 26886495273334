import * as React from "react";
import { X } from "react-feather";
import { observer } from "mobx-react";
// import { LabeledInputText } from "../common/LabeledInputText";
import * as styles from "./WarnDialog.module.scss";
function useOutsideClickEffect(ref, callback) {
    React.useEffect(function () {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}
export var WarnDialog = observer(function WarnDialog(props) {
    var dialogRef = React.useRef(null);
    useOutsideClickEffect(dialogRef, props.onClose);
    return (React.createElement("div", { className: styles["backdrop"] },
        React.createElement("div", { className: styles["dialog"] + " d-flex flex-column justify-content-center align-items-center", ref: dialogRef },
            React.createElement("div", { className: styles["dialog-close"], onClick: props.onClose },
                React.createElement(X, { size: 20 })),
            props.title ?
                React.createElement("div", { className: "mb-3" }, props.title) :
                React.createElement("div", null),
            React.createElement("div", null, props.children),
            React.createElement("div", { className: "mb-3" }))));
});

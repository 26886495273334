var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
var LoginScreenStore = /** @class */ (function () {
    function LoginScreenStore() {
        this.username = "";
        this.password = "";
    }
    LoginScreenStore.prototype.setUsername = function (v) {
        this.username = v;
    };
    LoginScreenStore.prototype.setPassword = function (v) {
        this.password = v;
    };
    __decorate([
        observable
    ], LoginScreenStore.prototype, "username", void 0);
    __decorate([
        observable
    ], LoginScreenStore.prototype, "password", void 0);
    __decorate([
        action.bound
    ], LoginScreenStore.prototype, "setUsername", null);
    __decorate([
        action.bound
    ], LoginScreenStore.prototype, "setPassword", null);
    return LoginScreenStore;
}());
export { LoginScreenStore };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import { Navbar } from "../common/Navbar";
import { MeigiChangeScreen } from "../meigi/MeigiChangeScreen";
import { WarnDialog } from "../common/WarnDialog";
import * as api from "../../api/apiClient";
import { makeTokuiSuggestionProps } from "./completeUtil/makeTokuiSuggestionProps";
function doNothing() { }
export var MeigiChangeScreenRoot = observer(function MeigiChangeScreenRoot() {
    var _this = this;
    var store = React.useContext(StoreContext);
    var history = useHistory();
    var screenStore = store.meigiChangeScreenStore;
    var location = useLocation();
    var acd = store.userStore.appAuthTable["meigi-change"];
    React.useEffect(function () {
        if (!acd.view) {
            alert("画面を表示する権限がありません");
            history.go(-1);
            return;
        }
        screenStore.resetInputs();
        screenStore.setEntries([]);
        screenStore.setIsSubmitted(false);
        // クエリパラメータ ino があればそれを追加
        // TODO 大量の INo を全選択で一括指定されて動くか？　動かないなら別経路で渡さないとダメ？
        var query = new URLSearchParams(location.search);
        var paramINos = query.getAll("ino");
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var _i, paramINos_1, INo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _i = 0, paramINos_1 = paramINos;
                        _a.label = 1;
                    case 1:
                        if (!(_i < paramINos_1.length)) return [3 /*break*/, 4];
                        INo = paramINos_1[_i];
                        return [4 /*yield*/, processINo(INo)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        }); })();
    }, [screenStore, location.search, acd]);
    var handleEnd = React.useCallback(function () {
        if ((!screenStore.isSubmitted) && (screenStore.entries.length)) {
            var result = window.confirm("表示中の素材は未更新です。終了してもよろしいですか？");
            if (!result)
                return;
        }
        history.go(-1);
    }, [history, screenStore]);
    var handleClear = React.useCallback(function () {
        screenStore.setEntries([]);
        screenStore.setIsSubmitted(false);
    }, [screenStore]);
    var processINo = React.useCallback(function (INo) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (screenStore.entries.some(function (e) { return e.INo === INo; })) {
                        // alert("既に追加されています");
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, api.getSozai({ INo: INo })];
                case 1:
                    res = _a.sent();
                    if (res.RtnMessage) {
                        if (res.RtnCode === "OK") {
                            alert(res.RtnMessage);
                        }
                        else {
                            screenStore.setWarnMessage(res.RtnMessage);
                            screenStore.setShowsWarnDialogTrue();
                        }
                    }
                    if (res.RtnCode !== "OK") {
                        return [2 /*return*/];
                    }
                    if (screenStore.isSubmitted) {
                        screenStore.setEntries([]);
                        screenStore.setIsSubmitted(false);
                    }
                    screenStore.addEntry(__assign({}, res)); // TODO? 必要な値だけ使ってclone
                    return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    var checkINoInput = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var INo;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    INo = screenStore.INo;
                    if (!(INo !== "")) return [3 /*break*/, 2];
                    screenStore.setINo("");
                    return [4 /*yield*/, processINo(INo)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    var handleEnter = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            checkINoInput();
            return [2 /*return*/];
        });
    }); }, [checkINoInput]);
    var handleSubmit = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (screenStore.entries.length === 0) {
                        screenStore.setWarnMessage("更新内容がありません");
                        screenStore.setShowsWarnDialogTrue();
                        return [2 /*return*/];
                    }
                    if (!screenStore.isTokuiValid) {
                        screenStore.setWarnMessage("得意先が正しく指定されていません");
                        screenStore.setShowsWarnDialogTrue();
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, api.changeMeigis({
                            UserCode: store.userStore.opeInfo.OpeCode,
                            DataList: screenStore.entries.map(function (e) { return ({
                                INo: e.INo,
                                Nohinsyo: screenStore.nohinsyo,
                                TokuiSaki: screenStore.sozaiDetail.TokuiCode
                            }); })
                        })];
                case 1:
                    res = _a.sent();
                    if (res.RtnCode === "OK") {
                        alert(res.RtnMessage || "更新しました");
                    }
                    else {
                        if (res.RtnMessage) {
                            screenStore.setWarnMessage(res.RtnMessage);
                            screenStore.setShowsWarnDialogTrue();
                        }
                    }
                    screenStore.setIsSubmitted(true);
                    screenStore.resetInputs();
                    return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    var dialog = React.createElement(WarnDialog, { onClose: screenStore.setShowsWarnDialogFalse }, screenStore.warnDialogMessages.map(function (message) {
        return React.createElement("div", null,
            React.createElement("code", null, message));
    }));
    return (React.createElement(React.Fragment, null,
        React.createElement(Navbar, { name: "\u5F97\u610F\u5148\u540D\u7FA9\u5909\u66F4" }),
        React.createElement(MeigiChangeScreen, { acd: acd, INo: screenStore.INo, nohinsyo: screenStore.nohinsyo, completePropsTokui: makeTokuiSuggestionProps(screenStore), entries: screenStore.entries, isSubmitted: screenStore.isSubmitted, onSubmit: handleSubmit, onClear: handleClear, onEnd: handleEnd, onChangeINo: screenStore.setINo, onChangeNohinsyo: screenStore.setNohinsyo, onBlurINo: checkINoInput, onBlurNohinsyo: doNothing, onEnter: handleEnter, onAddLine: checkINoInput, onRemoveLine: screenStore.removeEntry }),
        screenStore.isWarnDialogShown ? dialog : null));
});

var Emitter = /** @class */ (function () {
    function Emitter() {
        this.handlers = new Set();
    }
    Emitter.prototype.add = function (handler) {
        this.handlers.add(handler);
    };
    Emitter.prototype.remove = function (handler) {
        this.handlers.delete(handler);
    };
    Emitter.prototype.removeAll = function () {
        this.handlers.clear();
    };
    Emitter.prototype.emit = function (value) {
        this.handlers.forEach(function (handler) { return handler(value); });
    };
    return Emitter;
}());
export { Emitter };
;

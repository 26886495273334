import * as React from "react";
import { useHistory } from "react-router-dom";
import { setAPIToken } from "../../../api/apiCommons";
import { StoreContext } from "../../../context";
export function useLogout() {
    var store = React.useContext(StoreContext);
    var history = useHistory();
    return React.useCallback(function () {
        store.userStore.resetOpeInfo();
        store.userStore.setLoginType(null);
        setAPIToken(null);
        history.push("/");
    }, [store, history]);
}

import * as React from "react";
import { observer } from "mobx-react";
import * as styles from "./SozaiTable.module.scss";
export var SozaiTable = observer(function (props) {
    var entries = props.entries, onRemoveLine = props.onRemoveLine, highlightsLocationCol = props.highlightsLocationCol, isResultDisplay = props.isResultDisplay;
    return React.createElement("div", { className: "table-responsive-lg" },
        React.createElement("table", { className: "table table-bordered table-striped table-hover " + styles["sozai-table"] },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: styles["no-minw"] }),
                    React.createElement("th", null, "\uFF29\uFF2D\uFF21\uFF27\uFF29\uFF23\uFF21\u756A\u53F7"),
                    React.createElement("th", null, "\u5F97\u610F\u5148"),
                    React.createElement("th", null, "\u984C\u540D"),
                    React.createElement("th", null, "\u526F\u984C"),
                    React.createElement("th", null, "\u5F62\u72B6"),
                    React.createElement("th", null, "\u62C5\u5F53\u8005"),
                    React.createElement("th", null, "\u7279\u8A18\u4E8B\u9805"),
                    React.createElement("th", null, "\u30ED\u30B1\u30FC\u30B7\u30E7\u30F3"))),
            React.createElement("tbody", null, entries.map(function (e, i) {
                return React.createElement("tr", { key: "".concat(e.INo) },
                    isResultDisplay ?
                        React.createElement("td", { className: styles["no-minw"] },
                            React.createElement("button", { type: "button", className: "close", "aria-label": "Remove line", style: { visibility: "hidden" } },
                                React.createElement("span", { "aria-hidden": "true" }, "\u00D7"))) :
                        React.createElement("td", { className: styles["no-minw"] },
                            React.createElement("button", { type: "button", className: "close", "aria-label": "Remove line", onClick: function () { return onRemoveLine(i); } },
                                React.createElement("span", { "aria-hidden": "true" }, "\u00D7"))),
                    React.createElement("td", null, e.INo),
                    React.createElement("td", null, e.TokuiName),
                    React.createElement("td", null, e.Daimei),
                    React.createElement("td", null, e.Fukudai),
                    React.createElement("td", null, e.KeijoName),
                    React.createElement("td", null, e.TantoName),
                    React.createElement("td", { className: highlightsLocationCol ? "text-success" : "" }, e.TokiJiko),
                    React.createElement("td", { className: highlightsLocationCol ? "text-success" : "" },
                        e.LocationName,
                        " (\u68DA\u756A ",
                        e.Tanaban,
                        ")"));
            }))));
});

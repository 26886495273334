var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import download from "downloadjs";
import * as api from "../../api/apiClient";
import { StoreContext } from "../../context";
import { makeCSV } from "../../util/makeCSV";
import { formatDate } from "../../util/dateUtil";
import { kensakuJokenSetDataOfKensakuJoken } from "../../types/KensakuJoken";
import { SozaiKensakuScreen } from "../sozai/SozaiKensakuScreen";
import { MultiChangeDialog } from "../sozai/MultiChangeDialog";
import { useNestCount } from "../common/hooks/useNestCount";
import { makeTokuiSuggestionProps } from "./completeUtil/makeTokuiSuggestionProps";
import { makeKyuSuggestionProps } from "./completeUtil/makeKyuSuggestionProps";
import { makeSakuhinSuggestionProps } from "./completeUtil/makeSakuhinSuggestionProps";
import { makeTantoSuggestionProps } from "./completeUtil/makeTantoSuggestionProps";
export var SozaiKensakuScreenRoot = observer(function SozaiKensakuScreenRoot() {
    var _this = this;
    var store = React.useContext(StoreContext);
    var history = useHistory();
    var screenStore = store.sozaiKensakuScreen;
    var dialogStore = store.multiChangeDialogStore;
    var _a = React.useState(null), tableDom = _a[0], setTableDom = _a[1];
    var tableRef = React.useCallback(function (elem) {
        setTableDom(elem);
        // スクロール復元: 定石の実装方法はなさそうだ。DOMから抜いた scrollTop を強引に差し込んで復元する
        if (elem && screenStore.scrollTop != null) {
            elem.scrollTop = screenStore.scrollTop;
            screenStore.assignScrollTop(null); // 復元したらクリア
        }
    }, [screenStore, setTableDom]);
    var acd = store.userStore.appAuthTable["sozai-search"];
    var sozaiNewAcd = store.userStore.appAuthTable["sozai-new"];
    var sozaiHistoryAcd = store.userStore.appAuthTable["sozai-history"];
    var locationChangeAcd = store.userStore.appAuthTable["location-change"];
    var nohinAcd = store.userStore.appAuthTable["nohin"];
    var discardAcd = store.userStore.appAuthTable["discard"];
    var meigiChangeAcd = store.userStore.appAuthTable["meigi-change"];
    var _b = useNestCount(), loadingCount = _b[0], withLoading = _b[1];
    var handleModifyJoken = React.useCallback(screenStore.setKensakuJokenValue, [screenStore]);
    var doSearch = React.useCallback(withLoading(function (preserveSortOrder) { return __awaiter(_this, void 0, void 0, function () {
        var res, res2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.setKensakuJoken({
                        OpeCode: store.userStore.opeInfo.OpeCode,
                        DataList: screenStore.kensakuJokens.map(kensakuJokenSetDataOfKensakuJoken)
                    })];
                case 1:
                    res = _a.sent();
                    if (res.RtnCode !== "OK") {
                        alert(res.RtnMessage);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, api.searchSozaiAll_First({
                            OpeCode: store.userStore.opeInfo.OpeCode,
                            withHitCount: screenStore.showsHitCount
                        })];
                case 2:
                    res2 = _a.sent();
                    if (res2.RtnCode !== "OK") {
                        alert(res2.RtnMessage);
                        return [2 /*return*/];
                    }
                    screenStore.setIsInitial(false);
                    screenStore.setHitCount(res2.HitCNT);
                    screenStore.changeSearchResults(res2.DataList, preserveSortOrder);
                    screenStore.setNextData(res2.NextData);
                    screenStore.assignSearchParams(1, "0", 0);
                    return [2 /*return*/];
            }
        });
    }); }), [store, screenStore]);
    var doSearchOnBack = React.useCallback(withLoading(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, res2, nextData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!screenStore.publicState.isDirty)
                        return [2 /*return*/];
                    return [4 /*yield*/, api.setKensakuJoken({
                            OpeCode: store.userStore.opeInfo.OpeCode,
                            DataList: screenStore.kensakuJokens.map(kensakuJokenSetDataOfKensakuJoken)
                        })];
                case 1:
                    res = _a.sent();
                    if (res.RtnCode !== "OK") {
                        alert(res.RtnMessage);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, api.searchSozaiAll_Next({
                            OpeCode: store.userStore.opeInfo.OpeCode,
                            Direction: screenStore.searchParamDirection,
                            NextINo: screenStore.searchParamNextINo,
                            NextLine: screenStore.searchParamNextLine
                        })];
                case 2:
                    res2 = _a.sent();
                    if (res2.RtnCode !== "OK") {
                        alert(res2.RtnMessage);
                        return [2 /*return*/];
                    }
                    screenStore.changeSearchResults(res2.DataList, true);
                    nextData = (screenStore.searchParamDirection === 1) ? res2.NextData : "1";
                    screenStore.setNextData(nextData);
                    return [2 /*return*/];
            }
        });
    }); }), [store, screenStore]);
    var handleSearch = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            doSearch(false);
            return [2 /*return*/];
        });
    }); }, [store, screenStore]);
    var handleSelect = React.useCallback(function (idx) {
        if (!screenStore.searchResults || !screenStore.searchResults[idx]) {
            // この条件ではここに来ないはずだが念のため
            return;
        }
        // スクロール復元: 定石の実装方法はなさそうだ。DOMから無理やり scrollTop を抜いて記憶しておく
        if (tableDom) {
            screenStore.assignScrollTop(tableDom.scrollTop);
        }
        history.push("/sozai/".concat(encodeURIComponent(screenStore.searchResults[idx].LineINo)));
    }, [screenStore, history, tableDom]);
    var handleClickSort = React.useCallback(function (columnIndex) {
        if (screenStore.sortColumnIndex !== columnIndex) {
            // これまでと違う列が選択された
            screenStore.changeSortOrder(columnIndex, "asc");
        }
        else {
            var order = screenStore.sortOrder;
            screenStore.changeSortOrder(columnIndex, (order === "desc") ? "asc" : "desc");
        }
    }, [screenStore, history]);
    var handleSearchSetting = React.useCallback(function () { return history.push("/sozai-search-cond"); }, [history]);
    var handleEnd = React.useCallback(function () {
        screenStore.setIsInitial(true);
        history.go(-1);
    }, [history]);
    var handleClear = React.useCallback(withLoading(function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    screenStore.reset();
                    return [4 /*yield*/, api.setKensakuJoken({
                            OpeCode: store.userStore.opeInfo.OpeCode,
                            DataList: screenStore.kensakuJokens.map(kensakuJokenSetDataOfKensakuJoken)
                        })];
                case 1:
                    res = _a.sent();
                    if (res.RtnCode !== "OK") {
                        alert(res.RtnMessage);
                        return [2 /*return*/];
                    }
                    return [2 /*return*/];
            }
        });
    }); }), [screenStore]);
    var handlePrev = React.useCallback(withLoading(function () { return __awaiter(_this, void 0, void 0, function () {
        var maxDspCNT, firstResult, res2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!screenStore.searchResults || !screenStore.searchResults.length) {
                        // この条件ではここに来ないはずだが念のため
                        return [2 /*return*/];
                    }
                    maxDspCNT = screenStore.maxDspCNT;
                    screenStore.setStartNo(Math.max(0, screenStore.startNo - (maxDspCNT > 0 ? maxDspCNT : 10)));
                    firstResult = screenStore.searchResults[0];
                    return [4 /*yield*/, api.searchSozaiAll_Next({
                            OpeCode: store.userStore.opeInfo.OpeCode,
                            Direction: -1,
                            NextINo: firstResult.LineINo,
                            NextLine: firstResult.LineCNT
                        })];
                case 1:
                    res2 = _a.sent();
                    if (res2.RtnCode !== "OK") {
                        alert(res2.RtnMessage);
                        return [2 /*return*/];
                    }
                    if (tableDom) {
                        tableDom.scrollTop = 0;
                    }
                    screenStore.assignScrollTop(0);
                    screenStore.changeSearchResults(res2.DataList, false);
                    // screenStore.setNextData(res2.NextData);
                    screenStore.setNextData("1"); // 「前へ」ボタンを押した時、「次へ」は必ず有効
                    screenStore.assignSearchParams(-1, firstResult.LineINo, firstResult.LineCNT);
                    return [2 /*return*/];
            }
        });
    }); }), [store, screenStore, tableDom]);
    var handleNext = React.useCallback(withLoading(function () { return __awaiter(_this, void 0, void 0, function () {
        var lastResult, res2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!screenStore.searchResults || !screenStore.searchResults.length) {
                        // この条件ではここに来ないはずだが念のため
                        return [2 /*return*/];
                    }
                    lastResult = screenStore.searchResults[screenStore.searchResults.length - 1];
                    screenStore.setStartNo(screenStore.startNo + screenStore.searchResults.length);
                    return [4 /*yield*/, api.searchSozaiAll_Next({
                            OpeCode: store.userStore.opeInfo.OpeCode,
                            Direction: 1,
                            NextINo: lastResult.LineINo,
                            NextLine: lastResult.LineCNT
                        })];
                case 1:
                    res2 = _a.sent();
                    if (res2.RtnCode !== "OK") {
                        alert(res2.RtnMessage);
                        return [2 /*return*/];
                    }
                    if (tableDom) {
                        tableDom.scrollTop = 0;
                    }
                    screenStore.assignScrollTop(0);
                    screenStore.changeSearchResults(res2.DataList, false);
                    screenStore.setNextData(res2.NextData);
                    screenStore.assignSearchParams(1, lastResult.LineINo, lastResult.LineCNT);
                    return [2 /*return*/];
            }
        });
    }); }), [store, screenStore, tableDom]);
    var handleDownload = React.useCallback(withLoading(function () { return __awaiter(_this, void 0, void 0, function () {
        var searchResults, INoList, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    searchResults = screenStore.searchResults;
                    if (!searchResults || searchResults.length === 0) {
                        alert("素材が選択されていません");
                        return [2 /*return*/];
                    }
                    INoList = searchResults.filter(function (e) { return e.isChecked; }).map(function (e) { return e.LineINo; });
                    return [4 /*yield*/, api.downloadCSV({
                            OpeCode: store.userStore.opeInfo.OpeCode,
                            OpeName: store.userStore.opeInfo.OpeName,
                            INoList: INoList
                        })];
                case 1:
                    res = _a.sent();
                    if (res.RtnCode !== "OK") {
                        alert(res.RtnMessage);
                        return [2 /*return*/];
                    }
                    download(new Blob([makeCSV(res.JsonCSV)]), "sozailist-".concat(formatDate(new Date()), ".csv"), "text/csv");
                    return [2 /*return*/];
            }
        });
    }); }), []);
    React.useEffect(function () {
        if (!acd.view) {
            alert("画面を表示する権限がありません");
            history.go(-1);
            return;
        }
        if (screenStore.searchResults != null) {
            doSearchOnBack();
            return;
        }
        (withLoading(function () { return __awaiter(_this, void 0, void 0, function () {
            var resKensakuJoken, resHyoujiJoken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        screenStore.clearSearch();
                        return [4 /*yield*/, api.getKensakuJoken({ OpeCode: store.userStore.opeInfo.OpeCode })];
                    case 1:
                        resKensakuJoken = _a.sent();
                        if (resKensakuJoken.RtnCode !== "OK") {
                            alert(resKensakuJoken.RtnMessage);
                            return [2 /*return*/];
                        }
                        screenStore.setMaxDspCNT(resKensakuJoken.MaxDspCNT);
                        screenStore.setKensakuJokens(resKensakuJoken.DataList);
                        return [4 /*yield*/, api.getHyoujiJoken({ OpeCode: store.userStore.opeInfo.OpeCode })];
                    case 2:
                        resHyoujiJoken = _a.sent();
                        if (resHyoujiJoken.RtnCode !== "OK") {
                            alert(resHyoujiJoken.RtnMessage);
                            return [2 /*return*/];
                        }
                        screenStore.setShowsHitCount(resHyoujiJoken.KensuDisp !== "0");
                        return [2 /*return*/];
                }
            });
        }); }))();
    }, [store, screenStore, acd]); // StartNo を含めないことに注意 (検索ページ移動だけでは再実行しない)
    var handleCheckAll = React.useCallback(function () {
        screenStore.changeChecksAll(!screenStore.checksAll);
    }, [screenStore]);
    var handleCheck = React.useCallback(function (idx) {
        screenStore.toggleChecked(idx);
    }, [screenStore]);
    var collectCheckedINos = React.useCallback(function () {
        var INoList = screenStore.checkedINos;
        if (!INoList || INoList.length === 0) {
            // ボタンを disable しているのでこないはずだが念のため
            alert("選択されている素材がありません");
            return null;
        }
        return INoList;
    }, [screenStore]);
    var handleClickMultiLocationChange = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var INoList, param;
        return __generator(this, function (_a) {
            INoList = collectCheckedINos();
            if (!INoList)
                return [2 /*return*/];
            param = INoList.map(function (INo) { return "ino=".concat(encodeURIComponent(INo)); }).join("&");
            history.push("/location-change?".concat(param));
            return [2 /*return*/];
        });
    }); }, [history, collectCheckedINos]);
    var handleClickMultiNohin = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var INoList, param;
        return __generator(this, function (_a) {
            INoList = collectCheckedINos();
            if (!INoList)
                return [2 /*return*/];
            param = INoList.map(function (INo) { return "ino=".concat(encodeURIComponent(INo)); }).join("&");
            history.push("/nohin?".concat(param));
            return [2 /*return*/];
        });
    }); }, [history, collectCheckedINos]);
    var handleClickMultiDiscard = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var INoList, param;
        return __generator(this, function (_a) {
            INoList = collectCheckedINos();
            if (!INoList)
                return [2 /*return*/];
            param = INoList.map(function (INo) { return "ino=".concat(encodeURIComponent(INo)); }).join("&");
            history.push("/discard?".concat(param));
            return [2 /*return*/];
        });
    }); }, [history, collectCheckedINos]);
    var handleClickMultiMeigiChange = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var INoList, param;
        return __generator(this, function (_a) {
            INoList = collectCheckedINos();
            if (!INoList)
                return [2 /*return*/];
            param = INoList.map(function (INo) { return "ino=".concat(encodeURIComponent(INo)); }).join("&");
            history.push("/meigi-change?".concat(param));
            return [2 /*return*/];
        });
    }); }, [history, collectCheckedINos]);
    var handleSubmitMultiChange = React.useCallback(withLoading(function () { return __awaiter(_this, void 0, void 0, function () {
        var searchResults, sozaiDetail, INoList, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    searchResults = screenStore.searchResults;
                    if (!searchResults || searchResults.length === 0) {
                        alert("素材が選択されていません");
                        return [2 /*return*/];
                    }
                    sozaiDetail = dialogStore.sozaiDetail;
                    INoList = searchResults.filter(function (e) { return e.isChecked; }).map(function (e) { return e.LineINo; });
                    return [4 /*yield*/, api.multiChangeSozai({
                            OpeCode: store.userStore.opeInfo.OpeCode,
                            OpeName: store.userStore.opeInfo.OpeName,
                            INoList: INoList,
                            TokuiCode: dialogStore.isTokuiValid ? sozaiDetail.TokuiCode : "",
                            TokuiName: dialogStore.isTokuiValid ? sozaiDetail.TokuiName : "",
                            KyuCode: dialogStore.isKyuValid ? sozaiDetail.KyuCode : "",
                            KyuName: dialogStore.isKyuValid ? sozaiDetail.KyuName : "",
                            SakuhinCode: dialogStore.isSakuhinValid ? sozaiDetail.SakuhinCode : "",
                            SakuhinName: dialogStore.isSakuhinValid ? sozaiDetail.SakuhinName : "",
                            TantoCode: dialogStore.isTantoValid ? sozaiDetail.TantoCode : "",
                            TantoName: dialogStore.isTantoValid ? sozaiDetail.TantoName : "",
                            Daimei: sozaiDetail.Daimei,
                            Fukudai: sozaiDetail.Fukudai
                        })];
                case 1:
                    res = _a.sent();
                    alert(res.RtnMessage || (res.RtnCode === "OK") ? "更新しました" : "不明なエラーが発生しました");
                    if (screenStore.searchResults != null) {
                        handleSearch();
                    }
                    dialogStore.setShowsFalse();
                    return [2 /*return*/];
            }
        });
    }); }), [store, screenStore, dialogStore]);
    var dialog = React.createElement(MultiChangeDialog, { onClose: dialogStore.setShowsFalse, onSubmit: handleSubmitMultiChange, onModify: dialogStore.setSozaiDetail, sozaiDetail: dialogStore.sozaiDetail, completePropsTokui: makeTokuiSuggestionProps(dialogStore), completePropsKyu: makeKyuSuggestionProps(dialogStore), completePropsSakuhin: makeSakuhinSuggestionProps(dialogStore), completePropsTanto: makeTantoSuggestionProps(dialogStore) });
    var handleNewCopy = React.useCallback(function (idx) {
        if (!screenStore.searchResults || !screenStore.searchResults[idx]) {
            // この条件ではここに来ないはずだが念のため
            return;
        }
        // TODO URLSearchParams() と encodeURIComponent() をどちらかに揃える？
        var params = new URLSearchParams();
        params.append("from", screenStore.searchResults[idx].LineINo);
        history.push("/sozai-new?".concat(params.toString()));
    }, [screenStore, history]);
    var handleShowHistory = React.useCallback(function (idx) {
        if (!screenStore.searchResults || !screenStore.searchResults[idx]) {
            // この条件ではここに来ないはずだが念のため
            return;
        }
        history.push("/sozai-history?ino=".concat(encodeURIComponent(screenStore.searchResults[idx].LineINo)));
    }, [screenStore, history]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SozaiKensakuScreen, { isLoading: loadingCount > 0, tableRef: tableRef, acd: acd, sozaiNewAcd: sozaiNewAcd, sozaiHistoryAcd: sozaiHistoryAcd, locationChangeAcd: locationChangeAcd, nohinAcd: nohinAcd, discardAcd: discardAcd, meigiChangeAcd: meigiChangeAcd, kensakuJokens: screenStore.kensakuJokens, startNo: screenStore.startNo, nextData: screenStore.nextData, showsHitCount: screenStore.showsHitCount, hitCount: screenStore.hitCount, searchResults: screenStore.searchResults, checksAll: screenStore.checksAll, hasCheckedItem: screenStore.hasCheckedItem, isInitial: screenStore.isInitial, sortColumnIndex: screenStore.sortColumnIndex, sortOrder: screenStore.sortOrder, onModifyJoken: handleModifyJoken, onSearch: handleSearch, onSelect: handleSelect, onClickSort: handleClickSort, onCheckAll: handleCheckAll, onCheck: handleCheck, onClickMultiEdit: dialogStore.changeShowsTrue, onClickMultiLocationChange: handleClickMultiLocationChange, onClickMultiNohin: handleClickMultiNohin, onClickMultiDiscard: handleClickMultiDiscard, onClickMultiMeigiChange: handleClickMultiMeigiChange, onSearchSetting: handleSearchSetting, onClear: handleClear, onPrev: handlePrev, onNext: handleNext, onDownload: handleDownload, onEnd: handleEnd, onNewCopy: handleNewCopy, onShowHistory: handleShowHistory }),
        dialogStore.shows ? dialog : null));
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
/**
 * SozaiKensakuScreenStore の中でも特に他の画面から操作されるもの。
 */
var SozaiKensakuScreenPublicState = /** @class */ (function () {
    function SozaiKensakuScreenPublicState() {
        /**
         * 「戻る」「進む」でこのページにきた時、再検索して検索結果を更新する必要があるか否か。
         * 常に再検索しているとローディングが長すぎて辛いので、素材編集画面で (自分が) 素材を更新した時などにこのフラグを立てて、
         * その時にだけ再検索する。
         *
         * 暫定措置。本気でやると他の画面 (棚卸など) の操作も反映せねばならないかもしれない。
         */
        this.isDirty = false;
    }
    SozaiKensakuScreenPublicState.prototype.setIsDirtyTrue = function () { this.isDirty = true; };
    __decorate([
        observable
    ], SozaiKensakuScreenPublicState.prototype, "isDirty", void 0);
    __decorate([
        action.bound
    ], SozaiKensakuScreenPublicState.prototype, "setIsDirtyTrue", null);
    return SozaiKensakuScreenPublicState;
}());
export { SozaiKensakuScreenPublicState };
var SozaiKensakuScreenStore = /** @class */ (function () {
    function SozaiKensakuScreenStore() {
        // 戻ってきた時に検索検索結果を復元するためのパラメータ。これ起因で表示が変わることはありえないので observable にはしない。
        // 普通に考えたらページ指定 startNo だけで引けてほしいが、なぜかそうなってはいない。
        this.searchParamDirection = 1;
        this.searchParamNextINo = "0";
        this.searchParamNextLine = 0;
        // 戻ってきた時にスクロール一を復元するための値。これ起因で表示が変わることはあり得ないので observable にいはしない。
        this._scrollTop = null;
        this.publicState = new SozaiKensakuScreenPublicState();
        this.showsHitCount = false;
        this.maxDspCNT = 0;
        this.kensakuJokens = [];
        this.hitCount = 0;
        this.startNo = 0;
        this.nextData = "0";
        this.searchResults = null;
        this.checksAll = false;
        this.hasCheckedItem = false;
        this.isInitial = true;
        this.sortColumnIndex = null;
        this.sortOrder = null;
    }
    Object.defineProperty(SozaiKensakuScreenStore.prototype, "scrollTop", {
        get: function () {
            return this._scrollTop;
        },
        enumerable: false,
        configurable: true
    });
    SozaiKensakuScreenStore.prototype.reset = function () {
        this.setIsInitial(true);
        this.resetAllKensakuJokenValues();
        this.clearSearch();
    };
    SozaiKensakuScreenStore.prototype.assignSearchParams = function (direction, nextINo, nextLine) {
        this.searchParamDirection = direction;
        this.searchParamNextINo = nextINo;
        this.searchParamNextLine = nextLine;
    };
    SozaiKensakuScreenStore.prototype.assignScrollTop = function (scrollTop) {
        this._scrollTop = scrollTop;
    };
    Object.defineProperty(SozaiKensakuScreenStore.prototype, "checkedINos", {
        get: function () {
            return this.searchResults ? this.searchResults.filter(function (e) { return e.isChecked; }).map(function (e) { return e.LineINo; }) : null;
        },
        enumerable: false,
        configurable: true
    });
    SozaiKensakuScreenStore.prototype.setShowsHitCount = function (v) { this.showsHitCount = v; };
    SozaiKensakuScreenStore.prototype.setMaxDspCNT = function (v) { this.maxDspCNT = v; };
    SozaiKensakuScreenStore.prototype.setKensakuJokens = function (v) { this.kensakuJokens = v; };
    SozaiKensakuScreenStore.prototype.setKensakuJokenValue = function (idx, v) { this.kensakuJokens[idx].FilterValue = v; };
    SozaiKensakuScreenStore.prototype.resetAllKensakuJokenValues = function () { this.kensakuJokens.forEach(function (j) { return j.FilterValue = ""; }); };
    SozaiKensakuScreenStore.prototype.setHitCount = function (v) { this.hitCount = v; };
    SozaiKensakuScreenStore.prototype.setStartNo = function (v) { this.startNo = v; };
    SozaiKensakuScreenStore.prototype.setNextData = function (v) { this.nextData = v; };
    SozaiKensakuScreenStore.prototype.changeSearchResults = function (v, preservesSort) {
        if (preservesSort === void 0) { preservesSort = false; }
        if (v) {
            if (preservesSort) {
                var checkTable_1 = (this.searchResults || []).reduce(function (acc, e) {
                    acc[e.LineINo] = e.isChecked;
                    return acc;
                }, Object.create(null));
                // this.searchResults = v.map(lineData => ({ ...lineData, isChecked: !!checkTable[lineData.LineINo] }));
                var searchResults_1 = [];
                var checksAll_1 = true;
                var hasCheckedItem_1 = false;
                v.forEach(function (lineData) {
                    var isChecked = !!checkTable_1[lineData.LineINo];
                    searchResults_1.push(__assign(__assign({}, lineData), { isChecked: isChecked }));
                    if (isChecked) {
                        if (!hasCheckedItem_1)
                            hasCheckedItem_1 = true;
                    }
                    else {
                        if (checksAll_1)
                            checksAll_1 = false;
                    }
                });
                this.searchResults = searchResults_1;
                this.checksAll = checksAll_1;
                this.hasCheckedItem = hasCheckedItem_1;
            }
            else {
                this.searchResults = v.map(function (lineData) { return (__assign(__assign({}, lineData), { isChecked: false })); });
                this.checksAll = false;
                this.hasCheckedItem = false;
            }
        }
        else {
            this.searchResults = v;
            this.checksAll = false;
            this.hasCheckedItem = false;
        }
        this.setPublicIsDirty(false);
        if (preservesSort && this.sortColumnIndex != null && this.sortOrder != null) {
            this.changeSortOrder(this.sortColumnIndex, this.sortOrder);
        }
        else {
            this.setSortColumnIndex(null);
            this.setSortOrder(null);
        }
    };
    SozaiKensakuScreenStore.prototype.changeSortOrder = function (columnIndex, order) {
        var _a;
        if (!this.searchResults)
            return;
        this.setSortColumnIndex(columnIndex);
        this.setSortOrder(order);
        var lt = function (a, b) {
            var av = a.LineBody[columnIndex], bv = b.LineBody[columnIndex];
            return (av > bv) ? 1 : (av < bv) ? -1 : 0;
        };
        var comp = (order === "asc") ? lt : function (a, b) { return lt(b, a); };
        this.searchResults.replace((_a = this.searchResults) === null || _a === void 0 ? void 0 : _a.slice().sort(comp));
    };
    SozaiKensakuScreenStore.prototype.changeChecksAll = function (v) {
        var _a;
        if (this.checksAll === v)
            return;
        this.checksAll = v;
        this.hasCheckedItem = v;
        (_a = this.searchResults) === null || _a === void 0 ? void 0 : _a.forEach(function (e) { return e.isChecked = v; });
    };
    SozaiKensakuScreenStore.prototype.toggleChecked = function (idx) {
        var nextVal = !this.searchResults[idx].isChecked;
        this.searchResults[idx].isChecked = nextVal;
        // 今新たにチェックをつけたなら hasCheckedItem は確実に真だが、今チェックを外したなら全体を数える必要がある
        // 最適化を詰めるならチェックされてるものの数をカウントしていくべきだが、まあ誤差だろう。
        this.hasCheckedItem = nextVal ? true : this.searchResults.some(function (e) { return e.isChecked; });
    };
    SozaiKensakuScreenStore.prototype.clearSearch = function () {
        this.changeSearchResults(null);
        this.setStartNo(0);
        this.setNextData("0");
        this.setSortColumnIndex(null);
        this.setSortOrder(null);
    };
    SozaiKensakuScreenStore.prototype.setSortColumnIndex = function (v) { this.sortColumnIndex = v; };
    SozaiKensakuScreenStore.prototype.setSortOrder = function (v) { this.sortOrder = v; };
    SozaiKensakuScreenStore.prototype.setIsInitial = function (v) { this.isInitial = v; };
    SozaiKensakuScreenStore.prototype.setPublicIsDirty = function (v) { this.publicState.isDirty = v; };
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "showsHitCount", void 0);
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "maxDspCNT", void 0);
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "kensakuJokens", void 0);
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "hitCount", void 0);
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "startNo", void 0);
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "nextData", void 0);
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "searchResults", void 0);
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "checksAll", void 0);
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "hasCheckedItem", void 0);
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "isInitial", void 0);
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "sortColumnIndex", void 0);
    __decorate([
        observable
    ], SozaiKensakuScreenStore.prototype, "sortOrder", void 0);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "reset", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "setShowsHitCount", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "setMaxDspCNT", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "setKensakuJokens", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "setKensakuJokenValue", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "resetAllKensakuJokenValues", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "setHitCount", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "setStartNo", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "setNextData", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "changeSearchResults", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "changeSortOrder", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "changeChecksAll", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "toggleChecked", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "clearSearch", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "setSortColumnIndex", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "setSortOrder", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "setIsInitial", null);
    __decorate([
        action.bound
    ], SozaiKensakuScreenStore.prototype, "setPublicIsDirty", null);
    return SozaiKensakuScreenStore;
}());
export { SozaiKensakuScreenStore };

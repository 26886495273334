var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
var NohinScreenStore = /** @class */ (function () {
    function NohinScreenStore() {
        this.INo = "";
        this.nohinsyo = "";
        this.nohinSaki = "";
        this.nohinTanto = "";
        this.entries = [];
        this.isSubmitted = false;
        this.warnDialogMessage = "";
    }
    NohinScreenStore.prototype.setINo = function (v) { this.INo = v; };
    NohinScreenStore.prototype.setNohinsyo = function (v) { this.nohinsyo = v; };
    NohinScreenStore.prototype.setNohinSaki = function (v) { this.nohinSaki = v; };
    NohinScreenStore.prototype.setNohinTanto = function (v) { this.nohinTanto = v; };
    NohinScreenStore.prototype.setEntries = function (v) { this.entries = v; };
    NohinScreenStore.prototype.setIsSubmitted = function (v) { this.isSubmitted = v; };
    NohinScreenStore.prototype.setWarnDialogMessage = function (v) { this.warnDialogMessage = v; };
    NohinScreenStore.prototype.resetWarnDialogMessage = function () { this.warnDialogMessage = ""; };
    NohinScreenStore.prototype.removeEntry = function (idx) {
        this.entries.splice(idx, 1);
    };
    NohinScreenStore.prototype.addEntry = function (v) {
        this.entries.push(v);
    };
    NohinScreenStore.prototype.resetInputs = function () {
        this.INo = "";
        this.nohinsyo = "";
        this.nohinSaki = "";
        this.nohinTanto = "";
    };
    __decorate([
        observable
    ], NohinScreenStore.prototype, "INo", void 0);
    __decorate([
        observable
    ], NohinScreenStore.prototype, "nohinsyo", void 0);
    __decorate([
        observable
    ], NohinScreenStore.prototype, "nohinSaki", void 0);
    __decorate([
        observable
    ], NohinScreenStore.prototype, "nohinTanto", void 0);
    __decorate([
        observable
    ], NohinScreenStore.prototype, "entries", void 0);
    __decorate([
        observable
    ], NohinScreenStore.prototype, "isSubmitted", void 0);
    __decorate([
        observable
    ], NohinScreenStore.prototype, "warnDialogMessage", void 0);
    __decorate([
        action.bound
    ], NohinScreenStore.prototype, "setINo", null);
    __decorate([
        action.bound
    ], NohinScreenStore.prototype, "setNohinsyo", null);
    __decorate([
        action.bound
    ], NohinScreenStore.prototype, "setNohinSaki", null);
    __decorate([
        action.bound
    ], NohinScreenStore.prototype, "setNohinTanto", null);
    __decorate([
        action.bound
    ], NohinScreenStore.prototype, "setEntries", null);
    __decorate([
        action.bound
    ], NohinScreenStore.prototype, "setIsSubmitted", null);
    __decorate([
        action.bound
    ], NohinScreenStore.prototype, "setWarnDialogMessage", null);
    __decorate([
        action.bound
    ], NohinScreenStore.prototype, "resetWarnDialogMessage", null);
    __decorate([
        action.bound
    ], NohinScreenStore.prototype, "removeEntry", null);
    __decorate([
        action.bound
    ], NohinScreenStore.prototype, "addEntry", null);
    __decorate([
        action.bound
    ], NohinScreenStore.prototype, "resetInputs", null);
    return NohinScreenStore;
}());
export { NohinScreenStore };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import { LocationChangeScreen } from "../location/LocationChangeScreen";
import { WarnDialog } from "../common/WarnDialog";
import { parseLocationCode } from "../../util/codeUtil";
import * as api from "../../api/apiClient";
import { makeLocationSuggestionProps } from "./completeUtil/makeLocationSuggestionProps";
import { makeTanabanSuggestionProps } from "./completeUtil/makeTanabanSuggestionProps";
export var LocationChangeScreenRoot = observer(function LocationChangeScreenRoot() {
    var _this = this;
    var store = React.useContext(StoreContext);
    var history = useHistory();
    var screenStore = store.locationChangeScreenStore;
    var location = useLocation();
    var acd = store.userStore.appAuthTable["location-change"];
    React.useEffect(function () {
        if (!acd.view) {
            alert("画面を表示する権限がありません");
            history.go(-1);
            return;
        }
        screenStore.resetInputs();
        screenStore.setEntries([]);
        screenStore.setIsSubmitted(false);
        // クエリパラメータ ino があればそれを追加
        // TODO 大量の INo を全選択で一括指定されて動くか？　動かないなら別経路で渡さないとダメ？
        var query = new URLSearchParams(location.search);
        var paramINos = query.getAll("ino");
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var _i, paramINos_1, INo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _i = 0, paramINos_1 = paramINos;
                        _a.label = 1;
                    case 1:
                        if (!(_i < paramINos_1.length)) return [3 /*break*/, 4];
                        INo = paramINos_1[_i];
                        return [4 /*yield*/, processINo(INo)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        }); })();
    }, [screenStore, location.search, acd]);
    var handleEnd = React.useCallback(function () {
        if ((!screenStore.isSubmitted) && (screenStore.entries.length)) {
            var result = window.confirm("表示中の素材は未更新です。終了してもよろしいですか？");
            if (!result)
                return;
        }
        history.go(-1);
    }, [history, screenStore]);
    var handleClear = React.useCallback(function () {
        screenStore.setEntries([]);
        screenStore.resetInputs();
        screenStore.setIsSubmitted(false);
    }, [screenStore]);
    var processINo = React.useCallback(function (INo) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (screenStore.entries.some(function (e) { return e.INo === INo; })) {
                        // alert("既に追加されています");
                        return [2 /*return*/, true];
                    }
                    return [4 /*yield*/, api.getSozai({ INo: INo })];
                case 1:
                    res = _a.sent();
                    if (res.RtnCode !== "OK") {
                        return [2 /*return*/, false];
                    }
                    if (screenStore.isSubmitted) {
                        screenStore.setEntries([]);
                        screenStore.setIsSubmitted(false);
                    }
                    screenStore.addEntry(__assign({}, res)); // TODO? 必要な値だけ使ってclone
                    return [2 /*return*/, true];
            }
        });
    }); }, [screenStore]);
    var processINoOrLoc = React.useCallback(function (val) { return __awaiter(_this, void 0, void 0, function () {
        function processLocationCode(locCode) {
            return __awaiter(this, void 0, void 0, function () {
                var loctana, nonexistentTanaCode, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            loctana = parseLocationCode(locCode);
                            if (!loctana)
                                return [2 /*return*/, false];
                            nonexistentTanaCode = "**";
                            return [4 /*yield*/, api.getLocation({ LocationCode: loctana.LocationCode, TanaCode: loctana.TanaCode || nonexistentTanaCode })];
                        case 1:
                            res = _a.sent();
                            if (res.RtnCode !== "OK") {
                                screenStore.invalidateLocationCodeInput();
                                screenStore.invalidateTanaCodeInput();
                                return [2 /*return*/, false];
                            }
                            screenStore.assignValidatedLoctionCode(res.LocationCode, res.LocationName);
                            if (loctana.TanaCode) {
                                screenStore.assignValidatedTanaCode(res.TanaCode, res.Tanaban);
                            }
                            else {
                                screenStore.invalidateTanaCodeInput();
                            }
                            return [2 /*return*/, true];
                    }
                });
            });
        }
        var passAsLocCode;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, processLocationCode(val)];
                case 1:
                    passAsLocCode = _a.sent();
                    if (!!passAsLocCode) return [3 /*break*/, 3];
                    return [4 /*yield*/, processINo(val)];
                case 2:
                    if (!(_a.sent())) {
                        screenStore.setWarnDialogMessage("ＩＭＡＧＩＣＡ番号としてもロケーションコードとしても正しくない文字列です");
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    var processTanaban = React.useCallback(function (val) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!screenStore.isValidLocationCodeInput) {
                        // disable するのでこないはずだが一応
                        screenStore.setWarnDialogMessage("ロケーションコードが指定されていません (棚番コードを入力するためにはロケーションを指定してください)");
                        return [2 /*return*/];
                    }
                    if (!val) {
                        screenStore.invalidateTanaCodeInput();
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, api.getLocation({ LocationCode: screenStore.validatedLocationCodeInput, TanaCode: val })];
                case 1:
                    res = _a.sent();
                    if (res.RtnCode !== "OK") {
                        screenStore.setWarnDialogMessage("\u5B58\u5728\u3057\u306A\u3044\u68DA\u756A\u30B3\u30FC\u30C9\u3067\u3059: ".concat(screenStore.validatedLocationCodeInput, "%").concat(val));
                        screenStore.invalidateTanaCodeInput();
                        return [2 /*return*/];
                    }
                    screenStore.assignValidatedTanaCode(res.TanaCode, res.Tanaban);
                    return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    var checkINoInput = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var INo;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    INo = screenStore.INoInput;
                    if (!(INo !== "")) return [3 /*break*/, 2];
                    return [4 /*yield*/, screenStore.withSubmitLock(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        screenStore.setINoInput("");
                                        return [4 /*yield*/, processINoOrLoc(INo)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    var checkLocationCodeInput = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var locationCode;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    locationCode = screenStore.locationCodeInput;
                    if (!(locationCode !== "")) return [3 /*break*/, 2];
                    return [4 /*yield*/, screenStore.withSubmitLock(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        screenStore.setLocationCodeInput("");
                                        return [4 /*yield*/, processINoOrLoc(locationCode)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    var handleEnter = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkINoInput()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, checkLocationCodeInput()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [checkINoInput, checkLocationCodeInput]);
    var handleEnterTanaban = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var tanaCodeInput;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tanaCodeInput = screenStore.tanaCodeInput;
                    return [4 /*yield*/, screenStore.withSubmitLock(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        screenStore.setTanaCodeInput("");
                                        return [4 /*yield*/, processTanaban(tanaCodeInput)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    var handleSubmit = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var locationCode, tanaCode, tanaban, res, INos;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (screenStore.submitLockCount > 0) {
                        // 何か alert() すべき？　しかし現実にはこの後 UI の更新が発生するし、鬱陶しいだけのような気がする。
                        return [2 /*return*/];
                    }
                    if (screenStore.entries.length === 0) {
                        screenStore.setWarnDialogMessage("更新内容がありません");
                        return [2 /*return*/];
                    }
                    locationCode = null;
                    tanaCode = null;
                    tanaban = null;
                    if (screenStore.isNameInputMode) {
                        if (screenStore.isLocationValid) {
                            locationCode = screenStore.sozaiDetail.LocationCode;
                        }
                        else if (screenStore.locationInput === "") {
                            locationCode = "";
                        }
                        else {
                            screenStore.setWarnDialogMessage("移動先ロケーションが不正です");
                            return [2 /*return*/];
                        }
                        if (screenStore.isTanabanValid) {
                            tanaCode = screenStore.sozaiDetail.TanaCode;
                            tanaban = screenStore.sozaiDetail.Tanaban;
                        }
                        else if (screenStore.tanabanInput === "") {
                            if (screenStore.isLocationValid) { // ロケーション名がある場合、棚番も必要
                                screenStore.setWarnDialogMessage("棚番が指定されていません");
                                return [2 /*return*/];
                            }
                            tanaCode = "";
                            tanaban = "";
                        }
                        else {
                            screenStore.setWarnDialogMessage("移動先の棚番が不正です");
                            return [2 /*return*/];
                        }
                    }
                    else {
                        if (screenStore.locationCodeInput !== "" || screenStore.tanaCodeInput !== "") {
                            // 確定済みの値 (`screenStore.validatedLocationCodeInput` など; 入力欄下部に表示される) が
                            // あるか否かに関わらず、この値 (入力途中の値) が空でなければリジェクトする。
                            // どちらの値を使うのがユーザの期待なのかわからないため。
                            //
                            // なおこのパスを通過するとしたら、それは「入力欄にフォーカスを当てたまま "更新" ボタンを押した時」である。
                            // (フォーカスを外した時点でコード確定処理 (checkLocationCodeInput() など) が走り、入力欄はクリアされてしまうため)
                            // またその場合でも、 blur の通知はボタンの mousedown 時点で飛ぶので、恐らく必ず確定処理が先行する。
                            // このパスは、blur よりも先にボタンの onclick が飛ぶような環境 (恐らく存在しない) を救うための保険である。
                            //
                            // 現実に懸念すべきは、「コードの確定処理 "中"」にここに来てしまうことである。
                            // (確定処理の重複実行を防ぐため、確定処理の直前に入力欄 (locationCodeInput) はクリアされる＝このチェックをすり抜ける)
                            // しかしこれは起きないようにしてある (ref. submitLockCount) 。
                            //
                            // まとめ: 「更新」ボタン押下とコード確定処理 (checkLocationCodeInput() など) が短時間に両方起きる場合:
                            //  - (ケースA) 「コード確定処理」に先立って「更新」ボタンの処理が来る時: このパスで却下する (たぶんそんな環境はない)
                            //  - (ケースB) 「コード確定処理」の実行中に「更新」ボタンの処理が来る時: submitLockCount チェックでここに来ない
                            //  - (ケースC) 「コード確定処理」の完了後に「更新」ボタンの処理が来る時: 問題なく更新する
                            screenStore.setWarnDialogMessage("「ロケーションコード」または「棚番コード」の入力欄に未確定の文字列があります。\n" +
                                "(入力欄からフォーカスを外すか、入力欄で ENTER キーを押して、対応する棚番名を確定させてから\n「更新」ボタンを押してください)");
                            return [2 /*return*/];
                        }
                        // ボタンを disable する方がいいか？
                        if (screenStore.isValidLocationCodeInput && !screenStore.isValidTanaCodeInput) {
                            screenStore.setWarnDialogMessage("棚番コードが未入力です。ロケーションコードを指定した場合、棚番コードの指定も必要です。");
                            return [2 /*return*/];
                        }
                        locationCode = screenStore.isValidLocationCodeInput ? screenStore.validatedLocationCodeInput : "";
                        tanaCode = screenStore.isValidTanaCodeInput ? screenStore.validatedTanaCodeInput : "";
                        tanaban = screenStore.isValidTanaCodeInput ? screenStore.destTanaban : "";
                    }
                    if (!locationCode && !tanaCode && !(screenStore.tokiJikoInput)) {
                        screenStore.setWarnDialogMessage("入力欄が未入力です");
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, api.changeLocations({
                            UserCode: store.userStore.opeInfo.OpeCode,
                            DataList: screenStore.entries.map(function (e) { return ({
                                INo: e.INo,
                                LocationCode: locationCode,
                                TanaCode: tanaCode,
                                Tanaban: tanaban,
                                TokiJiko: screenStore.tokiJikoInput
                            }); })
                        })];
                case 1:
                    res = _a.sent();
                    if (res.RtnMessage) {
                        if (res.RtnCode === "OK") {
                            alert(res.RtnMessage);
                        }
                        else {
                            screenStore.setWarnDialogMessage(res.RtnMessage);
                        }
                    }
                    else {
                        alert("更新しました");
                    }
                    screenStore.resetInputs();
                    INos = screenStore.entries.map(function (e) { return e.INo; });
                    screenStore.setEntries([]);
                    return [4 /*yield*/, (function () { return __awaiter(_this, void 0, void 0, function () {
                            var _i, INos_1, INo;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _i = 0, INos_1 = INos;
                                        _a.label = 1;
                                    case 1:
                                        if (!(_i < INos_1.length)) return [3 /*break*/, 4];
                                        INo = INos_1[_i];
                                        return [4 /*yield*/, processINo(INo)];
                                    case 2:
                                        _a.sent();
                                        _a.label = 3;
                                    case 3:
                                        _i++;
                                        return [3 /*break*/, 1];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); })()];
                case 2:
                    _a.sent();
                    // 処理順上 processINo() の後でないといけない (processINo() に上書きされる点に注意)
                    screenStore.setIsSubmitted(true);
                    return [2 /*return*/];
            }
        });
    }); }, [screenStore]);
    var dialog = React.createElement(WarnDialog, { onClose: screenStore.resetWarnDialogMessage }, React.createElement("div", null,
        React.createElement("code", null, screenStore.warnDialogMessage)));
    return (React.createElement(React.Fragment, null,
        React.createElement(LocationChangeScreen, { acd: acd, inoInput: screenStore.INoInput, tokiJikoInput: screenStore.tokiJikoInput, entries: screenStore.entries, isSubmitted: screenStore.isSubmitted, onSubmit: handleSubmit, onClear: handleClear, onEnd: handleEnd, onChangeINo: screenStore.setINoInput, onBlurINo: checkINoInput, onChangeTokiJiko: screenStore.setTokiJikoInput, onAddLine: checkINoInput, onRemoveLine: screenStore.removeEntry, isNameInputMode: screenStore.isNameInputMode, onToggleNameInputMode: screenStore.toggleNameInputMode, completePropsLocation: makeLocationSuggestionProps(screenStore), completePropsTanaban: makeTanabanSuggestionProps(screenStore), locationCodeInput: screenStore.locationCodeInput, locationName: screenStore.destLocationName, tanaCodeInput: screenStore.tanaCodeInput, tanaban: screenStore.destTanaban, isValidTanaCodeInput: screenStore.isValidTanaCodeInput, onChangeLocationCode: screenStore.setLocationCodeInput, onChangeTanaCode: screenStore.setTanaCodeInput, onBlurLocationCode: checkLocationCodeInput, onEnter: handleEnter, onEnterTanaban: handleEnterTanaban }),
        screenStore.warnDialogMessage ? dialog : null));
});

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import { createNullItem } from "../types/Item";
var ItemEditScreenStore = /** @class */ (function () {
    function ItemEditScreenStore() {
        this.item = createNullItem();
    }
    ItemEditScreenStore.prototype.setItem = function (v) { this.item = v; };
    ItemEditScreenStore.prototype.resetItem = function () { this.item = createNullItem(); };
    __decorate([
        observable
    ], ItemEditScreenStore.prototype, "item", void 0);
    __decorate([
        action.bound
    ], ItemEditScreenStore.prototype, "setItem", null);
    __decorate([
        action.bound
    ], ItemEditScreenStore.prototype, "resetItem", null);
    return ItemEditScreenStore;
}());
export { ItemEditScreenStore };

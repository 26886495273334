var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observable, action } from "mobx";
import { createNullSozaiDetail } from "../types/SozaiDetail";
var LocationChangeScreenStore = /** @class */ (function () {
    function LocationChangeScreenStore() {
        // 補完用に location と tanaban 関連だけ使っている
        this.sozaiDetail = createNullSozaiDetail();
        this.INoInput = "";
        this.tokiJikoInput = "";
        this.entries = [];
        this.isSubmitted = false;
        this.isNameInputMode = false;
        // isNameInputMode が false の時用
        this.locationCodeInput = "";
        this.validatedLocationCodeInput = "";
        this.destLocationName = "";
        this.isValidLocationCodeInput = false;
        this.tanaCodeInput = "";
        this.validatedTanaCodeInput = "";
        this.destTanaban = "";
        this.isValidTanaCodeInput = false;
        // isNameInputMode が true の時用
        // LocationCompleteStoreLike
        this.locationInput = "";
        this.locationSuggestions = [];
        this.isLocationValid = false;
        // TanabanCompleteStoreLike
        this.tanabanInput = "";
        this.tanabanSuggestions = [];
        this.isTanabanValid = false;
        this.submitLockCount = 0;
        this.warnDialogMessage = "";
    }
    LocationChangeScreenStore.prototype.withSubmitLock = function (fun) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        ++this.submitLockCount;
                        return [4 /*yield*/, fun()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        --this.submitLockCount;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LocationChangeScreenStore.prototype.setINoInput = function (v) { this.INoInput = v; };
    LocationChangeScreenStore.prototype.setTokiJikoInput = function (v) { this.tokiJikoInput = v; };
    LocationChangeScreenStore.prototype.setEntries = function (v) { this.entries = v; };
    LocationChangeScreenStore.prototype.setIsSubmitted = function (v) { this.isSubmitted = v; };
    LocationChangeScreenStore.prototype.setIsNameInputMode = function (v) { this.isNameInputMode = v; };
    LocationChangeScreenStore.prototype.toggleNameInputMode = function () { this.setIsNameInputMode(!this.isNameInputMode); };
    LocationChangeScreenStore.prototype.setLocationCodeInput = function (v) { this.locationCodeInput = v; };
    LocationChangeScreenStore.prototype.setValidatedLocationCodeInput = function (v) { this.validatedLocationCodeInput = v; };
    LocationChangeScreenStore.prototype.setDestLocationName = function (v) { this.destLocationName = v; };
    LocationChangeScreenStore.prototype.setIsValidLocationCodeInput = function (v) { this.isValidLocationCodeInput = v; };
    LocationChangeScreenStore.prototype.assignValidatedLoctionCode = function (locCode, locName) {
        this.setValidatedLocationCodeInput(locCode);
        this.setDestLocationName(locName);
        this.setIsValidLocationCodeInput(true);
    };
    LocationChangeScreenStore.prototype.invalidateLocationCodeInput = function () {
        this.setValidatedLocationCodeInput("");
        this.setDestLocationName("");
        this.setIsValidLocationCodeInput(false);
        // 棚番コードも invalidate
        this.setTanaCodeInput("");
        this.invalidateTanaCodeInput();
    };
    LocationChangeScreenStore.prototype.setTanaCodeInput = function (v) { this.tanaCodeInput = v; };
    LocationChangeScreenStore.prototype.setValidatedTanaCodeInput = function (v) { this.validatedTanaCodeInput = v; };
    LocationChangeScreenStore.prototype.setDestTanaban = function (v) { this.destTanaban = v; };
    LocationChangeScreenStore.prototype.setIsValidTanaCodeInput = function (v) { this.isValidTanaCodeInput = v; };
    LocationChangeScreenStore.prototype.assignValidatedTanaCode = function (tanaCode, tanaban) {
        this.setValidatedTanaCodeInput(tanaCode);
        this.setDestTanaban(tanaban);
        this.setIsValidTanaCodeInput(true);
    };
    LocationChangeScreenStore.prototype.invalidateTanaCodeInput = function () {
        this.setValidatedTanaCodeInput("");
        this.setDestTanaban("");
        this.setIsValidTanaCodeInput(false);
    };
    // LocationCompleteStoreLike
    LocationChangeScreenStore.prototype.setLocationInput = function (v) {
        if (this.locationInput === v)
            return;
        this.locationInput = v;
        this.resetTanabanInputState();
    };
    LocationChangeScreenStore.prototype.setLocationSuggestions = function (v) { this.locationSuggestions = v; };
    LocationChangeScreenStore.prototype.setIsLocationValid = function (v) {
        this.isLocationValid = v;
        if (!v)
            this.resetTanabanInputState();
    };
    LocationChangeScreenStore.prototype.resetLocationSuggestions = function () { this.locationSuggestions = []; };
    // TanabanCompleteStoreLike
    LocationChangeScreenStore.prototype.setTanabanInput = function (v) { this.tanabanInput = v; };
    LocationChangeScreenStore.prototype.setTanabanSuggestions = function (v) { this.tanabanSuggestions = v; };
    LocationChangeScreenStore.prototype.setIsTanabanValid = function (v) { this.isTanabanValid = v; };
    LocationChangeScreenStore.prototype.resetTanabanSuggestions = function () { this.tanabanSuggestions = []; };
    LocationChangeScreenStore.prototype.resetTanabanInputState = function () {
        this.setTanabanInput("");
        this.setIsTanabanValid(false);
        this.resetTanabanSuggestions();
        this.sozaiDetail.TanaCode = "";
        this.sozaiDetail.Tanaban = "";
    };
    LocationChangeScreenStore.prototype.setWarnDialogMessage = function (v) { this.warnDialogMessage = v; };
    LocationChangeScreenStore.prototype.resetWarnDialogMessage = function () { this.warnDialogMessage = ""; };
    LocationChangeScreenStore.prototype.removeEntry = function (idx) {
        this.entries.splice(idx, 1);
    };
    LocationChangeScreenStore.prototype.addEntry = function (v) {
        this.entries.push(v);
    };
    LocationChangeScreenStore.prototype.resetInputs = function () {
        this.sozaiDetail = createNullSozaiDetail();
        this.INoInput = "";
        this.tokiJikoInput = "";
        this.locationCodeInput = "";
        this.validatedLocationCodeInput = "";
        this.destLocationName = "";
        this.isValidLocationCodeInput = false;
        this.tanaCodeInput = "";
        this.validatedTanaCodeInput = "";
        this.destTanaban = "";
        this.isValidTanaCodeInput = false;
        this.isSubmitted = false;
        this.destTanaban = "";
        this.locationInput = "";
        this.isLocationValid = false;
        this.locationSuggestions = [];
        this.tanabanInput = "";
        this.isTanabanValid = false;
        this.tanabanSuggestions = [];
    };
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "sozaiDetail", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "INoInput", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "tokiJikoInput", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "entries", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "isSubmitted", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "isNameInputMode", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "locationCodeInput", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "validatedLocationCodeInput", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "destLocationName", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "isValidLocationCodeInput", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "tanaCodeInput", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "validatedTanaCodeInput", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "destTanaban", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "isValidTanaCodeInput", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "locationInput", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "locationSuggestions", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "isLocationValid", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "tanabanInput", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "tanabanSuggestions", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "isTanabanValid", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "submitLockCount", void 0);
    __decorate([
        observable
    ], LocationChangeScreenStore.prototype, "warnDialogMessage", void 0);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "withSubmitLock", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setINoInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setTokiJikoInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setEntries", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setIsSubmitted", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setIsNameInputMode", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "toggleNameInputMode", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setLocationCodeInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setValidatedLocationCodeInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setDestLocationName", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setIsValidLocationCodeInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "assignValidatedLoctionCode", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "invalidateLocationCodeInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setTanaCodeInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setValidatedTanaCodeInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setDestTanaban", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setIsValidTanaCodeInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "assignValidatedTanaCode", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "invalidateTanaCodeInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setLocationInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setLocationSuggestions", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setIsLocationValid", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "resetLocationSuggestions", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setTanabanInput", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setTanabanSuggestions", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setIsTanabanValid", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "resetTanabanSuggestions", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "resetTanabanInputState", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "setWarnDialogMessage", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "resetWarnDialogMessage", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "removeEntry", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "addEntry", null);
    __decorate([
        action.bound
    ], LocationChangeScreenStore.prototype, "resetInputs", null);
    return LocationChangeScreenStore;
}());
export { LocationChangeScreenStore };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from "mobx";
import { createNullOpeInfo } from "../types/OpeInfo";
import { parseACDStr } from "../types/ACD";
import { createNullAppAuthTable } from "../types/AppAuthTable";
var UserStore = /** @class */ (function () {
    function UserStore() {
        var _this = this;
        this.loginType = null;
        this.token = "";
        this.opeInfo = createNullOpeInfo();
        this.appAuthTable = createNullAppAuthTable();
        // セッション管理代わり。将来削除？
        (function () {
            try {
                var opeInfoStr = localStorage.getItem("sozaimgt.opeInfo");
                var loginTypeStr = localStorage.getItem("sozaimgt.loginType");
                var acdStr = localStorage.getItem("sozaimgt.acd");
                if (opeInfoStr)
                    _this.opeInfo = JSON.parse(opeInfoStr);
                if (loginTypeStr)
                    _this.loginType = JSON.parse(loginTypeStr);
                if (acdStr)
                    _this.appAuthTable = JSON.parse(acdStr);
            }
            catch (e) {
                // do nothing
            }
        })();
    }
    Object.defineProperty(UserStore.prototype, "isLoggedIn", {
        get: function () {
            return this.loginType !== null;
        },
        enumerable: false,
        configurable: true
    });
    UserStore.prototype.setLoginType = function (v) {
        this.loginType = v;
        // セッション管理代わり。将来削除？
        try {
            localStorage.setItem("sozaimgt.loginType", JSON.stringify(this.loginType));
        }
        catch (e) {
            // do nothing.
        }
    };
    UserStore.prototype.setOpeInfo = function (v) {
        this.opeInfo = v;
        // セッション管理代わり。将来削除？
        try {
            localStorage.setItem("sozaimgt.opeInfo", JSON.stringify(this.opeInfo));
        }
        catch (e) {
            // do nothing.
        }
    };
    UserStore.prototype.resetOpeInfo = function () {
        this.setOpeInfo(createNullOpeInfo());
    };
    UserStore.prototype.assignAppAuthes = function (mes) {
        var authTable = createNullAppAuthTable();
        mes.forEach(function (me) {
            var acd = parseACDStr(me.AppAuth);
            acd.view = true; // エントリがある時点で参照権限はある。なぜread権限をフラグにしないのかは謎だが、仕様。
            authTable[me.MenuApp] = acd;
        });
        this.appAuthTable = authTable;
        // セッション管理代わり。将来削除
        try {
            localStorage.setItem("sozaimgt.acd", JSON.stringify(this.appAuthTable));
        }
        catch (e) {
            // do nothing.
        }
    };
    __decorate([
        observable
    ], UserStore.prototype, "loginType", void 0);
    __decorate([
        observable
    ], UserStore.prototype, "token", void 0);
    __decorate([
        observable
    ], UserStore.prototype, "opeInfo", void 0);
    __decorate([
        observable
    ], UserStore.prototype, "appAuthTable", void 0);
    __decorate([
        action
    ], UserStore.prototype, "setLoginType", null);
    __decorate([
        action.bound
    ], UserStore.prototype, "setOpeInfo", null);
    __decorate([
        action.bound
    ], UserStore.prototype, "resetOpeInfo", null);
    __decorate([
        action.bound
    ], UserStore.prototype, "assignAppAuthes", null);
    return UserStore;
}());
export { UserStore };

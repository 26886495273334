var table = {
    "": "",
    "E": "登録",
    "L": "ロケ変",
    "R": "納品",
    "C": "棚卸",
    "A": "アラート",
    "D": "廃棄",
    "M": "名変",
    "U": "変更"
};
[];
var invTable = {
    "": "",
    "登録": "E",
    "ロケ変": "L",
    "納品": "R",
    "棚卸": "C",
    "アラート": "A",
    "廃棄": "D",
    "名変": "M",
    "変更": "U"
};
export function stringOfEventKind(k) {
    return table[k] || "\u4E0D\u660E(".concat(k, ")");
}
export function eventKindOfString(s) {
    var ret = invTable[s];
    if (ret == null || ret.length > 1) // TODO length は toString とかの Object についてるやつ対策。普通に線形検索で実装すべき
        throw new Error("不明なイベント:" + s);
    return ret;
}
export var eventKindNames = Object.keys(invTable);

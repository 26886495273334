var escapeForEnclosing = RegExp('(,|"|\n|\r|\r\n)');
export function makeCSV(value) {
    var ret = value.map(function (line) {
        return line.map(function (elem) {
            if (escapeForEnclosing.test(elem)) {
                return '"' + elem.replace(/"/g, '""').replace(/\n/g, '\r\n') + '"';
            }
            else {
                return elem;
            }
            ;
        }).join(",");
    }).join("\r\n");
    return "\ufeff" + ret; // BOM つきがご所望とのこと
}
